import { FormEvent, useRef, useState } from 'react';
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch } from '../../../../store/hooks';
import useAccessRight from '../../../../hooks/useAccessRight';
import { addRows, deleteRows } from '../../../../store/marksSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { wordDeclination } from '../../../../helpers/wordDeclination';
import { MARKS } from '../../../../constants/accessRights';
import { backgroundColor, colorPrimary, colorRed } from '../../../../constants/colors';
import TableCustomPagination from '../../TableCustomPagination/TableCustomPagination';
import { ICustomFooterProps } from './CustomFooter.props';
import styles from './CustomFooter.module.scss';

const CustomFooter = ({ setChangeFlg, table, rowSelection, tableContainerRef }: ICustomFooterProps): JSX.Element => {
	const [inputMark, setInputMark] = useState<string>(''); // метка
	const [inputPhrases, setInputPhrases] = useState<string>(''); // описание
	const [inputTreshold, setInputTreshold] = useState<number>(90); // порог, %
	const inputMarkRef = useRef<HTMLInputElement>(); // ссылка на input метки

	const dispatch = useAppDispatch();

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления строк в словарь меток
	const submitAddRowsHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const match = inputPhrases.match(/^.+$/gm); // разделяем словосочетания по строкам, если их несколько
		match && dispatch(addRows([inputMark, match, inputTreshold / 100])); // добавление строк в словарь меток в store
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		setInputMark(''); // очищаем поле "Метка"
		setInputPhrases(''); // очищаем поле "Описание"
		setInputTreshold(90); // очищаем поле "Порог"
		inputMarkRef.current?.focus(); // ставим фокус на поле "Метка"
	};

	// обработчик нажатой клавиши
	const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		// если комбинация Ctrl+Enter - добавляем строку
		if (e.ctrlKey && e.code === 'Enter') {
			// это могло бы работать, 7 элемент - button
			// @ts-ignore
			e.target.form[7]?.click();
		}
	};

	// обработчик удаления выделенных строк
	const deleteRowsHandler = (): void => {
		dispatch(deleteRows({ rows: Object.keys(rowSelection).map(value => +value) })); // удаляем строки из словаря меток в store
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	return (
		<div className={cn(styles.footer, {
			[styles.footerBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollHeight - tableContainerRef.current.offsetHeight > Math.ceil(tableContainerRef.current.scrollTop) + 1
		})}>
			{Object.keys(rowSelection).length > 0 && isAccess(MARKS.PUT) &&
				<>
					<div className={styles.selectedRow}>
						<span>{translate('corpusTable_selectedTitle')} {Object.keys(rowSelection).length} {wordDeclination(Object.keys(rowSelection).length, [translate('corpusTable_rowTitle'), translate('corpusTable_rowsTitle1'), translate('corpusTable_rowsTitle2')])}</span>
						<FontAwesomeIcon icon={faTrashCan} color={colorRed} onClick={deleteRowsHandler} title={translate('corpusTable_deleteRowsBtn')} style={{ cursor: 'pointer' }} />
					</div>
				</>
			}
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
				{isAccess(MARKS.PUT) &&
					<form className={styles.formAddRows} onSubmit={e => submitAddRowsHandler(e)}>
						{/* метка */}
						<TextField
							required
							inputRef={inputMarkRef}
							label={translate('marksTable_inputMark')}
							size="small"
							value={inputMark}
							onChange={e => setInputMark(e.target.value)}
							variant="outlined"
							// multiline
							// maxRows={2}
							InputProps={{
								style: {
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{
								width: 'calc((100% - 110px - 100px) * 0.3)',
								// position: 'absolute',
								// right: 100,
								// bottom: 0,
								// '::WebkitBackdropFilter': inputMark.length ? 'blur(8px)' : 'none',
								// backdropFilter: inputMark.length > 0 ? 'blur(8px)' : 'none',
								'.MuiInputLabel-root[data-shrink="false"]': { top: -2 },
								// '.MuiInputLabel-root[data-shrink="true"]': { backgroundColor: backgroundColor, borderRadius: '3px' },
							}}
						/>
						{/* описание */}
						<TextField
							required
							label={translate('marksTable_inputDesc')}
							size="small"
							value={inputPhrases}
							onChange={e => setInputPhrases(e.target.value)}
							variant="outlined"
							multiline
							maxRows={10}
							InputProps={{
								onKeyDown: (e) => keyDownHandler(e),
								endAdornment: (
									<InputAdornment position="end" >
										{inputPhrases.length > 0 && <FontAwesomeIcon icon={faXmark} onClick={() => setInputPhrases('')} style={{ cursor: 'pointer ' }} />}
									</InputAdornment>
								),
								style: {
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{
								width: 'calc((100% - 110px - 100px) * 0.7)',
								position: 'absolute',
								right: 200,
								bottom: 0,
								'::WebkitBackdropFilter': inputPhrases.length ? 'blur(8px)' : 'none',
								backdropFilter: inputPhrases.length > 0 ? 'blur(8px)' : 'none',
								'.MuiInputLabel-root[data-shrink="false"]': { top: -2 },
								'.MuiInputLabel-root[data-shrink="true"]': { backgroundColor: backgroundColor, borderRadius: '3px' },
							}}
						/>
						{/* порог */}
						<TextField
							required
							label={translate('marksTable_inputThreshold')}
							size="small"
							value={inputTreshold}
							onChange={e => setInputTreshold(+e.target.value)}
							variant="outlined"
							type='number'
							InputProps={{
								style: {
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { min: 50, max: 99 },
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{
								width: '90px',
								position: 'absolute',
								right: 100,
								'.MuiInputLabel-root[data-shrink="false"]': { top: -2 },
							}}
						/>
						<Button
							variant="outlined"
							type='submit'
							sx={{ position: 'absolute', right: 0, height: '100%', width: 90, fontSize: 11, overflow: 'hidden' }}
						>
							{translate('corpusTable_addBtn')}
						</Button>
					</form >
				}
				<div className={styles.pagination}>
					<FormControl sx={{ width: 120, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="demo-simple-select-label" sx={{ fontSize: 13 }}>{translate('corpusTable_selectCountRows')}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="simple-select"
							value={table.getState().pagination.pageSize}
							label={translate('corpusTable_selectCountRows')}
							onChange={e => table.setPageSize(Number(e.target.value))}
							style={{ fontSize: 13, height: 36, color: colorPrimary }}
						>
							{[10, 20, 50, 100, 500, 1000].map(value => <MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}</MenuItem>)}
						</Select>
					</FormControl>
					<TableCustomPagination table={table} tableContainerRef={tableContainerRef} />
				</div>
			</Box>
		</div>
	);
};

export default CustomFooter;
