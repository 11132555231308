import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getStatusProcessingQuestions, processQuestions, selectCategoriesList, selectChannelList, selectProcessingQuestionsStartStatus } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { CachingType } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormProcessingQuestionsProps } from './FormProcessingQuestions.props';

const FormProcessingQuestions = ({ showModal, setShowModal, setShowNotification }: IFormProcessingQuestionsProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // текстовый файл с вопросами
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [selectChannelId, setSelectChannelId] = useState<string>(''); // id канала
	const [selectCaching, setSelectCaching] = useState<CachingType>('all'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(true); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(true); // добавление вопроса в похожие в кэше, если похожая формулировка найдена
	const [thresholdFlg, setThresholdFlg] = useState<boolean>(false); // флаг порога похожести
	const [thresholdValue, setThresholdValue] = useState<number>(90); // значение порога похожести

	const dispatch = useAppDispatch();
	const startProcessingQuestionsStatus = useAppSelector(selectProcessingQuestionsStartStatus); // store - статус запуска массовой обработки вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за checkbox'ом gpt
	useEffect(() => {
		!checkGPT && setCheckCreative(false); // если убран gpt - убираем творческий режим
	}, [checkGPT]);

	// следим за статусом массовой обработки вопросов
	useEffect(() => {
		if (startProcessingQuestionsStatus.error !== ResponseStatus.SUCCESS || startProcessingQuestionsStatus.status === RequestStatus.FAILED || startProcessingQuestionsStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус процесса генерации
		if (startProcessingQuestionsStatus.error === ResponseStatus.SUCCESS && startProcessingQuestionsStatus.message !== '') {
			isAccess(QAS.PROCESSING_QUESTIONS_STATUS) && dispatch(getStatusProcessingQuestions());
		}
	}, [startProcessingQuestionsStatus]);

	// обработчик массовой обработки вопросов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
			dispatch(processQuestions({
				formData,
				categoryId: selectCategoryId ? selectCategoryId : undefined,
				channel: selectChannelId ? selectChannelId : undefined,
				caching: selectCaching,
				gpt: checkGPT ? 'yes' : 'no',
				creative: checkCreative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
				threshold: thresholdFlg ? thresholdValue : undefined,
			})); // массовая обработка вопросов
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startProcessingQuestionsStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formProcessingQuestions_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						id="file"
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
						variant="outlined"
						type="file"
						required
						onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
							inputProps: { accept: "text/csv, text/plain" }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{categoriesList.data.length > 0 &&
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCategory')}</InputLabel>
						<Select
							labelId="category-label"
							id="category"
							label={translate('answerToQuestion_selectCategory')}
							disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
							value={selectCategoryId}
							onChange={(e) => setSelectCategoryId(e.target.value)}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							<MenuItem value='' sx={{ fontSize: 13 }}>{translate('answerToQuestion_notSelectedCategory')}</MenuItem>
							{categoriesList.data.map((category) => (
								<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}

				{channelList.data.length > 0 &&
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectChannel')}</InputLabel>
						<Select
							labelId="channel-label"
							id="channel"
							label={translate('answerToQuestion_selectChannel')}
							disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
							value={selectChannelId}
							onChange={(e) => setSelectChannelId(e.target.value)}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{channelList.data.map((channel) => (
								<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13, color: colorPrimary }}>{channel.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}

				<FormControl fullWidth margin='dense' sx={{
					'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
					'.MuiInputBase-input': { padding: '8px 14px' },
				}}>
					<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCacheAnswers')}</InputLabel>
					<Select
						labelId="caching-label"
						id="caching"
						label={translate('answerToQuestion_selectCacheAnswers')}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
						value={selectCaching}
						onChange={(e) => setSelectCaching(e.target.value as CachingType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheNo')}</MenuItem>
						<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheAll')}</MenuItem>
						<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheChecked')}</MenuItem>
					</Select>
				</FormControl>

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkGPT}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
						onChange={e => setCheckGPT(e.target.checked)}
						size='small'
					/>
				} label={translate('answerToQuestion_checkGpt')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkCreative}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING || !checkGPT}
						onChange={e => setCheckCreative(e.target.checked)}
						size='small'
					/>
				} label={translate('answerToQuestion_checkCreative')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={addAlias}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
						onChange={e => setAddAlias(e.target.checked)}
						size='small'
					/>
				} label={translate('answerToQuestion_checkCacheAlias')} />

				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={thresholdFlg}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
						onChange={e => setThresholdFlg(e.target.checked)}
						size='small'
					/>
				} label={translate('answerToQuestion_checkTreshold')} />

				<TextField
					variant="outlined"
					type='number'
					disabled={!thresholdFlg}
					value={thresholdValue}
					onChange={(e) => setThresholdValue(+e.target.value)}
					InputProps={{
						style: {
							height: 23,
							fontSize: 13,
							color: colorPrimary,
							marginTop: '6px'
						},
						inputProps: { min: 1, max: 99 }
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>


				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
					>
						{translate('formProcessingQuestions_startBtn')}
						{startProcessingQuestionsStatus.status === RequestStatus.LOADING &&
							<ProgressCircle isBtnDisabled />
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormProcessingQuestions;
