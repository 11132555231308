import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slide } from '@mui/material';
import { clearDeletionTask, deleteTask, selectDeletionTaskStatus, selectQueue, selectRecognitionAsyncData, selectRecognitionData } from '../../../store/sprSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { SPR_TASK_ID } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import ToggleColoring from '../Buttons/ToggleColoring/ToggleColoring';
import ExportToDoc from '../Buttons/ExportToDoc/ExportToDoc';
import Delete from '../Buttons/Delete/Delete';
import NewTranscript from '../Buttons/NewTranscript/NewTranscript';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import { IQueueControlsProps } from './QueueControls.props';
import styles from './QueueControls.module.scss';

const QueueControls = ({ setShowPage }: IQueueControlsProps): JSX.Element => {
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления задачи
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления задачи

	const dispatch = useAppDispatch();
	const queue = useAppSelector(selectQueue); // store - очередь распознавания
	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - данные асинхронного режима

	const [cookies] = useCookies([SPR_TASK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик удаления задачи
	const taskDeletionHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete && setShowNotificationDelete(true); // включаем уведомление
		dispatch(deleteTask(cookies.sprTaskId)); // удаление задачи
	};

	return (
		<div className={styles.controls}>

			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.controlsWrapper}>
					<div className={styles.controlsTitle}>{translate('spr')}</div>
					<section className={styles.controlsTypeAndFunc}>

						<div className={styles.controlsFunctions}>
							{/* если есть список очереди */}
							{queue.status === RequestStatus.IDLE && queue.data && Object.keys(queue.data).length > 0 &&
								<div className={styles.controlsFunctionsLeftBlock}>
									<ToggleColoring
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={recognitionData.status === RequestStatus.IDLE && recognitionData.data !== null && 'text' in recognitionData.data}
									/>
								</div>
							}
						</div>

						<div className={styles.controlsFunctions}>
							{/* если есть список очереди */}
							{queue.status === RequestStatus.IDLE && queue.data && Object.keys(queue.data).length > 0 &&
								<>
									<div className={styles.controlsFunctionsLeftlock}>
										{isAccess(TRANSCRIPTION.CREATE) && <NewTranscript styleNotActive={styles.controlsFunctionBtnNotActive} />}
										<ExportToDoc
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={(recognitionData.data !== null && 'splitted' in recognitionData.data && Array.isArray(recognitionData.data.splitted) && recognitionData.data.splitted.length > 0) || (recognitionData.data !== null && 'words' in recognitionData.data && Array.isArray(recognitionData.data.words) && recognitionData.data.words.length > 0)}
											fileName={recognitionData.data && 'processed' in recognitionData.data ? recognitionData.data.processed : 'document'}
											data={recognitionData.data !== null && 'splitted' in recognitionData.data && Array.isArray(recognitionData.data.splitted) ?
												{
													type: 'dialog',
													data: (recognitionData.data !== null && 'splitted' in recognitionData.data && Array.isArray(recognitionData.data.splitted) && recognitionData.data.splitted) || [],
												} : {
													type: 'monolog',
													data: (recognitionData.data !== null && 'words' in recognitionData.data && Array.isArray(recognitionData.data.words) && recognitionData.data.words) || [],
												}
											}
											speakerList={recognitionData.speakerList}
										/>
										{isAccess(SPR.DELETE_TASK) &&
											<Delete
												styleNotActive={styles.controlsFunctionBtnNotActive}
												isAvailable={recognitionAsyncData.requestStatus !== RequestStatus.LOADING}
												title='controlsBtn_deleteTask'
												setShowAlertDialog={setShowAlertDialogDelete}
											/>
										}
									</div>
								</>
							}

							<div className={styles.controlsFunctionsRightBlock}>
								<UserMenu setShowPage={setShowPage} />
							</div>
						</div>

					</section>
				</div>
			</Slide>

			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={taskDeletionHandler}
					title='dialog_deleteTaskTitle'
					description='dialog_deleteTaskConfirm'
				/>
			}
			{/* уведомление для удаления задачи */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletionTaskStatus}
					clearDataResponse={clearDeletionTask}
					titleFailed='noticeDelete_failed'
					titleSuccess='noticeDelete_success'
				/>
			}
		</div>
	);
};

export default QueueControls;
