import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMarks, importMarks, selectImportMarks, selectMarksList } from '../../../store/marksSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormImportMarksProps } from './FormImportMarks.props';

const FormImportMarks = ({ showModal, setShowModal, setShowNotification }: IFormImportMarksProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // файл для импорта словаря меток

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const importMarksStatus = useAppSelector(selectImportMarks); // store - статус импорта словаря меток

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом импорта словаря меток
	useEffect(() => {
		// иначе если нет ошибок
		if (importMarksStatus.error === ResponseStatus.SUCCESS && importMarksStatus.status === RequestStatus.IDLE && importMarksStatus.message !== '') {
			marksList.activeMarksId && dispatch(getMarks(marksList.activeMarksId)); // обновляем информацию словаря
		}

		// если изменился статус импорта
		if (importMarksStatus.error !== ResponseStatus.SUCCESS || importMarksStatus.status === RequestStatus.FAILED || importMarksStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [importMarksStatus]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт словаря - запрещаем покидать форму
		if (importMarksStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	// обработчик импорта словаря меток
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) formData.append('file', file);
		marksList.activeMarksId && dispatch(importMarks({ formData, marksId: marksList.activeMarksId }));
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={`${translate("formImportMarks_title")} "${marksList.activeMarksName}"`}
			close={handleClose}
		>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					<FormControl fullWidth margin='dense'>
						<TextField
							required
							id="outlined-basic"
							variant="outlined"
							size="small"
							type="file"
							onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
								},
								inputProps: { accept: "text/csv, text/plain" }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>

					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="submit"
							disabled={importMarksStatus.status === RequestStatus.LOADING}
							sx={{ fontSize: 11 }}
						>
							{translate('formImportMarks_btn')}
							{importMarksStatus.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
						</Button>
					</FormControl>
				</form>
				{importMarksStatus.status === RequestStatus.LOADING &&
					<ProgressLinear value={importMarksStatus.progress} />
				}
			</>
		</ModalFormWindow>
	);
};

export default FormImportMarks;
