import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getStatusAutomark, selectAutoMarkingStart, selectCorpusName, startAutomark } from '../../../store/corpusSlice';
import { selectMarksList } from '../../../store/marksSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { CORPUS } from '../../../constants/accessRights';
import { AUTO_MARK_TASK_ID } from '../../../constants/cookieNames';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAutoMarkProps } from './FormAutoMark.props';

const FormAutoMark = ({ showModal, setShowModal, setShowNotification }: IFormAutoMarkProps): JSX.Element => {
	const [taskName, setTaskName] = useState<string>(''); // название задачи
	const [marksId, setMarksId] = useState<string>(''); // id словаря меток
	const [option, setOption] = useState<'addition' | 'replacement'>('replacement'); // способ авторазметки (дополнение/замена)

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const corpusName = useAppSelector(selectCorpusName); // store - имя активного корпуса
	const startAutoMarkingStatus = useAppSelector(selectAutoMarkingStart); // store - статус начала авторазметки

	const [_cookies, setCookie] = useCookies([AUTO_MARK_TASK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом начала авторазметки
	useEffect(() => {
		if (startAutoMarkingStatus.error !== ResponseStatus.SUCCESS || startAutoMarkingStatus.status === RequestStatus.FAILED || startAutoMarkingStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус авторазметки
		if (startAutoMarkingStatus.status === RequestStatus.IDLE && startAutoMarkingStatus.error === ResponseStatus.SUCCESS && startAutoMarkingStatus.message !== '') {
			setCookie(AUTO_MARK_TASK_ID, startAutoMarkingStatus.taskId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			isAccess(CORPUS.AUTOMARK_STATUS) && startAutoMarkingStatus.taskId && dispatch(getStatusAutomark(startAutoMarkingStatus.taskId));
		}
	}, [startAutoMarkingStatus]);

	// обработчик авторазметки
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		corpusName && dispatch(startAutomark({ taskName, corpusName, marksId, appendMarks: option === 'addition' ? 'yes' : 'no' })); // авторазметка
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startAutoMarkingStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAutoMark_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						label={translate("formAutoMark_inputName")}
						variant="outlined"
						value={taskName}
						onChange={(e) => setTaskName(e.target.value)}
						disabled={startAutoMarkingStatus.status === RequestStatus.LOADING}
						// error={showErrorExistingUsername}
						// helperText={showErrorExistingUsername ? translate("formAddingUser_loginExists") : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('formAutoMark_selectMarks')}</InputLabel>
					<Select
						required
						labelId="category-label"
						id="category"
						label={translate('formAutoMark_selectMarks')}
						disabled={startAutoMarkingStatus.status === RequestStatus.LOADING}
						value={marksId}
						onChange={(e) => setMarksId(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{marksList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13 }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="option-label" sx={{ fontSize: 13 }}>{translate('formAutoMark_selectOption')}</InputLabel>
					<Select
						required
						labelId="option-label"
						id="option"
						label={translate('formAutoMark_selectOption')}
						disabled={startAutoMarkingStatus.status === RequestStatus.LOADING}
						value={option}
						onChange={(e) => setOption(e.target.value as 'addition' | 'replacement')}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='replacement' sx={{ fontSize: 13 }}>{translate('formAutoMark_selectReplacement')}</MenuItem>
						<MenuItem value='addition' sx={{ fontSize: 13 }}>{translate('formAutoMark_selectAddition')}</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={startAutoMarkingStatus.status === RequestStatus.LOADING}
					>
						{startAutoMarkingStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAutoMark_startBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAutoMark_startBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAutoMark;
