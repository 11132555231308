import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectCategoriesList } from '../../../../store/qasSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { downloadFile } from '../../../../helpers/downloadFile';
import { backgroundColor } from '../../../../constants/colors';
import { IExportQuestionsToCsvProps } from './ExportQuestionsToCsv.props';

const ExportQuestionsToCsv = ({ styleNotActive, isAvailable, questionsList }: IExportQuestionsToCsvProps): JSX.Element => {
	const [categoryDictionary, setCategoryDictionary] = useState<Record<string, string>>({}); // словарь категорий

	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const translate = useTranslate(); // hook для перевода текста

	// следим за данными списка категорий и пишем словарь "id: name"
	useEffect(() => {
		if (categoriesList.data.length > 0) {
			const categoryObj: Record<string, string> = {};
			categoriesList.data.forEach(categoryItem => categoryObj[categoryItem.id] = categoryItem.name);
			setCategoryDictionary(categoryObj);
		}
	}, [categoriesList.data]);

	// функция формирования и экспорта вопросов/алиасов в формате корпуса .csv
	const exportCsv = (): void => {
		let str: string = '';
		const arrCategories: string[] = [];

		questionsList.forEach(questionItem => {
			if (!arrCategories.includes(questionItem.question)) {
				arrCategories.push(questionItem.question);
				str += ((questionItem.category && categoryDictionary[questionItem.category]) || questionItem.category) + '\t' + questionItem.question.replace('\n', '') + '\n';
			}
			questionItem.aliases.forEach(alias => {
				if (!arrCategories.includes(alias)) {
					arrCategories.push(alias);
					str += ((questionItem.category && categoryDictionary[questionItem.category]) || questionItem.category) + '\t' + alias.replace('\n', '') + '\n';
				}
			});
		});

		downloadFile(str, 'corpus_from_questions_list.csv'); // экспорт файла корпуса из вопросов
	};

	return (
		<div
			className={isAvailable ? '' : styleNotActive}
			onClick={() => isAvailable && exportCsv()}
			title={translate('controlsBtn_exportQuestionsFromCsvFile')}
		>
			<FontAwesomeIcon icon={faFileCsv} size="2xl" color={backgroundColor} />
		</div>
	);
};

export default ExportQuestionsToCsv;
