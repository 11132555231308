import { useState } from 'react';
import { Fade, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faCloudArrowDown, faCloudArrowUp, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearCopyResponseCorpus, clearExportResponseCorpus, clearPutResponseCorpus, exportCorpus, putCorpus, selectCorpus, selectCorpusCopyStatus, selectCorpusExportStatus, selectCorpusPutStatus } from '../../store/corpusSlice';
import { clearChangePasswordStatus, selectChangePasswordStatus } from '../../store/userSlice';
import { selectDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { CORPUS, SERVER } from '../../constants/accessRights';
import { backgroundColor } from '../../constants/colors';
import AlertDialog from '../AlertDialog/AlertDialog';
import FormImportCorpus from '../Forms/FormImportCorpus/FormImportCorpus';
import Generating from '../Controls/Buttons/Generating/Generating';
import FormCopy from '../Forms/FormCopy/FormCopy';
import FormChangePassword from '../Forms/FormChangePassword/FormChangePassword';
import UserMenu from '../Controls/Buttons/UserMenu/UserMenu';
import AutoMark from '../AutoMark/AutoMark';
import ServerStatus from '../ServerStatus/ServerStatus';
import Notification from '../Notification/Notification';
import NoticeSingleAction from '../Notification/NoticeSingleAction/NoticeSingleAction';
import { ClickedBtnType } from '../../types/corpusControlTypes';
import { RequestStatus } from '../../types/statusTypes';
import { ICorpusControlProps } from './CorpusControl.props';
import styles from './CorpusControl.module.scss';

const CorpusControl = ({ changeFlg, setChangeFlg, serviceType, setShowPage }: ICorpusControlProps) => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна при сохранении корпуса
	const [showModalImport, setShowModalImport] = useState<boolean>(false); // показ формы для импорта
	const [showModalCopy, setShowModalCopy] = useState<boolean>(false); // показ формы для копирования корпуса
	const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false); // показ формы для изменения пароля
	const [showNotificationLoading, setShowNotificationLoading] = useState<boolean>(false); // показ уведомления загрузки
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const [clickedButton, setClickedButton] = useState<ClickedBtnType>(''); // нажатая кнопка
	const [showAutoMark, setShowAutoMark] = useState<boolean>(false); // показ вкладки авторазметки
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const exportStatus = useAppSelector(selectCorpusExportStatus); // store - статус экспорта корпуса
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик экспортирования корпуса
	const exportHandler = async () => {
		if (corpus.status === RequestStatus.LOADING) return; // если идет загрузка корпуса - запрещаем скачивания файла
		else {
			setClickedButton('export'); // обозначаем нажатую кнопку
			setShowNotificationLoading(true); // включаем уведомление для начала экспорта
			setShowNotification(true); // включаем уведомление для полученных данных
			corpus.corpusName && dispatch(exportCorpus({ corpusName: corpus.corpusName, serviceType })); // экспорт корпуса данных
		}
	};

	// обработчик импортирования корпуса
	const importHandler = (): void => {
		corpus.status !== RequestStatus.LOADING && setShowModalImport(true); // только если не в момент загрузки корпуса
	};

	// обработчик копирования корпуса
	const copyHandler = (): void => {
		// работает, если нет не сохраненных данных в таблице корпуса и не загрузка корпуса
		if (!changeFlg && corpus.status !== RequestStatus.LOADING) {
			setClickedButton('copy'); // обозначаем нажатую кнопку
			setShowModalCopy(true); // открываем форму для копирования корпуса
		}
	};

	// обработчик сохранения корпуса
	const changeHandler = (): void => {
		// если есть не сохраненные изменения и не загрузка корпуса
		if (changeFlg && corpus.status !== RequestStatus.LOADING) {
			setClickedButton('put'); // обозначаем нажатую кнопку
			setShowAlertDialog(false); // закрываем диалоговое окно
			setShowNotification(true); // включаем уведомление
			corpus.corpusName && dispatch(putCorpus({ corpusName: corpus.corpusName, text: corpus.data, serviceType })); // сохранение (изменение) корпуса данных
		}
	};

	return (
		<>
			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.corpusControl}>
					<div className={styles.corpusControlTitle}>{translate(serviceType)}</div>
					<div className={styles.corpusControlWrapper}>

						{corpus.corpusName && corpus.status !== RequestStatus.FAILED && Array.isArray(corpus.data.data) && <>
							<Fade in={corpus.corpusName !== null} timeout={500}>
								<div className={styles.corpusControlLeftBlock}>
									{isAccess(CORPUS.EXPORT) &&
										<div
											className={corpus.status === RequestStatus.LOADING ? styles.corpusControlBtnNotActive : ''}
											onClick={exportHandler}
											title={translate('corpusControl_exportBtn')}
										>
											<FontAwesomeIcon icon={faCloudArrowDown} size="2xl" color={backgroundColor} />
										</div>
									}
									{isAccess(CORPUS.SAVE) &&
										<div
											className={corpus.status === RequestStatus.LOADING ? styles.corpusControlBtnNotActive : ''}
											onClick={importHandler}
											title={translate('corpusControl_importBtn')}
										>
											<FontAwesomeIcon icon={faCloudArrowUp} size="2xl" color={backgroundColor} />
										</div>
									}
								</div>
							</Fade>

							<Fade in={corpus.corpusName !== null} timeout={500}>
								<div className={styles.modelControlFunctionsCenterBlock}>
									{isAccess([CORPUS.AUTOMARK_START, CORPUS.AUTOMARK_STOP, CORPUS.AUTOMARK_STATUS]) && serviceType === 'smc' &&
										<Generating
											styleNotActive={styles.corpusControlBtnNotActive}
											isAvailable={corpus.status !== RequestStatus.LOADING}
											generate='automark'
										/>
									}
									{isAccess(CORPUS.COPY) &&
										<div
											className={!changeFlg && corpus.status !== RequestStatus.LOADING ? '' : styles.corpusControlBtnNotActive}
											onClick={copyHandler}
											title={translate('corpusControl_copyBtn')}>
											<FontAwesomeIcon icon={faCopy} size="2xl" color={backgroundColor} />
										</div>
									}
									{isAccess(CORPUS.SAVE) &&
										<div
											className={changeFlg && corpus.status !== RequestStatus.LOADING ? '' : styles.corpusControlBtnNotActive}
											onClick={() => changeFlg && setShowAlertDialog(true)}
											title={translate('corpusControl_saveBtn')}>
											<FontAwesomeIcon icon={faFloppyDisk} size="2xl" color={backgroundColor} />
										</div>
									}
								</div>
							</Fade>
						</>}

						<div className={styles.corpusControlRightBlock}>
							<UserMenu setShowPage={setShowPage} />
						</div>
					</div>

					{/* бирки */}
					<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
						<section className={styles.tags}>
							{isAccess(CORPUS.AUTOMARK_LIST) && serviceType === 'smc' &&
								<div className={styles.autoMarkTag} onClick={() => setShowAutoMark(prev => !prev)}>
									<span className={styles.tagTitle}>{translate('tag_autoMark')}</span>
								</div>
							}
							{isAccess(SERVER.ADDRESSES) &&
								<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
									<span className={styles.tagTitle}>
										<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
										{translate('tag_servers')}
									</span>
								</div>
							}
						</section>
					</Slide>

					<AutoMark showAutoMark={showAutoMark} setShowAutoMark={setShowAutoMark} />
					<ServerStatus showServers={showServers} setShowServers={setShowServers} />
				</div>
			</Slide>

			{corpus.corpusName && showModalImport && <FormImportCorpus showModal={showModalImport} setShowModal={setShowModalImport} name={corpus.corpusName} serviceType={serviceType} setChangeFlg={setChangeFlg} />}
			{showModalCopy && <FormCopy showModal={showModalCopy} setShowModal={setShowModalCopy} corpusName={corpus.corpusName} serviceType={serviceType} setShowNotification={setShowNotification} />}
			{showModalChangePass && <FormChangePassword showModal={showModalChangePass} setShowModal={setShowModalChangePass} setShowNotification={setShowNotification} />}


			{showNotificationLoading && <NoticeSingleAction showNotification={showNotificationLoading} setShowNotification={setShowNotificationLoading} title='noticeExport_corpusLoading' severity='info' dataResponse={exportStatus} />}
			{clickedButton === 'copy' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusCopyStatus} clearDataResponse={clearCopyResponseCorpus} titleFailed='noticeCopy_failed' titleSuccess='noticeCopy_success' />}
			{clickedButton === 'put' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusPutStatus} clearDataResponse={clearPutResponseCorpus} titleFailed='noticePut_failed' titleSuccess='noticePut_success' />}
			{clickedButton === 'export' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusExportStatus} clearDataResponse={clearExportResponseCorpus} titleFailed='noticeExport_corpusFailed' titleSuccess='noticeExport_corpusSuccess' />}
			{clickedButton === 'changePass' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectChangePasswordStatus} clearDataResponse={clearChangePasswordStatus} titleFailed='noticeChangePassword_failed' titleSuccess='noticeChangePassword_success' />}

			<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={changeHandler} title='dialog_saveCorpusTitle' description='dialog_saveCorpusConfirm' name={String(corpus.corpusName)} />
		</>
	);
};

export default CorpusControl;
