import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeCategoryDataRobot, deleteCategoryDataRobot, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import { changeCategoryDataElem, changeCategoryDataEndpoint, deleteCategoryDataElem, deleteCategoryDataEndpoint, selectDataList } from '../../../store/sesSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { DataElemActionEventType } from '../../../types/sesTypes';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { ICategoryDataProps } from './CategoryData.props';
import styles from './CategoryData.module.scss';

const CategoryData = ({ categoryData, itsAction, actionEvent, channel, channelIdx, categoryId, categoryBlockIdx, categoryIdx, setChangeFlg }: ICategoryDataProps): JSX.Element => {
	const [selectDataElemId, setSelectDataElemId] = useState<string>(categoryData.data); // id элемента данных
	const [inputParam, setInputParam] = useState<string>(categoryData.value); // параметр

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик изменения элемента данных
	const changeDataElemHandler = (e: SelectChangeEvent<string>): void => {
		setSelectDataElemId(e.target.value);
		itsAction === 'robot' && dispatch((changeCategoryDataRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryIdx,
			categoryBlockIdx,
			data: { data: e.target.value, value: inputParam },
		})));
		itsAction === 'dataElement' && dispatch((changeCategoryDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: { data: e.target.value, value: inputParam },
		})));
		itsAction === 'endpoint' && dispatch((changeCategoryDataEndpoint({
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: { data: e.target.value, value: inputParam },
		})));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения значения
	const changeValueHandler = (): void => {
		itsAction === 'robot' && dispatch((changeCategoryDataRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: { data: selectDataElemId, value: inputParam },
		})));
		itsAction === 'dataElement' && dispatch((changeCategoryDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: { data: selectDataElemId, value: inputParam },
		})));
		itsAction === 'endpoint' && dispatch((changeCategoryDataEndpoint({
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: { data: selectDataElemId, value: inputParam },
		})));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления данных категории
	const deleteDataHandler = () => {
		itsAction === 'robot' && dispatch((deleteCategoryDataRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
		})));
		itsAction === 'dataElement' && dispatch((deleteCategoryDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
		})));
		itsAction === 'endpoint' && dispatch((deleteCategoryDataEndpoint({
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
		})));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.categoryDataBlock}>
			{/* id элемента данных */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('categoryData_selectDataElem')}</InputLabel>
				<Select
					required
					label={translate('categoryData_selectDataElem')}
					value={selectDataElemId}
					onChange={changeDataElemHandler}
					disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{/* не найденный элемент данных из списка */}
					{!dataElemList.data.find(elem => elem.id === selectDataElemId) &&
						<MenuItem value={selectDataElemId} sx={{ fontSize: 13, color: colorRed }}>{selectDataElemId} ({translate('notFound')})</MenuItem>
					}
					{dataElemList.data.map(({ id, name }) =>
						<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* значение */}
			<FormControl fullWidth margin='dense'>
				<TextField
					label={translate('categoryData_inputValue')}
					variant="outlined"
					value={inputParam}
					onChange={(e) => setInputParam(e.target.value)}
					onBlur={changeValueHandler}
					disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			{/* удаление данных категории */}
			{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteDataHandler}
					title={translate('categoryData_deleteDataBtn')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default CategoryData;
