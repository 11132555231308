import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAnswerMakerStatus, clearDeletingQuestion, clearProcessingQuestionsStatus, clearQuestionsList, deleteQuestion, deleteQuestionsList, editingQuestionInQuestionsList, getQuestion, getQuestionsList, selectDeletingQuestion, selectEditingQuestion, selectFilter, selectGeneratingAnswersStopStatus, selectProcessingQuestionsStopStatus, selectQuestion, selectQuestionsList, specifyDeletionOnlyAnswers } from '../../../store/qasSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS, SERVER } from '../../../constants/accessRights';
import { QAS_QUESTION_ID } from '../../../constants/cookieNames';
import { DeleteQuestionsListTypes, IQuestionItem } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ToggleCheck from '../Buttons/ToggleCheck/ToggleCheck';
import MovingQuestions from '../Buttons/MovingQuestions/MovingQuestions';
import Delete from '../Buttons/Delete/Delete';
import ExportQuestionsToCsv from '../Buttons/ExportQuestionsToCsv/ExportQuestionsToCsv';
import Update from '../Buttons/Update/Update';
import Generating from '../Buttons/Generating/Generating';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import LogQuestions from '../../LogQuestions/LogQuestions';
import Categories from '../../Categories/Categories';
import Channels from '../../Channels/Channels';
import ServerStatus from '../../ServerStatus/ServerStatus';
import AlertDialog from '../../AlertDialog/AlertDialog';
import AlertDialogTripleChoice from '../../AlertDialogTripleChoice/AlertDialogTripleChoice';
import Notification from '../../Notification/Notification';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IQuestionControlsProps } from './QuestionControls.props';
import styles from './QuestionControls.module.scss';

const QuestionControls = ({ setChangeFlg, setShowPage, inputQuestion, selectCategoryId, showAlertDialogDelete, setShowAlertDialogDelete, highlightMultipleQuestions, setHighlightMultipleQuestions, selectedListQuestionIds }: IQuestionControlsProps): JSX.Element => {
	const [showAlertDialogMultipleDelete, setShowAlertDialogMultipleDelete] = useState<boolean>(false); // показ диалогового окна для удаления нескольких вопросов
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления
	const [showNotificationMove, setShowNotificationMove] = useState<boolean>(false); // показ уведомления удаления (перемещения)

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showLog, setShowLog] = useState<boolean>(false); // показ вкладки логов массовой обработки вопросов
	const [showCategories, setShowCategories] = useState<boolean>(false); // показ вкладки категорий
	const [showChannels, setShowChannels] = useState<boolean>(false); // показ вкладки каналов
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const question = useAppSelector(selectQuestion); // store - вопрос/ответ
	const editStatus = useAppSelector(selectEditingQuestion); // store - статус сохранения ответов
	const deletionStatus = useAppSelector(selectDeletingQuestion); // store - статус удаления вопроса
	const stopGenerationStatus = useAppSelector(selectGeneratingAnswersStopStatus); // store - статус остановки генерации ответов
	const stopProcessingQuestionsStatus = useAppSelector(selectProcessingQuestionsStopStatus); // store - статус остановки массовой обработки вопросов
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const [_cookies, setCookie] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления вопроса, остановки генерации ответов/массовой обработки вопросов
	useEffect(() => {
		if (editStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_saveQuestionTitle' }); // если идет сохранение ответов
		else if (deletionStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_deleteQuestionTitle' }); // если идет удаление вопроса
		else if (stopGenerationStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_stopGenerationAnswersTitle' }); // если идет остановка генерации ответов
		else if (stopProcessingQuestionsStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_stopProcessingQuestionsTitle' }); // если идет остановка массовой обработки вопросов
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранение прошло успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message !== '') {
			setChangeFlg(false); // выключаем флаг
			// поиск вопроса в списке вопросов
			const foundQuestion = questionsList.data.find(questionItem => questionItem.id === question.questionId);
			// если изменился текст вопроса или категория
			if (foundQuestion && (foundQuestion?.question !== inputQuestion || foundQuestion.category !== selectCategoryId)) {
				dispatch(editingQuestionInQuestionsList({
					id: foundQuestion.id,
					question: inputQuestion,
					aliases: foundQuestion.aliases,
					candidates: foundQuestion.candidates,
					category: selectCategoryId,
					checked: foundQuestion.checked,
					unchecked: foundQuestion.unchecked,
				})); // меняем вопрос в списке вопросов
			}
		}
		// если удаление прошло успешно
		if (deletionStatus.status === RequestStatus.IDLE && deletionStatus.error === ResponseStatus.SUCCESS && deletionStatus.message !== '') {
			// если удалялись вопрос/вопросы
			if (!deletionStatus.deletingAnswers) {
				const foundIdxDeleteQuestion = questionsList.data.filter(filterFunction).findIndex(questionItem => questionItem.id === question.questionId); // idx удаленного вопроса
				// если найден удаленный вопрос и в списке больше одного вопроса
				if (foundIdxDeleteQuestion >= 0 && questionsList.data.filter(filterFunction).length > 1) {
					// id предыдущего вопроса, если удаляемый вопрос был последним, или следующего 
					const questionId = questionsList.data.filter(filterFunction).length - 1 === foundIdxDeleteQuestion ?
						questionsList.data.filter(filterFunction)[foundIdxDeleteQuestion - 1].id
						:
						questionsList.data.filter(filterFunction)[foundIdxDeleteQuestion + 1].id;
					setCookie(QAS_QUESTION_ID, questionId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
				}
			}
			dispatch(clearQuestionsList()); // очищаем список вопросов
			dispatch(getQuestionsList({ answer: filter.answer, question: filter.question })); // получаем заново список вопросов
			setHighlightMultipleQuestions(false); // убираем выделение вопросов
		}
		// если остановка генерации ответов прошла успешно - очищаем статус процесса генерации
		if (stopGenerationStatus.status === RequestStatus.IDLE && stopGenerationStatus.error === ResponseStatus.SUCCESS && stopGenerationStatus.message !== '') dispatch(clearAnswerMakerStatus());
		// если остановка массовой обработки вопросов прошла успешно - очищаем статус процесса
		if (stopProcessingQuestionsStatus.status === RequestStatus.IDLE && stopProcessingQuestionsStatus.error === ResponseStatus.SUCCESS && stopProcessingQuestionsStatus.message !== '') dispatch(clearProcessingQuestionsStatus());
	}, [editStatus, deletionStatus, stopGenerationStatus, stopProcessingQuestionsStatus]);

	// функция фильтрации списка вопросов
	const filterFunction = (questionItem: IQuestionItem): boolean => {
		return (questionItem.category === filter.category || filter.category === '') &&
			((filter.answerType === 'all' && (questionItem.checked >= 0 || questionItem.unchecked >= 0)) ||
				(filter.answerType === 'checked' && questionItem.checked > 0) ||
				(filter.answerType === 'unchecked' && questionItem.unchecked > 0) ||
				(filter.answerType === 'empty' && (questionItem.checked === 0 && questionItem.unchecked === 0))
			);
	};

	// обработчик удаления вопроса
	const deleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		question.questionId && dispatch(deleteQuestion(question.questionId)); // удаление вопроса
	};

	// обработчик удаления нескольких вопросов
	const deleteMultipleHandler = (type: DeleteQuestionsListTypes): void => {
		setShowAlertDialogMultipleDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		type === 'answers' && dispatch(specifyDeletionOnlyAnswers()); // уточнение - удаляем только ответы
		dispatch(deleteQuestionsList({ questionsIdList: selectedListQuestionIds, type })); // удаляем вопросы или ответы

		// функция последовательного удаления вопросов
		// async function deleteMultipleQuestions(selectedListQuestionIds: string[]) {
		// 	dispatch(changeMultipleDeleting(true)); // ставим флаг мульти-удаления
		// 	for (const questionId of selectedListQuestionIds) {
		// 		await dispatch(deleteQuestion(questionId)); // удаление вопроса
		// 	}
		// 	dispatch(changeMultipleDeleting(false)); // убираем флаг мульти-удаления
		// }
	};

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('qas')}</div>

						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsLeftBlock}>
									{isAccess(QAS.QUESTION_DELETE) &&
										<>
											<ToggleCheck
												styleNotActive={styles.controlsFunctionBtnNotActive}
												isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
												highlightMultipleQuestions={highlightMultipleQuestions}
												setHighlightMultipleQuestions={setHighlightMultipleQuestions}
											/>
											{selectedListQuestionIds.length > 0 && isAccess(QAS.QUESTION_APPEND) &&
												<MovingQuestions
													styleNotActive={styles.controlsFunctionBtnNotActive}
													isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING}
													selectedListQuestionIds={selectedListQuestionIds}
													setShowNotification={setShowNotificationMove}
												/>
											}
											{selectedListQuestionIds.length > 0 &&
												<Delete
													styleNotActive={styles.controlsFunctionBtnNotActive}
													isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING}
													setShowAlertDialog={setShowAlertDialogMultipleDelete}
													title='controlsBtn_deleteMultipleQuestions'
												/>
											}
										</>
									}
								</div>
								<div className={styles.controlsFunctionsRightBlock}>
									<ExportQuestionsToCsv
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={questionsList.data.filter(filterFunction).length > 0}
										questionsList={questionsList.data.filter(filterFunction)}
									/>
								</div>
							</div>

							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsLeftBlock}>
									{isAccess(QAS.QUESTION_GET) &&
										<Update
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING && question.questionId !== null}
											title='controlsBtn_updateQuestion'
											handler={() => {
												if (question.questionId) {
													dispatch(getQuestion(question.questionId));
													setChangeFlg(false);
												}
											}}
										/>
									}
									{isAccess([QAS.ANSWER_MAKER_START, QAS.ANSWER_MAKER_STOP, QAS.ANSWER_MAKER_STATUS]) &&
										<Generating
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
											generate='answers'
										/>
									}
									{isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) &&
										<Generating
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
											generate='all'
										/>
									}
								</div>

								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>

				{/* бирки */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
					<section className={styles.tags}>
						{isAccess(QAS.PROCESSING_QUESTIONS_LOG) &&
							<div className={styles.logTag} onClick={() => setShowLog(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_logsQuestion')}</span>
							</div>
						}
						{isAccess(QAS.CATEGORY_LIST) &&
							<div className={styles.categoriesTag} onClick={() => setShowCategories(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_categories')}</span>
							</div>
						}
						{isAccess(QAS.CHANNEL_LIST) &&
							<div className={styles.channelsTag} onClick={() => setShowChannels(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_channels')}</span>
							</div>
						}
						{isAccess(SERVER.ADDRESSES) &&
							<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
								<span className={styles.tagTitle}>
									<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
									{translate('tag_servers')}
								</span>
							</div>
						}
					</section>
				</Slide>

				<LogQuestions showLog={showLog} setShowLog={setShowLog} />
				<Categories showCategories={showCategories} setShowCategories={setShowCategories} />
				<Channels showChannels={showChannels} setShowChannels={setShowChannels} />
				<ServerStatus showServers={showServers} setShowServers={setShowServers} />

				{showAlertDialogDelete &&
					<AlertDialog
						showAlertDialog={showAlertDialogDelete}
						setShowAlertDialog={setShowAlertDialogDelete}
						submitHandler={deleteHandler}
						title='dialog_deleteQuestionTitle'
						description='dialog_deleteQuestionConfirm'
					/>
				}
				{showAlertDialogMultipleDelete &&
					<AlertDialogTripleChoice
						showAlertDialog={showAlertDialogMultipleDelete}
						setShowAlertDialog={setShowAlertDialogMultipleDelete}
						submitHandler={deleteMultipleHandler}
						title='dialog_deleteMultipleQuestionTitle'
						description='dialog_deleteMultipleQuestionConfirm'
					/>
				}
			</div>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для удаления вопроса */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletingQuestion}
					clearDataResponse={clearDeletingQuestion}
					titleFailed='noticeDelete_failed'
					titleSuccess='noticeDelete_success'
				/>
			}
			{/* уведомление для перемещения вопроса */}
			{showNotificationMove &&
				<Notification
					showNotification={showNotificationMove}
					setShowNotification={setShowNotificationMove}
					selectDataResponse={selectDeletingQuestion}
					clearDataResponse={clearDeletingQuestion}
					titleFailed='noticeMoveQuestions_failed'
					titleSuccess='noticeMoveQuestions_success'
				/>
			}
		</>
	);
};

export default QuestionControls;
