import { useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import { selectDebuggerAnswer } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { IVariablesBlockProps } from './VariablesBlock.props';

const VariablesBlock = ({ variable: initialVariable, value: initialValue, index, variablesData, setVariablesData }: IVariablesBlockProps): JSX.Element => {

	const [variable, setVariable] = useState<string>(''); // переменная
	const [value, setValue] = useState<string>(''); // значение

	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	const translate = useTranslate(); // hook для перевода текста

	// следим за начальными состояниями и вписываем
	useEffect(() => {
		setVariable(initialVariable);
		setValue(initialValue);
	}, [initialVariable, initialValue]);

	// следим за значениями
	useEffect(() => {
		// если заполнены оба поля у последней пары - добавляем новую пару
		if (value !== '' && variable !== '' && variablesData.length - 1 === index) {
			setVariablesData(prev => ([...prev, ['', '', Math.random()]]));
		}
		// если не заполнено хотя бы одно поле у предпоследней пары - удаляем последнюю пару, если там пусто
		if ((value === '' || variable === '') && variablesData.length - 2 === index && variablesData[variablesData.length - 1][0] === '' && variablesData[variablesData.length - 1][1] === '') {
			setVariablesData(prev => {
				prev.pop();
				return [...prev];
			});
		}
		// если пусты оба поля и блок не последний и не первый - удаляем
		if (value === '' && variable === '' && variablesData.length - 1 !== index && variablesData.length > 1) {
			setVariablesData(prev => {
				prev.splice(index, 1);
				return [...prev];
			});
		}
	}, [value, variable]);

	return (
		<>
			{/* переменная */}
			<FormControl fullWidth>
				<TextField
					variant="outlined"
					placeholder={`${translate('chatWidget_inputVariable')} ${index + 1}`}
					value={variable}
					onChange={(e) => setVariable(e.target.value)}
					onBlur={(e) => setVariablesData(prev => {
						prev[index][0] = e.target.value;
						return [...prev];
					})}
					disabled={debuggerAnswer.session.length > 0}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>
			{/* значение */}
			<FormControl fullWidth>
				<TextField
					variant="outlined"
					placeholder={`${translate('chatWidget_inputValue')} ${index + 1}`}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					onBlur={(e) => setVariablesData(prev => {
						prev[index][1] = e.target.value;
						return [...prev];
					})}
					disabled={debuggerAnswer.session.length > 0}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>
		</>
	);
};

export default VariablesBlock;
