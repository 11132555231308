import { useState } from 'react';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn, faCheck, faCloudArrowDown, faCloudArrowUp, faFileArrowUp, faFileZipper, faFloppyDisk, faGaugeHigh, faGraduationCap, faLayerGroup, faPenToSquare, faPlay, faRotateLeft, faStop, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { applyModel, changeActiveType, clearApplyResponse, clearExportResponse, clearHandlerUpload, clearImportResponse, clearInstallResponse, clearRestoreResponse, exportModel, installModel, restoreModel, selectApplyStatus, selectErrorsModel, selectExportStatus, selectFullModel, selectHandler, selectImportStatus, selectInstallStatus, selectModelName, selectRestoreStatus, selectUploadHandlerStatus, uploadHandlerPy } from '../../store/modelSlice';
import { selectDataServers } from '../../store/serverSlice';
import { clearStopTrainModelSmc, clearTestModelSmc, clearTrainModelSmc, clearUpdateGroups, selectStopTrainModelSmc, selectTestModelSmc, selectTrainModelSmc, selectUpdateGroups, stopTrainModelSmc } from '../../store/smcSlice';
import { clearStopTrainModelSee, clearTrainModelSee, selectStopTrainModelSee, selectTrainModelSee, stopTrainModelSee } from '../../store/seeSlice';
import { clearExportDictionary, clearImportDictionary, clearPutDictionary, exportDictionary, putDictionary, selectDictionary, selectExportDictionaryStatus, selectImportDictionaryStatus, selectPutDictionaryStatus } from '../../store/ttsSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { CORPUS, HANDLER, MARKS, MODEL, SEE, SERVER, SMC, TTS } from '../../constants/accessRights';
import { backgroundColor } from '../../constants/colors';

import ManualCheckModal from '../ManualCheck/ManualCheckModal/ManualCheckModal';
import ServerStatus from '../ServerStatus/ServerStatus';
import Log from '../Log/Log';
import Errors from '../Errors/Errors';
import CodeEditorPython from '../CodeEditorPython/CodeEditorPython';
import FormImportModel from '../Forms/FormImportModel/FormImportModel';
import FormImportDictionary from '../Forms/FormImportDictionary/FormImportDictionary';
import FormTrain from '../Forms/FormTrain/FormTrain';
import FormUpdateGroups from '../Forms/FormUpdateGroups/FormUpdateGroups';
import FormTest from '../Forms/FormTest/FormTest';
import UserMenu from '../Controls/Buttons/UserMenu/UserMenu';
import AlertDialog from '../AlertDialog/AlertDialog';
import NoticeSingleAction from '../Notification/NoticeSingleAction/NoticeSingleAction';
import Notification from '../Notification/Notification';

import { ModelType } from '../../types/modelTypes';
import { ClickedBtnType, ModelBtnType } from '../../types/modelControlTypes';
import { ModelStatus, RequestStatus } from '../../types/statusTypes';
import { IModelControlProps } from './ModelControl.props';
import styles from './ModelControl.module.scss';

const ModelControl = ({ serviceType, changeFlg, activeMatrix, setShowPage }: IModelControlProps): JSX.Element => {
	const [showManualCheck, setShowManualCheck] = useState<boolean>(false); // показ вкладки ручной проверки модели
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов
	const [showLog, setShowLog] = useState<boolean>(false); // показ вкладки логов модели
	const [showErrors, setShowErrors] = useState<boolean>(false); // показ вкладки ошибок модели
	const [showErrorsMark, setShowErrorsMark] = useState<boolean>(false); // показ вкладки ошибок разметки модели
	const [showCodeEditor, setShowCodeEditor] = useState<boolean>(false); // показ вкладки редактора кода python
	const [showModalImportModel, setShowModalImportModel] = useState<boolean>(false); // показ формы импортирования модели
	const [showModalImportDictionary, setShowModalImportDictionary] = useState<boolean>(false); // показ формы импортирования словаря ударений
	const [showModalTrain, setShowModalTrain] = useState<boolean>(false); // показ формы обучения модели
	const [showModalUpdateGroups, setShowModalUpdateGroups] = useState<boolean>(false); // показ формы обновления групп в модели
	const [showModalTest, setShowModalTest] = useState<boolean>(false); // показ формы тестирования модели
	const [showNotificationLoading, setShowNotificationLoading] = useState<boolean>(false); // показ уведомления загрузки
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления
	const [clickedButton, setClickedButton] = useState<ClickedBtnType>(''); // нажатая кнопка
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна при сохранении таблицы ударений

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели
	const handler = useAppSelector(selectHandler); // store - handler.py
	const errors = useAppSelector(selectErrorsModel); // store - ошибки модели
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах
	const dictionary = useAppSelector(selectDictionary); // store - словарь ударений
	const exportModelStatus = useAppSelector(selectExportStatus); // store - статус экспортирования модели
	const exportDictionaryStatus = useAppSelector(selectExportDictionaryStatus); // store - статус экспорта словаря ударений

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// список типов модели
	const modelTypeBtn: ModelBtnType = [
		{ icon: faPenToSquare, type: 'future', title: translate('modelControl_futureBtn') },
		{ icon: faGaugeHigh, type: 'current', title: translate('modelControl_currentBtn') },
		{ icon: faFileZipper, type: 'previous', title: translate('modelControl_previousBtn') }
	];

	// обработчик смены активного типа модели
	const activeTypeHandler = (type: ModelType): void => {
		fullModel.activeType !== type && dispatch(changeActiveType(type));
	};

	// обработчик экспортирования модели/словаря ударений
	const exportHandler = (): void => {
		// если нет данных о текущем типе модели или модель в статусе "Новая", "Ошибка", "Обучение" или "Тестирование" или загрузка словаря - запрещаем скачивание
		if ((serviceType !== 'tts' && fullModel.fullModel?.[fullModel.activeType] === null) || fullModel.fullModel?.[fullModel.activeType]?.status === ModelStatus.EMPTY || fullModel.fullModel?.[fullModel.activeType]?.status === ModelStatus.FAILED || fullModel.training || fullModel.testing || fullModel.status === RequestStatus.LOADING || dictionary.status === RequestStatus.LOADING) return;
		else {
			setShowNotificationLoading(true); // включаем уведомление для начала экспорта
			setShowNotification(true); // включаем уведомление для полученных данных
			if (serviceType === 'tts') {
				setClickedButton('exportDictionary'); // обозначаем нажатую кнопку
				dispatch(exportDictionary()); // экспорт словаря ударений
			} else {
				setClickedButton('exportModel'); // обозначаем нажатую кнопку
				fullModel.modelName && dispatch(exportModel({ modelName: fullModel.modelName, modelType: fullModel.activeType, serviceType })); // экспорт модели
			}
		}
	};

	// обработчик импортирования модели/словаря ударений
	const importHandler = (): void => {
		// только для черновика, не в момент обучения или тестирования и не при загрузке модели или для tts
		if ((fullModel.activeType === 'future' && !fullModel.training && !fullModel.testing && fullModel.status !== RequestStatus.LOADING) || serviceType === 'tts') {
			// обозначаем нажатую кнопку и открывам форму импортирования
			if (serviceType === 'tts') {
				setClickedButton('importDictionary');
				setShowModalImportDictionary(true);
			} else {
				setClickedButton('importModel');
				setShowModalImportModel(true);
			}
		}
	};

	// обработчик обучения модели
	const trainHandler = (): void => {
		// только для черновика, не в момент тестирования и не при загрузке модели
		if (fullModel.activeType === 'future' && !fullModel.testing && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('train'); // обозначаем нажатую кнопку
			setShowModalTrain(true); // открываем форму обучения модели
		}
	};

	// обработчик остановки обучения модели
	const stopTrainHandler = (): void => {
		// только для черновика, есть данные, без статуса "Ошибка" и не при загрузке модели
		if (fullModel.activeType === 'future' && fullModel.fullModel[fullModel.activeType] !== null && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.FAILED && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('stopTrain'); // обозначаем нажатую кнопку
			if (fullModel.modelName) {
				setShowNotification(true); // включаем уведомление
				serviceType === 'smc' && dispatch(stopTrainModelSmc({ modelName: fullModel.modelName })); // останавливаем обучение в smc
				serviceType === 'see' && dispatch(stopTrainModelSee({ modelName: fullModel.modelName })); // останавливаем обучение в see
			}
		}
	};

	// обработчик обновления групп в модели
	const updateGroupsHandler = (): void => {
		// только для черновика, и обученную или протестированную
		if (fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('updateGroups'); // обозначаем нажатую кнопку
			setShowModalUpdateGroups(true); // открываем форму установки групп
		}
	};

	// обработчик тестирования модели (только для smc)
	const testHandler = (): void => {
		// только для черновика с одним из статусов модели (обучен, протестирован, установлен, применен) и не при загрузке модели
		if (fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.INSTALLED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.APPLIED) && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('test'); // обозначаем нажатую кнопку
			setShowModalTest(true); // открываем форму тестирования модели
		}
	};

	// обработчик ручной проверки модели
	const manualCheckHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
		// только не при загрузке модели
		if (fullModel.status !== RequestStatus.LOADING) {
			(!showErrors || (showErrors && typeof errors.errors !== 'string' && errors.errors.length > 0)) && (!showErrorsMark || (showErrorsMark && typeof errors.errors !== 'string' && errors.errors.length > 0)) && setShowManualCheck(prev => !prev); // открываем вкладку ручной проверки модели, если вкладка ошибок закрыта или открыта и есть ошибки
		}
	};

	// установка обработчика в рабочую версию модели
	const installHandlerToCurrentModel = (): void => {
		// только для черновика, если есть модель черновика и рабочая, не идет обучение/тесты и не при загрузке модели и есть сам обработчик
		if (fullModel.activeType === 'future' && fullModel.fullModel.future !== null && fullModel.fullModel.current !== null && !fullModel.training && !fullModel.testing && fullModel.status !== RequestStatus.LOADING && typeof handler.data === 'string') {
			const filePy = new File([handler.data], 'handler.py', { type: "text/plain" }); // создаем экземпляр файла
			const formData = new FormData();
			if (filePy) {
				formData.append('filePy', filePy);
				fullModel.modelName && dispatch(uploadHandlerPy({ modelName: fullModel.modelName, serviceType, modelType: 'current', formData })); // отправка handler.py
			}
			setClickedButton('installHandler'); // обозначаем нажатую кнопку
			setShowNotification(true); // включаем уведомление
		}
	};

	// обработчик установки модели
	const installHandler = (): void => {
		// только для черновика с одним из статусов модели (обучен, протестирован) и не при загрузке модели
		if (fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('install'); // обозначаем нажатую кнопку
			if (fullModel.modelName) {
				setShowNotification(true); // включаем уведомление
				dispatch(installModel({ modelName: fullModel.modelName, serviceType })); // установка модели
			}
		}
	};

	// обработчик применения модели
	const applyHandler = (): void => {
		// только для черновика и не при загрузке модели
		if (fullModel.activeType === 'future' && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('apply'); // обозначаем нажатую кнопку
			if (fullModel.modelName) {
				setShowNotification(true); // включаем уведомление
				dispatch(applyModel({ modelName: fullModel.modelName, serviceType })); // применение модели
			}
		}
	};

	// обработчик восстановления модели
	const restoreHandler = (): void => {
		// только для архивной модели и не при загрузке модели
		if (fullModel.activeType === 'previous' && fullModel.status !== RequestStatus.LOADING) {
			setClickedButton('restore'); // обозначаем нажатую кнопку
			if (fullModel.modelName) {
				setShowNotification(true); // включаем уведомление
				dispatch(restoreModel({ modelName: fullModel.modelName, serviceType })); // восстановление модели из бэкапа
			}
		}
	};

	// обработчик сохранения таблицы ударений
	const changeHandler = (): void => {
		// если есть не сохраненные изменения
		if (changeFlg) {
			setClickedButton('save'); // обозначаем нажатую кнопку
			setShowAlertDialog(false); // закрываем диалоговое окно
			setShowNotification(true); // включаем уведомление
			dispatch(putDictionary({ text: dictionary.data })); // сохранение (изменение) таблицы ударений
		}
	};

	return (
		<div className={styles.modelControl}>

			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.modelControlWrapper}>
					<div className={styles.modelControlTitle}>{translate(serviceType)}</div>
					<section className={styles.modelControlTypeAndFunc} onClick={() => { setShowErrors(false); setShowErrorsMark(false); setShowManualCheck(false); }}>
						<Fade in={fullModel.status !== RequestStatus.FAILED && (fullModel.fullModel.current !== null || fullModel.fullModel.future !== null || fullModel.fullModel.previous !== null) && fullModel.modelName !== null && serviceType !== 'sbs' && serviceType !== 'tts'} timeout={500}>
							<div className={styles.modelControlTypes}>
								{fullModel.modelName !== null &&
									<div
										className={cn(styles.modelControlType, {
											[styles.modelControlTypeActive]: fullModel.activeType === modelTypeBtn[0].type,
											[styles.modelControlTypeNonActive]: fullModel.status === RequestStatus.LOADING
										})}
										onClick={() => fullModel.status !== RequestStatus.LOADING && activeTypeHandler(modelTypeBtn[0].type)}
										title={modelTypeBtn[0].title}
									>
										<FontAwesomeIcon icon={modelTypeBtn[0].icon} size="2xl" color={backgroundColor} />
									</div>
								}
								{modelTypeBtn.map((btn, idx) => {
									if (idx === 0) return undefined;
									return (
										<Fade in={fullModel.fullModel[btn.type] !== null} timeout={500} key={btn.type}>
											<div>
												<div
													className={cn(styles.modelControlType, {
														[styles.modelControlTypeActive]: fullModel.activeType === btn.type,
														[styles.modelControlTypeNonActive]: fullModel.status === RequestStatus.LOADING
													})}
													onClick={() => fullModel.status !== RequestStatus.LOADING && fullModel.fullModel[btn.type] !== null && activeTypeHandler(btn.type)}
													title={btn.title}
												>
													<FontAwesomeIcon icon={btn.icon} size="2xl" color={backgroundColor} />
												</div>
											</div>
										</Fade>
									);
								})}
							</div>
						</Fade>

						<div className={styles.modelControlFunctions}>

							{fullModel.status !== RequestStatus.FAILED && (serviceType === 'tts' || fullModel.fullModel.current !== null || fullModel.fullModel.future !== null || fullModel.fullModel.previous !== null) && serviceType !== 'sbs' && fullModel.modelName &&
								<Fade in={fullModel.modelName !== null} timeout={500}>
									<div className={styles.modelControlFunctionsLeftBlock}>
										{((serviceType !== 'tts' && isAccess(MODEL.EXPORT)) || (serviceType === 'tts' && isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_EXPORT]))) &&
											<div
												className={((serviceType !== 'tts' && fullModel.fullModel?.[fullModel.activeType] === null) || fullModel.fullModel?.[fullModel.activeType]?.status === ModelStatus.EMPTY || fullModel.fullModel?.[fullModel.activeType]?.status === ModelStatus.FAILED || fullModel.training || fullModel.testing || fullModel.status === RequestStatus.LOADING || dictionary.status === RequestStatus.LOADING) ? styles.modelControlFunctionBtnNotActive : ''}
												onClick={exportHandler}
												title={translate('modelControl_exportBtn')}
											>
												<FontAwesomeIcon icon={faCloudArrowDown} size="2xl" color={backgroundColor} />
											</div>
										}
										{((serviceType !== 'tts' && isAccess(MODEL.IMPORT)) || (serviceType === 'tts' && isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_IMPORT]))) &&
											<div
												className={(fullModel.activeType !== 'future' || fullModel.training || fullModel.testing || fullModel.status === RequestStatus.LOADING || dictionary.status === RequestStatus.LOADING) ? styles.modelControlFunctionBtnNotActive : ''}
												onClick={importHandler}
												title={translate('modelControl_importBtn')}
											>
												<FontAwesomeIcon icon={faCloudArrowUp} size="2xl" color={backgroundColor} />
											</div>
										}
									</div>
								</Fade>
							}

							{fullModel.status !== RequestStatus.FAILED && (fullModel.fullModel.current !== null || fullModel.fullModel.future !== null || fullModel.fullModel.previous !== null) && serviceType !== 'sbs' && serviceType !== 'tts' && fullModel.modelName &&
								<Fade in={fullModel.modelName !== null} timeout={500}>
									<div className={styles.modelControlFunctionsCenterBlock}>
										{serviceType !== 'spr' &&
											<>
												{fullModel.activeType && fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINING ?
													<>
														{((serviceType === 'smc' && isAccess(SMC.STOP_TRAIN)) || (serviceType === 'see' && isAccess(SEE.STOP_TRAIN))) && <div
															className={fullModel.activeType !== 'future' || fullModel.status === RequestStatus.LOADING ? styles.modelControlFunctionBtnNotActive : ''}
															onClick={stopTrainHandler}
															title={translate('modelControl_stopTrainBtn')}>
															<FontAwesomeIcon icon={faStop} size="2xl" color={backgroundColor} />
														</div>}
													</>
													:
													<>
														{((serviceType === 'smc' && (isAccess([SMC.TRAIN, CORPUS.LIST]) || isAccess([SMC.TRAIN, MARKS.LIST]))) || (serviceType === 'see' && isAccess([SEE.TRAIN, CORPUS.LIST]))) && <div
															className={(fullModel.activeType !== 'future' || fullModel.testing || fullModel.status === RequestStatus.LOADING) ? styles.modelControlFunctionBtnNotActive : ''}
															onClick={trainHandler}
															title={translate('modelControl_trainBtn')}>
															<FontAwesomeIcon icon={faGraduationCap} size="2xl" color={backgroundColor} />
														</div>}
													</>
												}
											</>
										}
										{serviceType === 'smc' && isAccess([SMC.UPDATE_GROUPS, CORPUS.LIST]) &&
											<div
												className={(fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status !== RequestStatus.LOADING) ? '' : styles.modelControlFunctionBtnNotActive}
												onClick={updateGroupsHandler}
												title={translate('modelControl_updateGroupsBtn')}>
												<FontAwesomeIcon icon={faLayerGroup} size="2xl" color={backgroundColor} />
											</div>
										}
										{serviceType === 'smc' && isAccess([SMC.TEST, CORPUS.LIST]) && <div
											className={(fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.INSTALLED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.APPLIED) && fullModel.status !== RequestStatus.LOADING) ? '' : styles.modelControlFunctionBtnNotActive}
											onClick={testHandler}
											title={translate('modelControl_testBtn')}>
											<FontAwesomeIcon icon={faChartColumn} size="2xl" color={backgroundColor} />
										</div>}
										{(serviceType === 'smc' || serviceType === 'see') && fullModel.activeType && isAccess(HANDLER.SAVE) &&
											<div
												className={fullModel.activeType === 'future' && fullModel.fullModel.future !== null && fullModel.fullModel.current !== null && !fullModel.training && !fullModel.testing && fullModel.status !== RequestStatus.LOADING && typeof handler.data === 'string' ? '' : styles.modelControlFunctionBtnNotActive}
												onClick={installHandlerToCurrentModel}
												title={translate('modelControl_installHandlerBtn')}>
												<FontAwesomeIcon icon={faFileArrowUp} size="2xl" color={backgroundColor} />
											</div>
										}
										{fullModel.activeType && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.INSTALLED && isAccess(MODEL.INSTALL) &&
											<div
												className={fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status !== RequestStatus.LOADING ? '' : styles.modelControlFunctionBtnNotActive}
												onClick={installHandler}
												title={translate('modelControl_installBtn')}>
												<FontAwesomeIcon icon={faWrench} size="2xl" color={backgroundColor} />
											</div>
										}
										{fullModel.activeType && fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.INSTALLED && isAccess(MODEL.APPLY) &&
											<div
												className={fullModel.activeType !== 'future' || fullModel.status === RequestStatus.LOADING ? styles.modelControlFunctionBtnNotActive : ''}
												onClick={applyHandler}
												title={translate('modelControl_applyBtn')}>
												<FontAwesomeIcon icon={faPlay} size="2xl" color={backgroundColor} />
											</div>
										}
										{isAccess(MODEL.RESTORE) && <div
											className={fullModel.activeType !== 'previous' || fullModel.status === RequestStatus.LOADING ? styles.modelControlFunctionBtnNotActive : ''}
											onClick={restoreHandler}
											title={translate('modelControl_restoreBtn')}>
											<FontAwesomeIcon icon={faRotateLeft} size="2xl" color={backgroundColor} />
										</div>}
									</div>
								</Fade>
							}

							{(serviceType === 'smc' || serviceType === 'see') &&
								<Fade in={true} timeout={500}>
									<div className={styles.modelControlFunctionsCenterBlock}>
										{((serviceType === 'smc' && (isAccess(SMC.CLASSIFY) || isAccess(SMC.COMPRESS) || isAccess(SMC.EMOTION) || isAccess(SMC.PUNCTUATE) || isAccess(SMC.CORRECT) || isAccess(SMC.NORMALIZE))) || (serviceType === 'see' && isAccess(SEE.SEARCH_ENTITIES))) &&
											<div
												className={(fullModel.status !== RequestStatus.LOADING && (!showErrors || (showErrors && typeof errors.errors !== 'string' && errors.errors.length > 0)) && (!showErrorsMark || (showErrorsMark && typeof errors.errors !== 'string' && errors.errors.length > 0))) ? '' : styles.modelControlFunctionBtnNotActive}
												onClick={(e) => manualCheckHandler(e)}
												title={translate('modelControl_manualCheckBtn')}>
												<FontAwesomeIcon icon={faCheck} size="2xl" color={backgroundColor} />
											</div>
										}
									</div>
								</Fade>
							}

							{serviceType === 'tts' && isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_SAVE]) &&
								<Fade in={fullModel.modelName !== null} timeout={500}>
									<div className={styles.modelControlFunctionsTtsBlock}>
										<div
											className={changeFlg ? '' : styles.modelControlFunctionBtnNotActive}
											onClick={() => changeFlg && setShowAlertDialog(true)}
											title={translate('modelControl_saveStressDictionaryBtn')}>
											<FontAwesomeIcon icon={faFloppyDisk} size="2xl" color={backgroundColor} />
										</div>
									</div>
								</Fade>
							}

							<div className={styles.modelControlFunctionsRightBlock}>
								<UserMenu setShowPage={setShowPage} />
							</div>
						</div>
					</section>
				</div>
			</Slide>

			<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
				<section className={styles.tags}>
					{(serviceType === 'smc' || serviceType === 'see') && fullModel.modelName &&
						<>
							{isAccess(HANDLER.GET) &&
								<div className={styles.codeEditorTag} onClick={() => setShowCodeEditor(prev => !prev)}>
									<span className={styles.tagTitle}>{translate('tag_handler')}</span>
								</div>
							}
							{isAccess(MODEL.LOGS) &&
								<div className={styles.logsTag} onClick={() => setShowLog(prev => !prev)}>
									<span className={styles.tagTitle}>{translate('tag_logs')}</span>
								</div>
							}
							{isAccess(MODEL.ERRORS) && serviceType === 'smc' && activeMatrix === 'train' &&
								<div className={styles.errorsTag} onClick={() => setShowErrorsMark(prev => !prev)}>
									<span className={styles.tagTitle}>{translate('tag_errorsMark')}</span>
								</div>
							}
							{isAccess(MODEL.ERRORS) && fullModel.fullModel[fullModel.activeType] && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.EMPTY && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.FAILED && !fullModel.training &&
								<div className={styles.errorsTag} onClick={() => setShowErrors(prev => !prev)}>
									<span className={styles.tagTitle}>{translate(activeMatrix === 'train' ? 'tag_errorsTrain' : 'tag_errorsTest')}</span>
								</div>
							}
						</>
					}
					{isAccess(SERVER.ADDRESSES) &&
						<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
							<span className={styles.tagTitle}>
								<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
								{translate('tag_servers')}
							</span>
						</div>}
				</section>
			</Slide>

			{fullModel.modelName && showModalImportModel && <FormImportModel showModal={showModalImportModel} setShowModal={setShowModalImportModel} name={fullModel.modelName} serviceType={serviceType} setShowNotification={setShowNotification} />}
			{fullModel.modelName && showModalImportDictionary && <FormImportDictionary showModal={showModalImportDictionary} setShowModal={setShowModalImportDictionary} setShowNotification={setShowNotification} />}
			{fullModel.modelName && showModalTrain && <FormTrain showModal={showModalTrain} setShowModal={setShowModalTrain} modelName={fullModel.modelName} serviceType={serviceType} setShowNotification={setShowNotification} />}
			{fullModel.modelName && showModalUpdateGroups && <FormUpdateGroups showModal={showModalUpdateGroups} setShowModal={setShowModalUpdateGroups} modelName={fullModel.modelName} serviceType={serviceType} setShowNotification={setShowNotification} />}
			{fullModel.modelName && showModalTest && <FormTest showModal={showModalTest} setShowModal={setShowModalTest} modelName={fullModel.modelName} setShowNotification={setShowNotification} serviceType={serviceType} />}

			{showNotificationLoading && <NoticeSingleAction showNotification={showNotificationLoading} setShowNotification={setShowNotificationLoading} title={serviceType === 'tts' ? 'noticeExport_dictionaryLoading' : 'noticeExport_modelLoading'} severity='info' dataResponse={serviceType === 'tts' ? exportDictionaryStatus : exportModelStatus} />}
			{clickedButton === 'exportModel' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectExportStatus} clearDataResponse={clearExportResponse} titleFailed='noticeExport_modelFailed' titleSuccess='noticeExport_modelSuccess' />}
			{clickedButton === 'exportDictionary' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectExportDictionaryStatus} clearDataResponse={clearExportDictionary} titleFailed='noticeExport_dictionaryFailed' titleSuccess='noticeExport_dictionarySuccess' />}
			{clickedButton === 'importModel' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectImportStatus} clearDataResponse={clearImportResponse} titleFailed='noticeImport_importModelFailed' titleSuccess='noticeImport_importModelSuccess' />}
			{clickedButton === 'importDictionary' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectImportDictionaryStatus} clearDataResponse={clearImportDictionary} titleFailed='noticeImport_importDictionaryFailed' titleSuccess='noticeImport_importDictionarySuccess' />}
			{clickedButton === 'train' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={serviceType === 'smc' ? selectTrainModelSmc : selectTrainModelSee} clearDataResponse={serviceType === 'smc' ? clearTrainModelSmc : clearTrainModelSee} titleFailed='noticeTrain_failed' titleSuccess='noticeTrain_success' />}
			{clickedButton === 'stopTrain' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={serviceType === 'smc' ? selectStopTrainModelSmc : selectStopTrainModelSee} clearDataResponse={serviceType === 'smc' ? clearStopTrainModelSmc : clearStopTrainModelSee} titleFailed='noticeStopTrain_failed' titleSuccess='noticeStopTrain_success' />}
			{clickedButton === 'updateGroups' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectUpdateGroups} clearDataResponse={clearUpdateGroups} titleFailed='noticeUpdateGroups_failed' titleSuccess='noticeUpdateGroups_success' />}
			{clickedButton === 'test' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectTestModelSmc} clearDataResponse={clearTestModelSmc} titleFailed='noticeTest_failed' titleSuccess='noticeTest_success' />}
			{clickedButton === 'install' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectInstallStatus} clearDataResponse={clearInstallResponse} titleFailed='noticeInstall_failed' titleSuccess='noticeInstall_success' />}
			{clickedButton === 'apply' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectApplyStatus} clearDataResponse={clearApplyResponse} titleFailed='noticeApply_failed' titleSuccess='noticeApply_success' />}
			{clickedButton === 'restore' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectRestoreStatus} clearDataResponse={clearRestoreResponse} titleFailed='noticeRestore_failed' titleSuccess='noticeRestore_success' />}
			{clickedButton === 'save' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectPutDictionaryStatus} clearDataResponse={clearPutDictionary} titleFailed='noticeSaveStressDictionary_failed' titleSuccess='noticeSaveStressDictionary_success' />}
			{clickedButton === 'installHandler' && showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectUploadHandlerStatus} clearDataResponse={clearHandlerUpload} titleFailed='noticeInstallToCurrentHandler_failed' titleSuccess='noticeInstallToCurrentHandler_success' />}

			{!showErrors && !showErrorsMark && <ManualCheckModal showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} serviceType={serviceType} modelName={modelName} />}
			<CodeEditorPython showCodeEditor={showCodeEditor} setShowCodeEditor={setShowCodeEditor} serviceType={serviceType} />
			<Log showLog={showLog} setShowLog={setShowLog} serviceType={serviceType} />
			<Errors showErrors={showErrors} setShowErrors={setShowErrors} serviceType={serviceType} showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} typeErrors={activeMatrix} />
			<Errors showErrors={showErrorsMark} setShowErrors={setShowErrorsMark} serviceType={serviceType} showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} typeErrors='mark' />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />

			<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={changeHandler} title='dialog_saveStressDictionaryTitle' description='dialog_saveStressDictionaryConfirm' />
		</div>
	);
};

export default ModelControl;
