import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearDeleteResponse, clearState, getAllModels, getInfoModel, selectAllModels, selectApplyStatus, selectDeleteStatus, selectFullModel, selectImportStatus, selectInstallStatus, selectRestoreStatus, selectUploadHandlerStatus } from '../../store/modelSlice';
import { clearCorpuses, selectCorpusList } from '../../store/corpusSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import { selectStopTrainModelSmc, selectTestModelSmc, selectTrainModelSmc } from '../../store/smcSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL, SERVER } from '../../constants/accessRights';
import { findServiceRightByType } from '../../helpers/findServiceRightByType';
import { SelectMatrixType } from '../../types/modelTypes';
import { ModelStatus, RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import ModelNavBar from '../../components/Navbars/ModelNavBar/ModelNavBar';
import ModelControl from '../../components/ModelControl/ModelControl';
import ModelInfo from '../../components/ModelInfo/ModelInfo';
import MatrixBlock from '../../components/ConfusionMatrix/ConfusionMatrix.jsx';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import Notification from '../../components/Notification/Notification';
import { IClassifierProps } from './Classifier.props';
import styles from './Classifier.module.scss';

const Classifier = ({ serviceType }: IClassifierProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [selectMatrix, setSelectMatrix] = useState<SelectMatrixType>('test'); // активная матрица
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении модели
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста
	const dispatch = useAppDispatch();

	const allModels = useAppSelector(selectAllModels); // store - все модели
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const uploadHandlerStatus = useAppSelector(selectUploadHandlerStatus); // store - статус загрузки handler.py
	const installStatus = useAppSelector(selectInstallStatus); // store - статус установки модели
	const applyStatus = useAppSelector(selectApplyStatus); // store - статус применения модели
	const restoreStatus = useAppSelector(selectRestoreStatus); // store - статус восстановления модели
	const importStatus = useAppSelector(selectImportStatus); // store - статус импортирования модели
	const trainStatus = useAppSelector(selectTrainModelSmc); // store - статус обучения модели
	const testStatus = useAppSelector(selectTestModelSmc); // store - статус тестирования модели
	const stopTrainStatus = useAppSelector(selectStopTrainModelSmc); // store - статус остановки обучения модели
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов

	useEffect(() => {
		isAccess(MODEL.INFO) && dispatch(getAllModels({ serviceType })); // если есть доступ - получаем все модели
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// при уходе со страницы
		return () => {
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очищаем state
			corpusList.data.length > 0 && dispatch(clearCorpuses()); // очищаем список корпусов, если есть
		};
	}, []);

	// следим за статусом импорта модели 
	useEffect(() => {
		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// если идет импорт - удаляем автозапрос получения данных о серверах
		importStatus.status === RequestStatus.LOADING && clearInterval(interval);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
		};
	}, [importStatus.status]);

	// следим за активным типом модели, моделью полностью, статусом тестирования
	useEffect(() => {
		// если нет матрицы тестирования - ставим в активную матрицу обучения
		if (fullModel.fullModel[fullModel.activeType]?.matrix?.test === null) {
			setSelectMatrix('train');
			// иначе тестовая матрица становистя активной
		} else {
			setSelectMatrix('test');
		}
		fullModel.testing === true && setSelectMatrix('train'); // если идет тестирование, то активной становится матрица обучения
	}, [fullModel.activeType, fullModel.fullModel, fullModel.testing]);

	// следим за статусами обучения, остановки обучения, тестирования, установки, применения, восстановления, отправки обработчика модели и при изменении заново запрашиваем инфо о модели
	useEffect(() => {
		if (trainStatus?.status !== RequestStatus.LOADING && trainStatus?.message !== '') {
			// делаем запрос через 3 сек, чтобы успел сформироваться статус
			setTimeout(() => {
				fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
			}, 3000);
		}
		stopTrainStatus.status === RequestStatus.IDLE && stopTrainStatus.error === ResponseStatus.SUCCESS && stopTrainStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		testStatus.status !== RequestStatus.LOADING && testStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		installStatus.status === RequestStatus.IDLE && installStatus.error === ResponseStatus.SUCCESS && installStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		((applyStatus.status === RequestStatus.IDLE && applyStatus.message !== '') || applyStatus.status === RequestStatus.FAILED) && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		restoreStatus.status === RequestStatus.IDLE && restoreStatus.error === ResponseStatus.SUCCESS && restoreStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));

		// если идет обучение/остановка обучения/тестирование/установка/применение/восстановление модели - включаем экран блокировки с подписью
		if (trainStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_trainModelTitle' });
		else if (stopTrainStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_stopTrainModelTitle' });
		else if (testStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_testModelTitle' });
		else if (installStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_installModelTitle' });
		else if (applyStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_applyModelTitle' });
		else if (restoreStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_restoreModelTitle' });
		else if (uploadHandlerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_installHandlerToCurrentTitle' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем
	}, [trainStatus, stopTrainStatus, testStatus, installStatus, applyStatus, restoreStatus, uploadHandlerStatus]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={findServiceRightByType(serviceType)}>
			<>
				<ModelNavBar serviceType={serviceType} setShowPage={setShowPage} />
				<ModelControl serviceType={serviceType} activeMatrix={selectMatrix} setShowPage={setShowPage} />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{allModels.models !== null && typeof (allModels.models) !== 'string' && Object.keys(allModels.models).length !== 0 ?
							<>
								{((fullModel.status === RequestStatus.LOADING || fullModel.modelName === null) && !fullModel.training && !fullModel.testing) &&
									<div className={styles.loading}><ProgressCircle title={translate('progressCircle_modelLoadingTitle')} /></div>
								}
								{fullModel.training &&
									<div className={styles.training}>
										<div className={styles.trainingImg}></div>
										<p className={styles.trainingTitle}>{translate('smcSee_trainingTitle')}</p>
									</div>
								}
								{fullModel.status !== RequestStatus.FAILED && fullModel.fullModel?.[fullModel.activeType] !== null &&
									<>
										<ModelInfo setShowNotificationDelete={setShowNotificationDelete} serviceType={serviceType} />

										<Fade in={(fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null && fullModel.fullModel?.[fullModel.activeType]?.status !== ModelStatus.EMPTY && fullModel.training !== true) || fullModel.testing === true} timeout={500}>
											<div className={cn(styles.wrapperMatrix, {
												[styles.shadowFuture]: fullModel.activeType === 'future',
												[styles.shadowCurrent]: fullModel.activeType === 'current',
												[styles.shadowPrevious]: fullModel.activeType === 'previous'
											})}>
												<MatrixBlock selectMatrix={selectMatrix} setSelectMatrix={setSelectMatrix} translate={translate} />
											</div>
										</Fade>
									</>
								}
								{fullModel.status !== RequestStatus.LOADING && (fullModel.status === RequestStatus.FAILED || fullModel.fullModel?.[fullModel.activeType] === null) &&
									<div className={styles.notFound}><div>{translate(fullModel.message || 'notFound')}</div></div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</Fade>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
				{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDeleteStatus} clearDataResponse={clearDeleteResponse} titleFailed='noticeDelete_modelFailed' titleSuccess='noticeDelete_modelSuccess' />}
			</>
		</PageWrapper>
	);
};

export default Classifier;
