export const HOST = process.env.REACT_APP_HOST_KONSTANTIN || window.location.origin;
export const URL_AUTH = `${HOST}/auth`;
export const URL_USER = `${HOST}/auth/user`;
export const URL_MODEL = `${HOST}/model`;
export const URL_SERVER = `${HOST}/server/get`;
export const URL_CORPUS = `${HOST}/corpus`;
export const URL_MARKS = `${HOST}/marks`;
export const URL_SMC = `${HOST}/smc`;
export const URL_SEE = `${HOST}/see`;
export const URL_SPR = `${HOST}/spr`;
export const URL_TRANSCRIPTION = `${HOST}/transcription`;
export const URL_SBS = `${HOST}/sbs`;
export const URL_TTS = `${HOST}/tts`;
export const URL_QAS = `${HOST}/qas`;
export const URL_SES = `${HOST}/ses`;
export const URL_LANG = `${HOST}/lang`; // словарь для перевода текста
export const URL_WEBSOCKET = 'ws://185.132.242.179:6187';

export const ENDPOINTS_LANG = {
	WALLPAPER: 'wallpaper', // случайные обои (get)
};

export const ENDPOINTS_AUTH = {
	ACCESS: 'access', // авторизация (post)
	REFRESH: 'refresh', // обновление токена (get)
	RIGHTS: 'rights', // права доступа (get)
	VERSION: 'version', // версия системы (get)
	ROLES: 'roles', // список ролей (get)
	ROLE_ADD: 'role/add', // добавление роли (post)
	ROLE_EDIT: 'role/modify', // изменение роли (post)
	ROLE_DELETE: 'role/delete', // удаление роли (delete)
	DATASETS: 'datasets', // список наборов данных (get)
	DATASET_ADD: 'dataset/add', // добавление набора даннных (post)
	DATASET_EDIT: 'dataset/modify', // изменение набора данных (post)
	DATASET_DELETE: 'dataset/delete', // удаление набора данных (delete)
};

export const ENDPOINTS_USER = {
	LIST: 'list', // список пользователей (get)
	GET: 'get', // информация о пользователе (get)
	ADD: 'add', // добавить пользователя (post)
	MODIFY: 'modify', // изменить инфо пользователя (post)
	DELETE: 'delete', // удалить пользователя (delete)
	INFO: 'info', // информация о текущем пользователе (get)
	PASSWORD: 'password', // изменить пароль текущего пользователя (post)
	USER_LOGS: 'userlog', // логи пользователей (post)
};

export const ENDPOINTS_MODEL = {
	EXPORT: 'export', // экспорт .zip (get)
	ADD: 'add', // создание новой модели
	IMPORT: 'import', // импорт .zip, импорт всегда происходит в future; если future существовало, то оно затирается, если модели нет - то создается новая (post)
	INFO: 'info',	// инфо о модели (без параметров - все, с конкретным - полную модель) (get)
	INSTALL: 'install', // установка модели типа future на серверы кластера (post)
	APPLY: 'apply', // применение модели типа future на всех серверах кластера, т.е. черновик становится рабочей моделью на всех серверах кластера, а модель current бэкапируется в previous (post)
	RESTORE: 'restore', // восстановление бэкапа модели, т.е. модель previous становится моделью current (post)
	DELETE: 'delete', // полное удаление с сервера управления и всех серверов кластера (delete)
	LOG: 'log', // логи (get)
	ERRORS: 'errors', // ошибки (get)
	HANDLER: 'handler', // обработчик (get, post, delete) - получить, отправить, удалить
};

export const ENDPOINTS_SERVER = {
	ADDRESSES: 'addresses', // все адреса серверов сервиса (get)
	MODELS: 'models', // список моделей по всем серверам указанного типа (cluster или trainer) (get)
};

export const ENDPOINTS_CORPUS = {
	EXPORT: 'export', // экспорт .csv (get)
	IMPORT: 'import', // импорт (post)
	LIST: 'list', // список корпусов (get)
	GET: 'get', // получить корпус (get)
	COPY: 'copy', // копирование (post)
	PUT: 'put', // изменение, если нет такого - создание (post)
	RENAME: 'rename', // переименовывание (post)
	DELETE: 'delete', // удаление (delete)

	AUTOMARK_START: 'automark/start', // старт авторазметки (get)
	AUTOMARK_STOP: 'automark/stop', // остановка авторазметки (get)
	AUTOMARK_STATUS: 'automark/status', // статус авторазметки (get)
	AUTOMARK_LIST: 'automark/list', // список задач авторазметки (get)
	AUTOMARK_RESULT: 'automark/result', // результат авторазметки (get)
};

export const ENDPOINTS_MARKS = {
	EXPORT: 'export', // экспорт .csv (get)
	IMPORT: 'import', // импорт (post)
	LIST: 'list', // список меток (get)
	GET: 'get', // получить метки (get)
	ADD: 'add', // добавить (post)
	PUT: 'put', // изменение (post)
	RENAME: 'rename', // переименовывание (post)
	DELETE: 'delete', // удаление (delete)
};

export const ENDPOINTS_SMC = {
	TEST: 'test', // тестирование модели (post)
	MANUAL_CHECK: 'classify', // ручная проверка модели (get)
	TRAIN: 'train', // обучение модели (post)
	STOP_TRAIN: 'stop', // остановка обучения модели (post)
	COMPRESS: 'compress', // сжатие фразы (get)
	EMOTION: 'emotion', // определение эмоции (get)
	GROUPS: 'groups', // обновление групп в модели (post)
	PUNCTUATE: 'punctuate', // пунктуация (get)
	CORRECT: 'correct', // коррекция ошибок (get)
	NORMALIZE: 'normalize', // обратная нормализация (get)
};

export const ENDPOINTS_SEE = {
	MANUAL_CHECK: 'entities', // ручная проверка модели (get)
	TRAIN: 'train', // обучение модели (post)
	STOP_TRAIN: 'stop', // остановка обучения модели (post)
};

export const ENDPOINTS_SPR = {
	RECOGNITION: 'stt', // распознавание речи (post)
	ASYNC_RESULT: 'result', // асинхронное распознавание речи (get)
	QUEUE: 'queue', // очередь асинхронного распознавания/удаление задачи (get / delete)
	AUDIO: 'audio', // экспорт распознанного аудио-файла (get)
	WAVEFORM: 'waveform', // пики для построения аудио-дорожки (get)
};

export const ENDPOINTS_TRANSCRIPTION = {
	LIST: 'list', // список сохраненных распознаваний (get)
	GET_DATA: 'get', // получить данные сохраненного распознавания (get)
	GET_AUDIO: 'audio', // получить аудио файл (get)
	WAVEFORM: 'waveform', // пики для построения аудио-дорожки (get)
	CREATE: 'create', // создать запись с сохраненным распознаванием (post)
	SAVE: 'save', // сохранить изменения сохраненного распознавания (post)
	DELETE: 'delete', // удалить запись сохраненного распознавания (delete)
};

export const ENDPOINTS_SBS = {
	SPEAKERS: 'speakers/list', // список спикеров (get)
	SPEAKER_ADD: 'speakers/add', // добавить спикера (post)
	SPEAKER_EDIT: 'speakers/modify', // изменить спикера (post)
	SPEAKER_DELETE: 'speakers/delete', // удалить спикера (delete)
	ANALYZE: 'analyze', // анализ речи (post)
	SEARCH: 'search', // поиск спикера по аудиозаписи (post)
	VERIFY: 'verify', // соответствие спикера и аудиозаписи (post)
};

export const ENDPOINTS_TTS = {
	SYNTHESIZE: 'synthesize', // синтез речи (get)
	DICTIONARY_EXPORT: 'dictionary/export', // экспорт словаря ударений .csv (get)
	DICTIONARY_IMPORT: 'dictionary/import', // импорт словаря ударений (post)
	DICTIONARY_GET: 'dictionary/get', // получить словарь ударений (get)
	DICTIONARY_PUT: 'dictionary/put', // изменение словаря ударений (post)
	NORMALIZE: 'normalize', // нормализация (post)
};

export const ENDPOINTS_QAS = {
	SEARCH: 'search', // фрагмент документа, откуда был дан ответ на заданный вопрос (get)
	ASK: 'ask', // ответ на заданный вопрос (get)

	DOC_LIST: 'doc/list', // список файлов (get)
	DOC_GET: 'doc/get', // получить документ .txt (get)
	DOC_ADD: 'doc/add', // добавить документ (post)
	DOC_EDIT: 'doc/modify', // изменение документа (post)
	DOC_DELETE: 'doc/delete', // удалить документ (delete)

	QUESTION_LIST: 'question/list', // список вопросов (get)
	QUESTION_GET: 'question/get', // получить вопрос (get)
	QUESTION_ADD: 'question/add', // добавить вопрос (post)
	QUESTION_EDIT: 'question/modify', // редактировать ответы на вопрос (post)
	QUESTION_DELETE: 'question/delete', // удалить вопрос (delete)
	QUESTION_LIST_DELETE: 'question/delete/list', // удалить список вопросов (post)
	QUESTION_APPEND: 'question/append', // добавить алиасы/кандидаты к другому вопросу (post)
	QUESTION_APPROVE: 'question/approve', // перемещение кандидатов в формулировки другого вопроса (post)

	CATEGORY_LIST: 'category/list', // список категорий (get)
	CATEGORY_ADD: 'category/add', // добавить категорию (post)
	CATEGORY_EDIT: 'category/modify', // изменить категорию (post)
	CATEGORY_DELETE: 'category/delete', // удалить категорию (delete)

	CHANNEL_LIST: 'channel/list', // список каналов (get)
	CHANNEL_ADD: 'channel/add', // добавить канал (post)
	CHANNEL_EDIT: 'channel/modify', // изменить канал (post)
	CHANNEL_DELETE: 'channel/delete', // удалить канал (delete)

	ANSWER_MAKER_START: 'cachemaker/start', // старт генерации ответов (get)
	ANSWER_MAKER_STOP: 'cachemaker/stop', // остановка генерации ответов (get)
	ANSWER_MAKER_STATUS: 'cachemaker/status', // статус генерации ответов (get)

	QUESTION_MAKER_START: 'questionmaker/start', // старт генерации вопросов (get)
	QUESTION_MAKER_STOP: 'questionmaker/stop', // остановка генерации вопросов (get)
	QUESTION_MAKER_STATUS: 'questionmaker/status', // статус генерации вопросов (get)

	PROCESSING_QUESTIONS_START: 'questionlist/start', // старт массовой обработки вопросов (post)
	PROCESSING_QUESTIONS_STOP: 'questionlist/stop', // остановка массовой обработки вопросов (get)
	PROCESSING_QUESTIONS_STATUS: 'questionlist/status', // статус массовой обработки вопросов (get)
	PROCESSING_QUESTIONS_LOG: 'questionlist/answers', // лог массовой обработки вопросов (get)
};

export const ENDPOINTS_SES = {
	ROBOT_LIST: 'robot/list', // список роботов (get)
	ROBOT_GET: 'robot/get', // данные робота (get)
	ROBOT_ADD: 'robot/add', // добавить робота (post)
	ROBOT_EDIT: 'robot/modify', // изменить робота (post)
	ROBOT_DELETE: 'robot/delete', // удалить робота (delete)
	ROBOT_COMMIT_CONFIG: 'robot/commit', // применить настройки робота (get)
	ROBOT_APPLY: 'robot/apply', // применить черновик робота в прод (get)
	ROBOT_RESTORE: 'robot/restore', // восстановить робота из бэкапа (get)
	ROBOT_CLEAR_DRAFT: 'robot/cleardraft', // обнулить черновик роботом прода (get)

	DATA_LIST: 'robot/data/list', // список элементов данных (get)
	DATA_GET: 'robot/data/get', // элемент данных (get)
	DATA_ADD: 'robot/data/add', // добавить данные (post)
	DATA_EDIT: 'robot/data/modify', // изменить данные (post)
	DATA_DELETE: 'robot/data/delete', // удалить данные (delete)

	ENDPOINT_LIST: 'robot/endpoint/list', // список конечных точек (get)
	ENDPOINT_GET: 'robot/endpoint/get', // получить конечную точку (get)
	ENDPOINT_ADD: 'robot/endpoint/add', // добавить конечную точку (post)
	ENDPOINT_EDIT: 'robot/endpoint/modify', // изменить конечную точку (post)
	ENDPOINT_DELETE: 'robot/endpoint/delete', // удалить конечную точку (delete)

	SCRIPT_LIST: 'robot/script/list', // список скриптов (get)
	SCRIPT_GET: 'robot/script/get', // получить скрипт (get)
	SCRIPT_ADD: 'robot/script/add', // добавить скрипт (post)
	SCRIPT_EDIT: 'robot/script/modify', // изменить скрипт (post)
	SCRIPT_DELETE: 'robot/script/delete', // удалить скрипт (delete)

	ASK: 'ask', // ответ робота в отладчике (post)
	SESSION: 'session', // данные сессии отладчика (get)
};
