import { FormEvent, useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearQuestionManipulation, deleteAnswer, editQuestionForMoving, getQuestionForMoving, getQuestionsListForMoving, moveAnswer, selectCategoriesList, selectChannelList, selectEditStatusForMoving, selectQuestion, selectQuestionsForMoving, selectQuestionsListForMoving } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { IQuestionItem } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormMoveAnswerProps } from './FormMoveAnswer.props';

const FormMoveAnswer = ({ showModal, setShowModal, idx, channelId, setChangeFlg }: IFormMoveAnswerProps): JSX.Element => {
	const [placeToMove, setPlaceToMove] = useState<'channel' | 'question'>('channel'); // таб для выбора, куда будет перемещаться ответ
	const [selectChannelId, setSelectChannelId] = useState<string>(''); // id канала
	const [categoryIdSelect, setCategoryIdSelect] = useState<string>(''); // id категории
	const [questionItem, setQuestionItem] = useState<IQuestionItem | null>(null); // конечный вопрос для перемещения

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsListForMoving); // store - список вопросов
	const question = useAppSelector(selectQuestion); // store - вопросы/ответы
	const questionForMoving = useAppSelector(selectQuestionsForMoving); // store - вопросы/ответы для перемещения
	const editStatus = useAppSelector(selectEditStatusForMoving); // store - статус сохранения ответов для перемещения
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getQuestionsListForMoving()); // получаем список всех вопросов
	}, []);

	// следим за фильтрованной категорией
	useEffect(() => {
		// если выбранный вопрос не соответствует выбранной категории или пустой категории - сбрасываем выбранный вопрос
		questionItem?.category !== categoryIdSelect && categoryIdSelect !== '' && setQuestionItem(null);
	}, [categoryIdSelect]);

	// следим за выбранным вопросом
	useEffect(() => {
		questionItem && dispatch(getQuestionForMoving(questionItem.id)); // получаем данные вопроса
	}, [questionItem]);

	// следим за статусом сохранения ответов в другом вопросе
	useEffect(() => {
		// если сохранение прошло успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message !== '') {
			dispatch(clearQuestionManipulation()); // очищаем данные манипуляции с вопросом
			dispatch(deleteAnswer(idx)); // удаляем ответ
			setShowModal(false); // закрываем форму
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	}, [editStatus]);

	// обработчик перемещения ответа
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (placeToMove === 'channel') {
			dispatch(moveAnswer({ idx, channelId: selectChannelId })); // перемещение ответа
			setChangeFlg(true); // ставим флаг о несохраненных данных
			setShowModal(false); // закрываем форму
		} else {
			questionItem && dispatch(editQuestionForMoving({
				questionId: questionItem.id,
				answers: [...questionForMoving.answers, question.answers[idx]],
			})); // добавлем ответ другому вопросу
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет получение вопроса или сохранение в другой вопрос - запрещаем покидать форму
		if (questionForMoving.status === RequestStatus.LOADING || editStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formMoveAnswer_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>

				<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
					<Button variant={placeToMove === 'channel' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setPlaceToMove('channel')}>
						{translate('formMoveAnswer_tabChannel')}
					</Button>
					<Button variant={placeToMove === 'question' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setPlaceToMove('question')}>
						{translate('formMoveAnswer_tabQuestion')}
					</Button>
				</ButtonGroup>

				{/* канал */}
				{placeToMove === 'channel' &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('formMoveAnswer_selectChannel')}</InputLabel>
						<Select
							required
							id="channel"
							labelId="channel-label"
							label={translate('formMoveAnswer_selectChannel')}
							value={selectChannelId}
							onChange={(e) => setSelectChannelId(e.target.value)}
							style={{ fontSize: 13, height: 33 }}
						>
							{channelList.data.filter(channelItem => channelItem.id !== channelId).map((channel) => (
								<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13 }}>{channel.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}

				{placeToMove === 'question' &&
					<>
						{/* категория */}
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('formMoveAnswer_selectCategory')}</InputLabel>
							<Select
								label={translate('formMoveAnswer_selectCategory')}
								disabled={questionForMoving.status === RequestStatus.LOADING || editStatus.status === RequestStatus.LOADING}
								value={categoryIdSelect}
								onChange={(e) => setCategoryIdSelect(e.target.value)}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								<MenuItem value='' sx={{ fontSize: 13 }}>{translate('formMoveAnswer_selectItemNoChecked')}</MenuItem>
								{categoriesList.data.map((category) => (
									<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
								))}
							</Select>
						</FormControl>
						{/* вопрос */}
						<FormControl fullWidth margin='dense'>
							<Autocomplete
								options={questionsList.data.filter(questionItem => questionItem.id !== question.questionId && (questionItem.category === categoryIdSelect || categoryIdSelect === ''))}
								value={questionItem}
								onChange={(_, value) => setQuestionItem(value)}
								noOptionsText={<div style={{ fontSize: '13px' }}>{translate('notFound')}</div>}
								disabled={questionForMoving.status === RequestStatus.LOADING || editStatus.status === RequestStatus.LOADING}
								renderInput={(params) =>
									<TextField
										{...params}
										required
										label={translate('formMoveAnswer_inputQuestion')}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										InputProps={{
											...params.InputProps, // важно прокинуть параметры
											endAdornment: (
												<div style={{ marginTop: '-7px' }}>
													{questionsList.status === RequestStatus.LOADING &&
														<ProgressCircle isBtnDisabled />
													}
													{params.InputProps.endAdornment} {/* важно дописать параметры */}
												</div>
											),
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option.question}
								renderOption={(props, option) => {
									return (
										<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }} key={option.id}>
											{option.question}
										</span>
									);
								}}
							/>
						</FormControl>
					</>
				}

				{((questionForMoving.status === RequestStatus.FAILED || questionForMoving.error === ResponseStatus.FAILED) && placeToMove === 'question') &&
					<Typography variant="body2" color={colorRed}>
						{translate(questionForMoving.message || 'formMoveAnswer_failedQuestionTitle')}
					</Typography>
				}
				{((editStatus.status === RequestStatus.FAILED || editStatus.error === ResponseStatus.FAILED) && placeToMove === 'question') &&
					<Typography variant="body2" color={colorRed}>
						{translate(editStatus.message || 'formMoveAnswer_failedMoveTitle')}
					</Typography>
				}

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={(questionForMoving.status === RequestStatus.LOADING || editStatus.status === RequestStatus.LOADING || questionForMoving.status === RequestStatus.FAILED) && placeToMove === 'question'}
					>
						{translate('formMoveAnswer_moveBtn')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormMoveAnswer;
