import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { clearImportMarks, selectImportMarks } from '../../../../store/marksSlice';
import { backgroundColor } from '../../../../constants/colors';
import FormImportMarks from '../../../Forms/FormImportMarks/FormImportMarks';
import Notification from '../../../Notification/Notification';
import { IImportMarksProps } from './ImportMarks.props';

const ImportMarks = ({ styleNotActive, isAvailable }: IImportMarksProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы импорта словаря меток
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && setShowModal(true)}
				title={translate('controlsBtn_importMarks')}>
				<FontAwesomeIcon icon={faCloudArrowUp} size="2xl" color={backgroundColor} />
			</div>

			{showModal &&
				<FormImportMarks
					showModal={showModal}
					setShowModal={setShowModal}
					setShowNotification={setShowNotification}
				/>
			}

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectImportMarks}
						clearDataResponse={clearImportMarks}
						titleFailed='noticeImportMarks_failed'
						titleSuccess='noticeImportMarks_success'
					/>
				}
			</section>
		</>
	);
};

export default ImportMarks;
