import { FormEvent, useEffect, useState } from 'react';
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { appendAliasList, clearQuestionManipulation, deleteQuestionsList, getQuestionsListForMoving, selectAppendAliasList, selectCategoriesList, selectDeletingQuestion, selectQuestionsListForMoving } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { IQuestionItem } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormMovingQuestionsProps } from './FormMovingQuestions.props';

const FormMovingQuestions = ({ showModal, setShowModal, selectedListQuestionIds, setShowNotification }: IFormMovingQuestionsProps): JSX.Element => {
	const [categoryIdSelect, setCategoryIdSelect] = useState<string>(''); // id категории
	const [questionItem, setQuestionItem] = useState<IQuestionItem | null>(null); // конечный вопрос для перемещения

	const [haveAnswersCount, setHaveAnswersCount] = useState<number>(0); // кол-во вопросов с проверенными ответами
	const [haveCandidatesCount, setHaveCandidatesCount] = useState<number>(0); // кол-во вопросов с кандидатами
	const [allQuestionsForMoving, setAllQuestionsForMoving] = useState<string[]>([]); // вопросы/алиасы для перемещения
	const [questionIsListShouldBeIgnored, setQuestionIsListShouldBeIgnored] = useState<string[]>([]); // список id вопросов, которые будут игнорированы при перемещении

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsListForMoving); // store - список вопросов
	const appendAliasListStatus = useAppSelector(selectAppendAliasList); // store - статус добавления алиасов
	const deletionQuestionsStatus = useAppSelector(selectDeletingQuestion); // store - статус удаления вопроса
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getQuestionsListForMoving()); // получаем список всех вопросов
	}, []);

	// следим за списком вопросов
	useEffect(() => {
		if (questionsList.data.length > 0) {
			// подсчет вопросов, содержащих проверенные ответы/кандидаты, и список вопросов
			let haveAnswers = 0; // ответы
			let haveCandidates = 0; // кандидаты
			const fullQuestionList: string[] = []; // вопросы/алиасы для перемещения
			const ignoredIdList: string[] = []; // id вопросов, которые не будут перемещаться
			questionsList.data.forEach(question => {
				if (selectedListQuestionIds.includes(question.id)) {
					if (question.checked > 0) {
						haveAnswers++;
						!ignoredIdList.includes(question.id) && ignoredIdList.push(question.id);
					}
					if (question.candidates.length > 0) {
						haveCandidates++;
						!ignoredIdList.includes(question.id) && ignoredIdList.push(question.id);
					}
					if (question.checked === 0 && question.candidates.length === 0) fullQuestionList.push(question.question, ...question.aliases);
				}
			});
			setHaveAnswersCount(haveAnswers);
			setHaveCandidatesCount(haveCandidates);
			setAllQuestionsForMoving(Array.from(new Set(fullQuestionList)));
			setQuestionIsListShouldBeIgnored(ignoredIdList);
		}
	}, [questionsList.data]);

	// следим за фильтрованной категорией
	useEffect(() => {
		// если выбранный вопрос не соответствует выбранной категории или пустой категории - сбрасываем выбранный вопрос
		questionItem?.category !== categoryIdSelect && categoryIdSelect !== '' && setQuestionItem(null);
	}, [categoryIdSelect]);

	// следим за статусом добавления алиасов, удалением вопросов
	useEffect(() => {
		// если добавилось успешно
		if (appendAliasListStatus.error === ResponseStatus.SUCCESS && appendAliasListStatus.status === RequestStatus.IDLE && appendAliasListStatus.message !== '') {
			dispatch(clearQuestionManipulation()); // очищаем статус добавления
			dispatch(deleteQuestionsList({
				questionsIdList: selectedListQuestionIds.filter(id => !questionIsListShouldBeIgnored.includes(id)),
				type: 'questions'
			})); // удаление вопросов
		}
		// если были изменения в статусе удаления вопросов
		if (deletionQuestionsStatus.error !== ResponseStatus.SUCCESS || deletionQuestionsStatus.status === RequestStatus.FAILED || deletionQuestionsStatus.message !== '') {
			setShowNotification(true); // включаем уведомление
			handleClose(); // закрываем форму
		}
	}, [appendAliasListStatus, deletionQuestionsStatus]);

	// обработчик начала перемещения - добавления алиасов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		questionItem && dispatch(appendAliasList({
			questionId: questionItem.id,
			aliasList: allQuestionsForMoving,
		})); // добавление вопросов и алиасов к другому вопросу
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление алиасов или удаление вопросов - запрещаем покидать форму
		if (appendAliasListStatus.status === RequestStatus.LOADING || deletionQuestionsStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formMoveQuestions_header' close={handleClose}>
			<form onSubmit={submitHandler}>
				{(haveAnswersCount > 0 || haveCandidatesCount > 0) &&
					<Typography variant="body2" color={colorRed}>
						{translate('formMoveQuestions_alertTitle')}
					</Typography>
				}
				{haveAnswersCount > 0 &&
					<Typography variant="body2" color={colorRed}>
						- {translate('formMoveQuestions_alertChekedAnswersTitle')}: {haveAnswersCount}
					</Typography>
				}
				{haveCandidatesCount > 0 &&
					<Typography variant="body2" color={colorRed}>
						- {translate('formMoveQuestions_alertCandidatesTitle')}: {haveCandidatesCount}
					</Typography>
				}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('formMoveQuestions_selectCategory')}</InputLabel>
					<Select
						label={translate('formMoveQuestions_selectCategory')}
						disabled={appendAliasListStatus.status === RequestStatus.LOADING || deletionQuestionsStatus.status === RequestStatus.LOADING}
						value={categoryIdSelect}
						onChange={(e) => setCategoryIdSelect(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='' sx={{ fontSize: 13 }}>{translate('formMoveQuestions_selectItemNotSelected')}</MenuItem>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Autocomplete
						options={questionsList.data.filter(questionItem => !selectedListQuestionIds.includes(questionItem.id) && (questionItem.category === categoryIdSelect || categoryIdSelect === ''))}
						value={questionItem}
						onChange={(_, value) => setQuestionItem(value)}
						noOptionsText={<div style={{ fontSize: '13px' }}>{translate('notFound')}</div>}
						disabled={appendAliasListStatus.status === RequestStatus.LOADING || deletionQuestionsStatus.status === RequestStatus.LOADING}
						renderInput={(params) =>
							<TextField
								{...params}
								required
								label={translate('formMoveQuestions_inputQuestion')}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								InputProps={{
									...params.InputProps, // важно прокинуть параметры
									endAdornment: (
										<div style={{ marginTop: '-7px' }}>
											{questionsList.status === RequestStatus.LOADING &&
												<ProgressCircle isBtnDisabled />
											}
											{params.InputProps.endAdornment} {/* важно дописать параметры */}
										</div>
									),
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						}
						sx={{
							".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
							".MuiInputBase-input": { marginTop: -1 },
						}}
						getOptionLabel={option => option.question}
						renderOption={(props, option) => {
							return (
								<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }} key={option.id}>
									{option.question}
								</span>
							);
						}}
					/>
				</FormControl>
				{(appendAliasListStatus.status === RequestStatus.FAILED || appendAliasListStatus.error === ResponseStatus.FAILED) &&
					<Typography variant="body2" color={colorRed}>
						{translate(appendAliasListStatus.message || 'formMoveQuestions_failedMovingTitle')}
					</Typography>
				}
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={appendAliasListStatus.status === RequestStatus.LOADING || deletionQuestionsStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{(appendAliasListStatus.status === RequestStatus.LOADING || deletionQuestionsStatus.status === RequestStatus.LOADING) ?
							<>
								{translate('formMoveQuestions_btnMoving')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formMoveQuestions_btnMove')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormMovingQuestions;
