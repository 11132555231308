import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Autocomplete, createFilterOptions, Popper } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch } from '../../store/hooks';
import { changeTimestamp as changeTimestampRecognition } from '../../store/sprSlice';
import { changeSpeaker, changeTimestamp as changeTimestampTranscript, createWord, deleteWord, editWord, joinReplicas, splitReplica } from '../../store/transcriptionSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { timeConversion } from '../../helpers/timeConversion';
import { colorizeWord } from '../../helpers/colorizeWord';
import { TRANSCRIPTION } from '../../constants/accessRights';
import { COLORING_CONFIDENCE } from '../../constants/cookieNames';
import { colorSecondary } from '../../constants/colors';
import { IReplicaProps } from './Replica.props';
import styles from './Replica.module.scss';

const Replica = ({ fragment, fragmentIdx, fragmentsArray, speakerList, timestamp, from, setChangeFlg, showContextMenu, setShowContextMenu }: IReplicaProps): JSX.Element => {
	const [speakerName, setSpeakerName] = useState<string>(''); // имя спикера
	const divRef = useRef<HTMLDivElement>(null); // ссылка на редактируемый div-контейнер
	const [positionContextMenu, setPositionContextMenu] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция кастомного контекстного меню
	const [activeWordIdx, setActiveWordIdx] = useState<number>(-1); // индекс активного слова
	const [bottomPositionInfo, setBottomPositionInfo] = useState<boolean>(false); // флаг для отображения инфо фрагмента снизу спикера

	const dispatch = useAppDispatch();
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const [cookies] = useCookies([COLORING_CONFIDENCE]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком имен спикеров и пишем активного в выпадающий список
	useEffect(() => {
		setSpeakerName(fragment.speaker === null ? '?' : speakerList[fragment.speaker]);
	}, [speakerList]);

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// функция поиска позиции курсора в слове
	const getCursorPosition = (parent: Node): number => {
		const selection = document.getSelection();
		const range = new Range();
		range.setStart(parent, 0);
		if (selection) {
			selection.anchorNode && range.setEnd(selection.anchorNode, selection.anchorOffset);
		}
		return range.toString().length;
	};

	// обработчик редактирования слова
	const editWordHandler = (fragmentIdx: number, wordIdx: number, newValue: string): void => {
		dispatch(editWord({
			fragmentIdx,
			wordIdx,
			newValue,
		})); // обновляем слово в store
		setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик фокуса слова
	const focusHandler = (wordStart: number, wordIdx: number): void => {
		setActiveWordIdx(wordIdx); // устанавливаем индекс активного слова
		// устанавливаем временную метку
		from === 'transcript' ?
			dispatch(changeTimestampTranscript(wordStart + 1))
			:
			dispatch(changeTimestampRecognition(wordStart + 1));
	};

	// обработчик нажатия клавиши
	const keyDownHandler = (e: React.KeyboardEvent<HTMLSpanElement>, wordIdx: number): void => {
		// разделение реплики
		if (e.ctrlKey && e.code === 'Enter') {
			from === 'transcript' && isAccess(TRANSCRIPTION.SAVE) && splitReplicaHandler(wordIdx);
		}
		// перенос строки
		else if (e.code === 'Enter') {
			e.preventDefault();
			const range = new Range();
			range.selectNode(e.currentTarget); // выбор текущего слова
			// если начало слова (не первое) и у предыдущего слова нет переноса 
			if (wordIdx !== 0 && getCursorPosition(e.currentTarget) === 0 && e.currentTarget.previousSibling?.nodeName !== 'DIV') {
				editWordHandler(fragmentIdx, wordIdx - 1, e.currentTarget.previousSibling?.textContent + '\n');
			}
			// если курсор не вначале слова и у текущего слова нет переноса строки и не последнее слово во фрагменте
			else if (getCursorPosition(e.currentTarget) !== 0 && e.currentTarget.nextSibling?.nodeName !== 'DIV' && fragment.words.length - 1 !== wordIdx) {
				e.currentTarget.nextSibling && document.getSelection()?.setPosition(e.currentTarget.nextSibling.firstChild, 0); // курсор на начало следующего слова
				editWordHandler(fragmentIdx, wordIdx, range.toString() + '\n');
			}
		}
		// создание слова
		else if (e.code === 'Space') {
			e.preventDefault();
			// только для стенографии и есть права на редактирование
			if (from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) {
				const range = new Range();
				range.selectNode(e.currentTarget); // выбор текущего слова
				// для случая создания нового слова с переносом (перенос теряется)
				if (getCursorPosition(e.currentTarget) === range.toString().length && e.currentTarget.nextSibling?.nodeName === 'DIV' && !e.currentTarget.innerText.includes('\n')) {
					// editWordHandler(fragmentIdx, wordIdx, range.toString().replace('\n', '')); // убираем перенос строки с предыдущего слова
					// dispatch(createWord({ fragment, fragmentIdx, wordIdx, word: '' })); // создание слова с переносом строки
					// (e.currentTarget.nextSibling as HTMLSpanElement)?.focus(); // фокус на начало следующего слова
				}
				// если конец не последнего слова во фрагменте
				else if (getCursorPosition(e.currentTarget) === range.toString().length && fragment.words.length - 1 !== wordIdx) {
					dispatch(createWord({ fragment, fragmentIdx, wordIdx })); // создание слова
					(e.currentTarget.nextSibling as HTMLSpanElement)?.focus(); // фокус на начало следующего слова
				}
				// если конец слова !с переносом строки!
				else if (getCursorPosition(e.currentTarget) === range.toString().length - 1 && range.toString().includes('\n')) {
					editWordHandler(fragmentIdx, wordIdx, range.toString().replace('\n', '')); // убираем перенос строки с предыдущего слова
					dispatch(createWord({ fragment, fragmentIdx, wordIdx, word: '\n' })); // создание слова с переносом строки
					(e.currentTarget.nextSibling?.nextSibling as HTMLSpanElement)?.focus(); // фокус на начало следующего слова
				}
				// если конец слова во фрагменте (последнего)
				else if (getCursorPosition(e.currentTarget) === range.toString().length) {
					dispatch(createWord({ fragment, fragmentIdx, wordIdx })); // создание слова
					setTimeout(() => {
						(divRef.current?.children[wordIdx + 1] as HTMLSpanElement)?.focus(); // фокус на начало следующего слова с задержкой
					});
				}
			}
		} else if (e.code === 'Backspace') {
			// только для стенографии и есть права на редактирование
			if (from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) {
				// если начало первого слова в абзаце - убираем перенос строки
				if (getCursorPosition(e.currentTarget) === 0 && e.currentTarget.previousSibling?.nodeName === 'DIV') {
					if (e.currentTarget.previousSibling.previousSibling?.textContent) {
						e.preventDefault();
						editWordHandler(fragmentIdx, wordIdx - 1, e.currentTarget.previousSibling.previousSibling.textContent.replace('\n', ''));
					}
				}
				// если начало не первого слова во фрагменте
				else if (getCursorPosition(e.currentTarget) === 0 && wordIdx !== 0) {
					e.preventDefault();
					const range = new Range();
					if (e.currentTarget.previousSibling) {
						range.selectNode(e.currentTarget.previousSibling); // выбор предыдущего слова
						document.getSelection()?.setPosition(e.currentTarget.previousSibling?.firstChild, range.toString().length); // ставим курсор в конец предыдущего слова
					}
				}
			}
		} else if (e.code === 'Delete') {
			// только для стенографии и есть права на редактирование
			if (from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) {
				const range = new Range();
				range.selectNode(e.currentTarget); // выбор текущего слова
				// если конец слова в абзаце
				if (getCursorPosition(e.currentTarget) === range.toString().length - 1 && e.currentTarget.nextSibling?.nodeName === 'DIV') {
					e.preventDefault();
					e.currentTarget.nextSibling.nextSibling && document.getSelection()?.setPosition(e.currentTarget.nextSibling.nextSibling.firstChild, 0); // курсор на следующее слово
					editWordHandler(fragmentIdx, wordIdx, range.toString().replace('\n', ''));
				}
				// если конец не последнего слова во фрагменте
				else if (getCursorPosition(e.currentTarget) === range.toString().length && fragment.words.length - 1 !== wordIdx) {
					e.preventDefault();
					(e.currentTarget.nextSibling as HTMLSpanElement)?.focus(); // курсор на следующее слово
				}
			}
		} else if (e.code === 'Escape') {
			setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
		}
		// навигация
		else if (e.code === 'ArrowDown') {
			e.preventDefault();
			// фокус на первое слово следующего фрагмента, если фрагмент не последний
			((fragmentsArray.length - 1) !== fragmentIdx) && (e.currentTarget.parentNode?.parentNode?.nextSibling?.lastChild?.firstChild as HTMLSpanElement)?.focus();
		} else if (e.code === 'ArrowUp') {
			e.preventDefault();
			// фокус на первое слово предыдущего фрагмента, если фрагмент не первый
			fragmentIdx !== 0 && (e.currentTarget.parentNode?.parentNode?.previousSibling?.lastChild?.firstChild as HTMLSpanElement)?.focus();
		} else if (e.code === 'ArrowLeft') {
			if (from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) {
				// если начало первого слова не первого фрагмента - фокус на конец последнего слова предыдущего фрагмента
				if (getCursorPosition(e.currentTarget) === 0 && wordIdx === 0 && fragmentIdx !== 0) {
					if (e.currentTarget.parentNode?.parentNode?.previousSibling?.lastChild?.lastChild) {
						e.preventDefault();
						const range = new Range();
						range.selectNode(e.currentTarget.parentNode.parentNode.previousSibling.lastChild.lastChild); // выбор последнего слова предыдущего фрагмента
						document.getSelection()?.setPosition(e.currentTarget.parentNode.parentNode.previousSibling.lastChild.lastChild.firstChild, range.toString().length); // ставим курсор в конец слова
					}
				}
				// если просто начало слова - фокус на конец предыдущего слова
				else if (getCursorPosition(e.currentTarget) === 0) {
					e.preventDefault();
					const range = new Range();
					// если предыдущий тег - span (обычное слово)
					if (e.currentTarget.previousSibling && e.currentTarget.previousSibling.nodeName === 'SPAN') {
						range.selectNode(e.currentTarget.previousSibling); // выбор предыдущего слова
						document.getSelection()?.setPosition(e.currentTarget.previousSibling.firstChild, range.toString().length); // ставим курсор в конец слова
					}
					// если предыдущий тег - div (разделитель новой строки)
					if (e.currentTarget.previousSibling && e.currentTarget.previousSibling.nodeName === 'DIV') {
						if (e.currentTarget.previousSibling.previousSibling) {
							range.selectNode(e.currentTarget.previousSibling.previousSibling); // выбор предыдущего слова
							document.getSelection()?.setPosition(e.currentTarget.previousSibling.previousSibling.firstChild, range.toString().length - 1); // ставим курсор в конец слова
						}
					}
				}
			} else {
				// фокус на предыдущее слово, если оно не первое
				if (wordIdx !== 0) {
					// если предыдущий тег - span (обычное слово)
					if (e.currentTarget.previousSibling?.nodeName === 'SPAN') (e.currentTarget.previousSibling as HTMLSpanElement)?.focus();
					// если предыдущий тег - div (разделитель новой строки)
					if (e.currentTarget.previousSibling?.nodeName === 'DIV') (e.currentTarget.previousSibling.previousSibling as HTMLSpanElement)?.focus();
				}
				// фокус на последнее слово предыдущего фрагмента, если в фокусе было первое слово в реплике, и реплика не первая
				fragmentIdx !== 0 && wordIdx === 0 && (e.currentTarget.parentNode?.parentNode?.previousSibling?.lastChild?.lastChild as HTMLSpanElement)?.focus();
			}
		} else if (e.code === 'ArrowRight') {
			if (from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) {
				const range = new Range();
				range.selectNode(e.currentTarget); // выбор текущего слова
				// если конец не последнего слова !с переносом строки! во фрагменте - фокус начало следующего слова
				if (range.toString().includes('\n') && getCursorPosition(e.currentTarget) === range.toString().length - 1 && fragment.words.length - 1 !== wordIdx) {
					e.preventDefault();
					(e.currentTarget.nextSibling?.nextSibling as HTMLSpanElement)?.focus();
				}
				// если конец не последнего слова во фрагменте - фокус на начало следующего слова
				if (getCursorPosition(e.currentTarget) === range.toString().length && fragment.words.length - 1 !== wordIdx) {
					e.preventDefault();
					(e.currentTarget.nextSibling as HTMLSpanElement)?.focus();
				}
				// если конец последнего слова фо фрагменте - фокус на начало первого слова следующего фрагмента
				else if (getCursorPosition(e.currentTarget) === range.toString().length && fragment.words.length - 1 === wordIdx) {
					e.preventDefault();
					(e.currentTarget.parentNode?.parentNode?.nextSibling?.lastChild?.firstChild as HTMLSpanElement)?.focus();
				}
			} else {
				// фокус на следующее слово, если оно не последнее
				if (fragment.words.length - 1 !== wordIdx) {
					// если следующий тег - span (обычное слово)
					if (e.currentTarget.nextSibling?.nodeName === 'SPAN') (e.currentTarget.nextSibling as HTMLSpanElement)?.focus();
					// если следующий тег - div (разделитель новой строки)
					if (e.currentTarget.nextSibling?.nodeName === 'DIV') (e.currentTarget.nextSibling?.nextSibling as HTMLSpanElement)?.focus();
				}
				// фокус на первое слово следующего фрагмента, если в фокусе было последнее слово в реплике, и реплика не последняя
				(fragmentsArray.length - 1 !== fragmentIdx) && (fragment.words.length - 1 === wordIdx) && (e.currentTarget.parentNode?.parentNode?.nextSibling?.lastChild?.firstChild as HTMLSpanElement)?.focus();
			}
		} else if (e.code === 'End') {
			e.preventDefault();
			// фокус на конец последнего слова во фрагменте
			if (e.currentTarget.parentNode?.lastChild) {
				const range = new Range();
				range.selectNode(e.currentTarget.parentNode.lastChild); // выбор последнего слова
				document.getSelection()?.setPosition(e.currentTarget.parentNode.lastChild.firstChild, range.toString().length); // курсор в конец слова
				(e.currentTarget.parentNode.lastChild as HTMLSpanElement)?.focus(); // фокус на последнее слово во фрагменте (для очереди)
			}
		} else if (e.code === 'Home') {
			e.preventDefault();
			(e.currentTarget.parentNode?.firstChild as HTMLSpanElement)?.focus(); // фокус на начало первого слова во фрагменте
		}
	};

	// обработчик вставки текста
	const pasteHandler = (e: React.ClipboardEvent<HTMLSpanElement>): void => {
		e.preventDefault();
		const pastedData = e.clipboardData.getData('text');
		document.execCommand('inserttext', false, pastedData); // убираем форматирование текста
		// navigator.clipboard.readText()
		// 	.then((clipText) => console.log(clipText))
		// 	.catch(err => console.log(err));
	};

	// обработчик увода фокуса от слова
	const blurHandler = (e: React.FocusEvent<HTMLSpanElement, Element>, oldWord: string, idx: number): void => {
		const valueWithoutSpaces = e.target.innerText.replaceAll(' ', '');
		// если в слове не осталось символов
		if (valueWithoutSpaces.length === 0) deleteWordHandler(idx); // удаляем слово
		// если слово изменилось
		else if (valueWithoutSpaces !== oldWord) {
			// для случая создания нового слова с переносом (перенос теряется)
			if (e.target.nextSibling?.nodeName === 'DIV' && !valueWithoutSpaces.includes('\n')) editWordHandler(fragmentIdx, idx, valueWithoutSpaces + '\n');
			else editWordHandler(fragmentIdx, idx, valueWithoutSpaces);
		}
	};

	// обработчик добавления слова
	const createWordHandler = (wordIdx: number): void => {
		// если слово с переносом строки
		if (fragment.words[wordIdx].word.includes('\n')) {
			editWordHandler(fragmentIdx, wordIdx, fragment.words[wordIdx].word.replace('\n', '')); // убираем перенос строки с предыдущего слова
			dispatch(createWord({ fragment, fragmentIdx, wordIdx, word: '\n' })); // создание слова с переносом строки
		} else {
			dispatch(createWord({ fragment, fragmentIdx, wordIdx })); // создание слова
		}
		setTimeout(() => {
			(divRef.current?.children[wordIdx + 1] as HTMLSpanElement)?.focus(); // фокус на начало следующего слова с задержкой
		});
		setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
		setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
	};

	// обработчик удаления слова из реплики
	const deleteWordHandler = (wordIdx: number): void => {
		dispatch(deleteWord({ fragmentIdx, wordIdx, fragment })); // удаляем слово
		setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
		setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
	};

	// обработчик разделения реплики
	const splitReplicaHandler = (wordIdx: number): void => {
		// разрешаем, если слово не первое/единственное в реплике
		if (wordIdx !== 0) {
			dispatch(splitReplica({
				speakerId: speakerList.indexOf(speakerName),
				fragmentIdx,
				wordIdx,
				fragment,
			})); // разделяем реплику на две
			setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
			setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
		}
	};

	// обработчик объединения реплик
	const joinReplicasHandler = (withReplica: 'prev' | 'next'): void => {
		// для предыдущей реплики, если не первая и предыдущий спикер такой же
		if (withReplica === 'prev' && fragmentIdx !== 0 && (fragment.speaker === fragmentsArray[fragmentIdx - 1].speaker)) {
			dispatch(joinReplicas({ fragmentIdx, withReplica }));
			setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
			setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
		}
		// для следующей реплики, если не последняя и следующий спикер такой же
		if (withReplica === 'next' && (fragmentsArray.length - 1 !== fragmentIdx) && (fragment.speaker === fragmentsArray[fragmentIdx + 1].speaker)) {
			dispatch(joinReplicas({ fragmentIdx, withReplica }));
			setShowContextMenu && setShowContextMenu({ isShow: false, idx: -1 }); // закрываем контекстное меню
			setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// кастомное контекстное меню
	const customContextMenuHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
		e.preventDefault();
		const newPosition = {
			x: e.pageX,
			y: e.pageY - 125,
		};
		setPositionContextMenu(newPosition); // устанавливаем новые координаты контекстного меню
		setShowContextMenu && setShowContextMenu({ isShow: true, idx: fragmentIdx }); // открываем контекстное меню
	};

	return (
		<li className={cn(styles.line, {
			[styles.lineActive]: timestamp >= fragment.start_ms && timestamp <= fragment.stop_ms,
			[styles.lineRightPosition]: fragment.channel > 0,
		})}
			onContextMenu={(e) => from === 'transcript' && isAccess(TRANSCRIPTION.SAVE) && fragment.words && customContextMenuHandler(e)}
		>
			<div className={cn(styles.speakerWrapper, {
				[styles.speakerWrapperRightPosition]: fragment.channel > 0,
			})} onMouseOver={e => setBottomPositionInfo(e.clientY < 235)}>
				<div className={cn(styles.speakerInfo, {
					[styles.speakerInfoBottomPosition]: bottomPositionInfo,
					[styles.speakerInfoRightPosition]: fragment.channel > 0,
				})}>
					<p>{translate('replica_fragmentStartTitle')}: {fragment.start}</p>
					<p>{translate('replica_fragmentDurationTitle')}: {fragment.duration}</p>
				</div>
				{from === 'transcript' && isAccess(TRANSCRIPTION.SAVE) ?
					<Autocomplete
						freeSolo
						openOnFocus
						autoHighlight
						options={speakerList}
						value={speakerName}
						onChange={(_, value) => {
							if (value === null) setSpeakerName('');
							else {
								setSpeakerName(value);
								dispatch(changeSpeaker({ newSpeakerName: value, newSpeakerId: speakerList.indexOf(value), fragment, fragmentIdx })); // изменяем спикера
								setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
							}
						}}
						filterOptions={(options, state) => {
							const filtered = filter(options, state);
							if (state.inputValue.length > 0 && options.findIndex(speakerName => speakerName === state.inputValue) === -1) filtered.push(state.inputValue);
							return filtered;
						}}
						sx={{
							'& input': {
								fieldSizing: 'content',
								border: 'none',
								background: 'transparent',
								color: colorSecondary,
								fontSize: '12px',
							},
							'& input:focus': {
								outline: `none`,
							},
						}}
						renderInput={(params) => (
							<div ref={params.InputProps.ref}>
								<input {...params.inputProps} type="text" />
							</div>
						)}
						renderOption={(props, option, _state, ownerState) => {
							const match = ownerState.options.filter(classItem => classItem === option);
							return (
								<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorSecondary }}>
									{match.length === 0 ?
										<>{translate('replica_addNewSpeakerTitle')} "{option}"</>
										:
										<>{option}</>
									}
								</li>
							);
						}}
						PopperComponent={(props) =>
							<Popper {...props} style={{ maxWidth: "fit-content" }}>
								{props.children}
							</Popper>
						}
					/>
					:
					<p>{fragment.speaker === null ? '?' : speakerList[fragment.speaker]}</p>
				}
			</div>
			<div ref={divRef} className={styles.lineReplica}>
				{fragment.words ?
					fragment.words.map((word, idx) =>
						<Fragment key={idx}>
							<span
								tabIndex={idx === 0 ? 0 : -1}
								className={cn(styles.lineWord, {
									[styles.lineWordActiveRed]: timestamp >= word.start && timestamp <= word.stop && typeof word.confidence === 'number' && word.confidence <= 0.7 && (!cookies.coloringConfidence || cookies.coloringConfidence === 'true'),
									[styles.lineWordActiveBlue]: timestamp >= word.start && timestamp <= word.stop && (!word.confidence || (typeof word.confidence === 'number' && word.confidence > 0.7) || cookies.coloringConfidence === 'false'),
								})}
								contentEditable={from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)} // редактируемая область
								suppressContentEditableWarning // отключить warning'и в консоли
								spellCheck='false' // отключить проверку правописания
								onFocus={() => focusHandler(word.start, idx)}
								onKeyDown={(e) => keyDownHandler(e, idx)}
								onPaste={(e) => pasteHandler(e)}
								onBlur={(e) => blurHandler(e, word.word, idx)}
								style={typeof word.confidence === 'number' && word.confidence <= 0.7 && (!cookies.coloringConfidence || cookies.coloringConfidence === 'true') ? { color: colorizeWord(word.confidence) } : undefined}
								title={`${translate('replica_wordStartTitle')}: ${timeConversion({ time: word.start, from: 'ms', to: 'withMs' })}`}
							>
								{word.word}
							</span>
							{word.word.includes('\n') && <div className={styles.lineWordDelimiter}></div>}
						</Fragment>
					)
					:
					fragment.text
				}
			</div>

			{(showContextMenu?.isShow === true && showContextMenu.idx === fragmentIdx) &&
				<div
					className={styles.contextMenu}
					style={{ top: positionContextMenu.y, left: positionContextMenu.x }}
					onClick={e => e.stopPropagation()}
				>
					<div className={cn(styles.contextMenuOption, {
						[styles.contextMenuOptionNotActive]: fragmentIdx === 0 || (fragment.speaker !== fragmentsArray[fragmentIdx - 1].speaker),
					})} onClick={() => joinReplicasHandler('prev')}>
						{translate('replica_joinPrevReplicaTitle')}
					</div>
					<div className={cn(styles.contextMenuOption, {
						[styles.contextMenuOptionNotActive]: (fragmentsArray.length - 1 === fragmentIdx) || (fragment.speaker !== fragmentsArray[fragmentIdx + 1].speaker),
					})} onClick={() => joinReplicasHandler('next')}>
						{translate('replica_joinNextReplicaTitle')}
					</div>
					<div className={cn(styles.contextMenuOption, {
						[styles.contextMenuOptionNotActive]: activeWordIdx === 0,
					})} onClick={() => splitReplicaHandler(activeWordIdx)}>
						{translate('replica_splitReplicaTitle')}
					</div>
					<div className={styles.contextMenuOption} onClick={() => deleteWordHandler(activeWordIdx)}>
						{translate('replica_deleteWordTitle')}
					</div>
					<div className={styles.contextMenuOption} onClick={() => createWordHandler(activeWordIdx)}>
						{translate('replica_createWordTitle')}
					</div>
				</div>
			}
		</li>
	);
};

export default memo(Replica);
