import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addCategoryDataElem, addCategoryDataEndpoint, selectDataList } from '../../../store/sesSlice';
import { addCategoryDataRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { DataElemActionEventType } from '../../../types/sesTypes';
import { IFormAddingCategoryDataProps } from './FormAddingCategoryData.props';
import styles from './FormAddingCategoryData.module.scss';

const FormAddingCategoryData = ({ actionEvent, itsAction, categoryId, categoryBlockIdx, channel, channelIdx, setChangeFlg }: IFormAddingCategoryDataProps): JSX.Element => {
	const [selectDataElem, setSelectDataElem] = useState<string>('formAddingCategoryData_chooseDataElem'); // элемент данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных

	const translate = useTranslate(); // hook для перевода текста

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setSelectDataElem('formAddingCategoryData_chooseDataElem');
	};

	// обработчик добавления данных категории
	const addCategoryDataHandler = (e: SelectChangeEvent<string>): void => {
		setSelectDataElem(e.target.value);
		itsAction === 'robot' && dispatch(addCategoryDataRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data: { data: e.target.value, value: '' },
		}));
		itsAction === 'dataElement' && dispatch(addCategoryDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data: { data: e.target.value, value: '' },
		}));
		itsAction === 'endpoint' && dispatch(addCategoryDataEndpoint({
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data: { data: e.target.value, value: '' },
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
		resetInputAndSelect(); // очищаем поля
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: selectDataElem === 'formAddingCategoryData_chooseDataElem'
		})}>
			{/* элемент данных */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingCategoryData_selectDataElem')}</InputLabel>
				<Select
					required
					label={translate('formAddingCategoryData_selectDataElem')}
					value={selectDataElem}
					onChange={addCategoryDataHandler}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					<MenuItem value='formAddingCategoryData_chooseDataElem' sx={{ fontSize: 13, color: colorPrimary }} disabled>{translate('formAddingCategoryData_chooseDataElem')}
					</MenuItem>
					{dataElemList.data.map(({ id, name }) =>
						<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingCategoryData;
