import { forwardRef, Ref, useEffect, useState } from 'react';
import { Autocomplete, Popper, TextField } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import { selectCategoriesList, selectQuestionsList } from '../../../../store/qasSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import { IFilterProps } from './Filter.props';
import styles from './Filter.module.scss';

const Filter = ({ table, column, rowSelection }: IFilterProps) => {
	const [inputFilter, setInputFilter] = useState<string>(''); // значение для фильтрации

	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const translate = useTranslate(); // hook для перевода текста

	// следим за значениями для фильтрации
	useEffect(() => {
		Object.keys(rowSelection).length > 0 && table.resetRowSelection(); // если выбраны строки - сбрасываем
		// фильтруем через пол-секунды бездействия после окончания ввода значения для фильтрации
		const handler = setTimeout(() => {
			column.setFilterValue(inputFilter);
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод значения для фильтрации
		};
	}, [inputFilter]);

	return (
		<>
			{(column.id === 'question' || column.id === 'category') ?
				<Autocomplete
					freeSolo
					autoHighlight
					openOnFocus
					options={column.id === 'question' ? questionsList.data.map(questionItem => questionItem.question) : categoriesList.data.map(categoryItem => categoryItem.name)}
					value={inputFilter}
					onChange={(_, value) => setInputFilter(value ? value : '')}
					renderInput={(params) =>
						<TextField
							{...params}
							label={translate('candidateTable_inputFilter')}
							value={inputFilter}
							onChange={(e) => setInputFilter(e.target.value)}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					}
					sx={{
						".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
						".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
						".MuiAutocomplete-popupIndicator": { display: 'none' },
						backgroundColor: 'transparent',
					}}
					getOptionLabel={option => option}
					renderOption={(props, option) =>
						<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
							{option}
						</li>
					}
					PopperComponent={(props) =>
						<Popper {...props} sx={{ boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
							{props.children}
						</Popper>
					}
					ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
						<ul {...props} ref={ref}>
							{props.children}
							<div className={styles.blockFillGradientTop} />
							<div className={styles.blockFillGradientBottom} />
						</ul>
					)}
				/>
				:
				<TextField
					label={translate('candidateTable_inputFilter')}
					value={inputFilter}
					onChange={e => setInputFilter(e.target.value)}
					variant="outlined"
					InputProps={{
						style: {
							height: 34,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: '100%' }}
				/>
			}
		</>
	);
};

export default Filter;
