import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addPhraseInActionDataElem, addPhraseInActionEndpoint, changeActionDataElem, changeActionEndpoint, changeActionTypeDataElem, changeActionTypeEndpoint, changeIntervalDataElem, changeIntervalEndpoint, changeScriptDataElem, changeScriptEndpoint, changeServiceDataElem, changeServiceEndpoint, deleteActionDataElem, deleteActionEndpoint, deleteIntervalDataElem, deleteIntervalEndpoint } from '../../store/sesSlice';
import { addPhraseInActionRobot, changeActionRobot, changeActionTypeRobot, changeIntervalRobot, changeScriptRobot, changeServiceRobot, deleteActionRobot, deleteIntervalRobot, selectActiveRobotVersion, selectRobot } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { selectCategoriesList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ACTION_LIST, ACTION_TYPE_LIST, SERVICE_TYPE_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../constants/colors';
import { Action, ActionType, DataElemActionEventType, IActionInternal, ServiceType } from '../../types/sesTypes';
import { RobotActionEventType } from '../../types/sesRobotTypes';
import { CachingType } from '../../types/qasTypes';
import Phrase from './Phrase/Phrase';
import CategoryData from './CategoryData/CategoryData';
import FormAddingCategoryData from '../Forms/FormAddingCategoryData/FormAddingCategoryData';
import { IActionProps } from './ActionEvent.props';
import styles from './ActionEvent.module.scss';

const ActionEvent = ({ action, idx, actionEvent, channel, setChangeFlg, itsAction }: IActionProps): JSX.Element => {
	const [selectAction, setSelectAction] = useState<Action>(action.action); // действие
	const [selectActionType, setSelectActionType] = useState<ActionType>(action.action === 'say' ? action.type : 'linear'); // тип действия
	const [script, setScript] = useState<string>(action.action === 'say' && action.type === 'external' ? action.script : ''); // скрипт
	const [service, setService] = useState<ServiceType>(action.action === 'say' && action.type === 'internal' ? action.service : 'qas'); // сервис
	const [selectCategoryId, setSelectCategoryId] = useState<string>((action.action === 'say' && action.type === 'internal' && Object.keys(action.categories || {})[0]) || ''); // id категории
	const [selectCaching, setSelectCaching] = useState<CachingType>(action.action === 'say' && action.type === 'internal' ? action.useCache : 'checked'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.useGPT === 'yes' ? true : false); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.creative === 'yes' ? true : false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.addAlias === 'yes' ? true : false); // добавление вопроса в похожие в кэше, если похожая формулировка найдена

	const [showCategoriesSettings, setShowCategoriesSettings] = useState<boolean>(false); // показ настроек категорий
	const [showAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false); // показ доп.настроек
	const [intervalName, setIntervalName] = useState<string>(action.conditions?.interval || ''); // имя интервала

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за действием
	useEffect(() => {
		// если касается действий с фразами
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			// есть права на редактирование и последняя фраза не пустая - добавляем новую
			((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && action.text[action.text.length - 1] !== '' && activeRobotVersion === 'draft' && addPhraseHandler();
		}
	}, [action]);

	// обработчик изменения действия
	const changeActionHandler = (e: SelectChangeEvent<Action>): void => {
		setSelectAction(e.target.value as Action);
		itsAction === 'robot' && dispatch(changeActionRobot({ actionEvent: actionEvent as RobotActionEventType, channel, idx, action: e.target.value as Action })); // изменение действия робота
		itsAction === 'dataElement' && dispatch(changeActionDataElem({ actionEvent: actionEvent as DataElemActionEventType, channel, idx, action: e.target.value as Action })); // изменение действия элемента данных
		itsAction === 'endpoint' && dispatch(changeActionEndpoint({ channel, idx, action: e.target.value as Action })); // изменение действия конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения типа действия
	const changeActionTypeHandler = (e: SelectChangeEvent<ActionType>): void => {
		setSelectActionType(e.target.value as ActionType);
		itsAction === 'robot' && dispatch(changeActionTypeRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			action: selectAction,
			actionType: e.target.value as ActionType,
			text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
		})); // робота

		itsAction === 'dataElement' && dispatch(changeActionTypeDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			action: selectAction,
			actionType: e.target.value as ActionType,
			text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
		})); // элемента данных

		itsAction === 'endpoint' && dispatch(changeActionTypeEndpoint({
			channel,
			idx,
			action: selectAction,
			actionType: e.target.value as ActionType,
			text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
		})); // конечной точки

		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления действия
	const deleteActionHandler = (): void => {
		itsAction === 'robot' && dispatch(deleteActionRobot({ actionEvent: actionEvent as RobotActionEventType, channel, idx })); // робота
		itsAction === 'dataElement' && dispatch(deleteActionDataElem({ actionEvent: actionEvent as DataElemActionEventType, channel, idx })); // элемента данных
		itsAction === 'endpoint' && dispatch(deleteActionEndpoint({ channel, idx })); // конечной точки
		setChangeFlg(true);
	};

	// обработчик добавления фразы
	const addPhraseHandler = (): void => {
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			itsAction === 'robot' && dispatch(addPhraseInActionRobot({
				actionEvent: actionEvent as RobotActionEventType, channel, idx, actionObj: {
					action: action.action,
					type: action.type,
					text: [...action.text, ''],
					conditions: action.conditions,
				}
			})); // перезапись действия робота с добавлением фразы
			itsAction === 'dataElement' && dispatch(addPhraseInActionDataElem({
				actionEvent: actionEvent as DataElemActionEventType, channel, idx, actionObj: {
					action: action.action,
					type: action.type,
					text: [...action.text, ''],
					conditions: action.conditions,
				}
			})); // перезапись действия элемента данных с добавлением фразы
			itsAction === 'endpoint' && dispatch(addPhraseInActionEndpoint({
				channel, idx, actionObj: {
					action: action.action,
					type: action.type,
					text: [...action.text, ''],
					conditions: action.conditions,
				}
			})); // перезапись действия конечной точки с добавлением фразы
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения скрипта
	const changeScriptHandler = (e: SelectChangeEvent<string>): void => {
		setScript(e.target.value);
		itsAction === 'robot' && dispatch(changeScriptRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			script: e.target.value,
		})); // робота
		itsAction === 'dataElement' && dispatch(changeScriptDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			script: e.target.value,
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeScriptEndpoint({
			channel,
			idx,
			script: e.target.value,
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения сервиса
	const changeServiceHandler = (e: SelectChangeEvent<ServiceType>): void => {
		setService(e.target.value as ServiceType);
		itsAction === 'robot' && dispatch(changeServiceRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			service: e.target.value as ServiceType,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		itsAction === 'dataElement' && dispatch(changeServiceDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			service: e.target.value as ServiceType,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeServiceEndpoint({
			channel,
			idx,
			service: e.target.value as ServiceType,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования кэша ответов
	const changeCachingHandler = (e: SelectChangeEvent<CachingType>): void => {
		setSelectCaching(e.target.value as CachingType);
		itsAction === 'robot' && dispatch(changeServiceRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			service,
			useCache: e.target.value as CachingType,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		itsAction === 'dataElement' && dispatch(changeServiceDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			service,
			useCache: e.target.value as CachingType,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeServiceEndpoint({
			channel,
			idx,
			service,
			useCache: e.target.value as CachingType,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования генерации
	const changeGPTHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckGPT(e.target.checked);
		itsAction === 'robot' && dispatch(changeServiceRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: e.target.checked ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		itsAction === 'dataElement' && dispatch(changeServiceDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: e.target.checked ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeServiceEndpoint({
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: e.target.checked ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования творческого режима
	const changeCreativeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckCreative(e.target.checked);
		itsAction === 'robot' && dispatch(changeServiceRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: e.target.checked ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		itsAction === 'dataElement' && dispatch(changeServiceDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: e.target.checked ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeServiceEndpoint({
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: e.target.checked ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования кэширования алиасов
	const changeAddAliasHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setAddAlias(e.target.checked);
		itsAction === 'robot' && dispatch(changeServiceRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: e.target.checked ? 'yes' : 'no',
		})); // робота
		itsAction === 'dataElement' && dispatch(changeServiceDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: e.target.checked ? 'yes' : 'no',
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeServiceEndpoint({
			channel,
			idx,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: e.target.checked ? 'yes' : 'no',
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения интервала
	const changeIntervalHandler = (e: SelectChangeEvent<string>): void => {
		setIntervalName(e.target.value);
		itsAction === 'robot' && dispatch(changeIntervalRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idx,
			interval: e.target.value,
		})); // робота
		itsAction === 'dataElement' && dispatch(changeIntervalDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idx,
			interval: e.target.value,
		})); // элемента данных
		itsAction === 'endpoint' && dispatch(changeIntervalEndpoint({
			channel,
			idx,
			interval: e.target.value,
		})); // конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления интервала
	const deleteIntervalHandler = (): void => {
		itsAction === 'robot' && dispatch(deleteIntervalRobot({ actionEvent: actionEvent as RobotActionEventType, channel, idx })); // робота
		itsAction === 'dataElement' && dispatch(deleteIntervalDataElem({ actionEvent: actionEvent as DataElemActionEventType, channel, idx })); // элемента данных
		itsAction === 'endpoint' && dispatch(deleteIntervalEndpoint({ channel, idx })); // конечной точки
		setIntervalName(''); // сбрасываем поле выбора интервала
		setChangeFlg(true);
	};

	return (
		<div className={styles.container}>
			<div className={styles.actionBlock}>
				{/* действие */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('actionEvent_selectAction')}</InputLabel>
					<Select
						required
						label={translate('actionEvent_selectAction')}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={selectAction}
						onChange={changeActionHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{ACTION_LIST.map(({ action, translation }) =>
							<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* тип действия */}
				{selectAction === 'say' &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('actionEvent_selectActionType')}</InputLabel>
						<Select
							required
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							label={translate('actionEvent_selectActionType')}
							value={selectActionType}
							onChange={changeActionTypeHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_TYPE_LIST.map(({ actionType, translation }) =>
								<MenuItem key={actionType}
									value={actionType}
									disabled={(actionType === 'external' && !isAccess(SES.SCRIPT_LIST)) || (actionType === 'internal' && itsAction === 'dataElement')}
									sx={{ fontSize: 13, color: colorPrimary }}
								>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				}

				{/* удаление действия */}
				{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteActionHandler}
						title={translate('actionEvent_deleteActionTitle')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>

			{/* фразы */}
			{action.action === 'say' && (action.type === 'linear' || action.type === 'random') &&
				action.text.map((phrase, idxPhrase, arrayPhrases) => (
					<Phrase
						key={phrase + idxPhrase}
						phrase={phrase}
						idxPhrase={idxPhrase}
						arrayPhrases={arrayPhrases}
						itsAction={itsAction}
						actionEvent={actionEvent}
						channel={channel}
						idxAction={idx}
						setChangeFlg={setChangeFlg}
					/>
				))
			}

			{/* скрипт */}
			{selectActionType === 'external' && selectAction === 'say' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('actionEvent_selectScript')}</InputLabel>
					<Select
						required
						label={translate('actionEvent_selectScript')}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={script}
						onChange={changeScriptHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{/* на случай, если не найден скрипт из списка */}
						{!scriptList.data.find(scriptItem => scriptItem.id === script) &&
							<MenuItem value={script} sx={{ fontSize: 12, color: colorRed }}>{script} ({translate('notFound')})</MenuItem>
						}
						{scriptList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}

			{/* сервис */}
			{selectActionType === 'internal' && selectAction === 'say' &&
				<>
					{/* сервис */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('actionEvent_selectService')}</InputLabel>
						<Select
							required
							label={translate('actionEvent_selectService')}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							value={service}
							onChange={changeServiceHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{SERVICE_TYPE_LIST.map(({ serviceType, translation }) =>
								<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>

					<div className={styles.additionally} onClick={() => setShowCategoriesSettings(prev => !prev)}>
						{translate('actionEvent_categoryTitle')}
					</div>

					{/* категория */}
					{showCategoriesSettings &&
						<>
							<FormControl fullWidth margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiInputBase-input': { padding: '8px 14px' },
							}}>
								<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCategory')}</InputLabel>
								<Select
									labelId="category-label"
									id="category"
									label={translate('answerToQuestion_selectCategory')}
									value={selectCategoryId}
									onChange={e => setSelectCategoryId(e.target.value)}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									{/* на случай, если не найдена категория */}
									{(Object.keys((action as IActionInternal)?.categories || {}).map(categoryId => {
										if (!categoriesList.data.find(categoryItem => categoryItem.id === categoryId)) {
											return (
												<MenuItem key={categoryId} value={categoryId} sx={{ fontSize: 12, color: colorRed }}>{categoryId} ({translate('notFound')})</MenuItem>
											);
										} else return undefined;
									}))}
									{categoriesList.data.map((category) => (
										<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>
											{category.name} {(action as IActionInternal).categories?.[category.id]?.length > 0 && `(${(action as IActionInternal).categories[category.id].length})`}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{/* блок условий категорий */}
							{((selectCategoryId !== '' && activeRobotVersion === 'draft') || (action as IActionInternal).categories?.[selectCategoryId]?.length > 0) &&
								// блоки ИЛИ
								<>
									{(action as IActionInternal).categories?.[selectCategoryId]?.map((categoryDataBlock, categoryBlockIdx) =>
										<Fragment key={categoryDataBlock.toString() + categoryBlockIdx}>
											<fieldset className={styles.categoryDataBlock}>
												<legend>{translate('actionEvent_conditionFieldsetLegend')}</legend>
												{categoryDataBlock?.map((categoryData, categoryIdx) => (
													<Fragment key={categoryData.data + categoryData.value + categoryBlockIdx + categoryIdx}>
														{/* блоки И */}
														<CategoryData
															categoryData={categoryData}
															itsAction={itsAction}
															setChangeFlg={setChangeFlg}
															actionEvent={actionEvent}
															channel={channel}
															channelIdx={idx}
															categoryId={selectCategoryId}
															categoryBlockIdx={categoryBlockIdx}
															categoryIdx={categoryIdx}
														/>
														<div className={styles.categoryDataBlockLine}>
															<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineAnd')}</span>
														</div>
													</Fragment>
												))}

												{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
													<FormAddingCategoryData
														itsAction={itsAction}
														actionEvent={actionEvent}
														channel={channel}
														channelIdx={idx}
														categoryId={selectCategoryId}
														categoryBlockIdx={categoryBlockIdx}
														setChangeFlg={setChangeFlg}
													/>
												}
											</fieldset>
											<div className={styles.categoryDataBlockLine}>
												<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineOr')}</span>
											</div>
										</Fragment>
									)}
									<fieldset className={styles.categoryDataBlock}>
										<legend>{translate('actionEvent_conditionFieldsetLegend')}</legend>
										{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
											<FormAddingCategoryData
												itsAction={itsAction}
												actionEvent={actionEvent}
												channel={channel}
												channelIdx={idx}
												categoryId={selectCategoryId}
												categoryBlockIdx={(action as IActionInternal).categories?.[selectCategoryId]?.length || 0}
												setChangeFlg={setChangeFlg}
											/>
										}
									</fieldset>
								</>
							}
						</>
					}

					{/* кэш ответов */}
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCacheAnswers')}</InputLabel>
						<Select
							labelId="caching-label"
							id="caching"
							label={translate('answerToQuestion_selectCacheAnswers')}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							value={selectCaching}
							onChange={changeCachingHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheNo')}</MenuItem>
							<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheAll')}</MenuItem>
							<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheChecked')}</MenuItem>
						</Select>
					</FormControl>

					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={checkGPT}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							onChange={changeGPTHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkGpt')} />
					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={checkCreative}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft' /* || !checkGPT */}
							onChange={changeCreativeHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkCreative')} />
					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px', textAlign: 'left' } }} control={
						<Checkbox
							checked={addAlias}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							onChange={changeAddAliasHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkCacheAlias')} />
				</>
			}

			<div className={styles.additionally} onClick={() => setShowAdditionalSettings(prev => !prev)}>
				{translate('actionException_additionallyTitle')}
			</div>

			{showAdditionalSettings &&
				<div className={cn(styles.actionBlock, {
					[styles.actionBlockOpacity]: intervalName === '',
				})}>
					{/* интервал */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('intervals_inputChoiceInterval')}</InputLabel>
						<Select
							label={translate('intervals_inputChoiceInterval')}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							value={intervalName}
							onChange={changeIntervalHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{/* на случай, если не найден интервал из списка */}
							{robotInfo.data && !Object.keys(robotInfo.data.timeintervals).includes(intervalName) && intervalName !== '' &&
								<MenuItem value={intervalName} sx={{ fontSize: 12, color: colorPrimary }}>{intervalName} ({translate('notFound')})</MenuItem>
							}
							{robotInfo.data && Object.keys(robotInfo.data.timeintervals).map((name) =>
								<MenuItem key={name} value={name} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
							)}
						</Select>
					</FormControl>

					{/* удаление интервала */}
					{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && intervalName !== '' && activeRobotVersion === 'draft' &&
						<FontAwesomeIcon
							icon={faTrashCan}
							color={colorRed}
							size='lg'
							onClick={deleteIntervalHandler}
							title={translate('actionException_deleteIntervalTitle')}
							style={{ cursor: 'pointer' }}
						/>
					}
				</div>
			}
		</div>
	);
};

export default ActionEvent;
