export const LOGIN = '/login';
export const CLASSIFIER = '/classifier';
export const ENTITIES = '/entities';
export const CORPUS_LINK = 'corpus';
export const MARKS_LINK = 'marks';
export const RECOGNIZER = '/recognizer';
export const QUEUE = 'queue';
export const TRANSCRIPT = 'transcript';
export const BIOMETRY = '/biometry';
export const SYNTHESIS = '/synthesis';
export const QUESTIONNAIRE = '/questionnaire';
export const QUESTION = 'question';
export const CANDIDATES = 'candidates';
export const ROBOTS = '/robots';
export const USERS = '/users';
export const ROLES = 'roles';
