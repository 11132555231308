import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addMarks, clearMarksList, getMarksList, selectAddMarks, selectMarksList } from '../../../store/marksSlice';
import useTranslate from '../../../hooks/useTranslate';
import { MARK_ID, } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingMarksProps } from './FormAddingMarks.props';

const FormAddingMarks = ({ showModal, setShowModal, setShowNotification }: IFormAddingMarksProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название словаря меток
	const [showErrorExistingMarks, setShowErrorExistingMarks] = useState<boolean>(false); // флаг существующего имени словаря

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const addMarksStatus = useAppSelector(selectAddMarks); // store - статус добавления словаря

	const [_cookies, setCookie] = useCookies([MARK_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления словаря
	useEffect(() => {
		// если добавление прошло успешно 
		if (addMarksStatus.error === ResponseStatus.SUCCESS && addMarksStatus.status === RequestStatus.IDLE && addMarksStatus.message !== '') {
			setCookie(MARK_ID, addMarksStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearMarksList()); // очищаем список словарей
			dispatch(getMarksList()); // получаем заново список словарей
		}
		// если были изменения в статусе добавления
		if (addMarksStatus.error !== ResponseStatus.SUCCESS || addMarksStatus.status === RequestStatus.FAILED || addMarksStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addMarksStatus]);

	// обработчик добавления словаря меток
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingMarksName = marksList.data.find(marks => marks.name === inputName); // существующее имя словаря
		if (existingMarksName) {
			setShowErrorExistingMarks(true); // подсвечиваем ошибку
		} else {
			showErrorExistingMarks && setShowErrorExistingMarks(false); // убираем ошибку, если была
			dispatch(addMarks({ marksName: inputName, data: [] })); // добавление словаря
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление словаря - запрещаем покидать форму
		if (addMarksStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingMarks_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="marksName"
						label={translate('formAddingMarks_inputName')}
						variant="outlined"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						disabled={addMarksStatus.status === RequestStatus.LOADING}
						error={showErrorExistingMarks}
						helperText={showErrorExistingMarks ? translate('formAddingMarks_existTitle') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addMarksStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addMarksStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingMarks_addingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingMarks_addBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingMarks;
