import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getQuestionsList, selectQuestion, selectQuestionsList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS, SERVICE } from '../../../constants/accessRights';
import { QUESTION, QUESTIONNAIRE } from '../../../constants/routes';
import { RequestStatus } from '../../../types/statusTypes';
import HideSidebar from '../HideSidebar/HideSidebar';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { ICandidatesNavbarProps } from './CandidatesNavbar.props';
import styles from './CandidatesNavbar.module.scss';

const CandidatesNavbar = ({ showSidebar, setShowSidebar, setShowPage }: ICandidatesNavbarProps): JSX.Element => {
	// const [selectCategoryId, setSelectCategoryId] = useState<string>(filter.category); // фильтр по категории
	// const [searchQuestion, setSearchQuestion] = useState<string>(filter.question); // поиск по вопросам
	// const [searchAnswer, setSearchAnswer] = useState<string>(filter.answer); // поиск по ответам
	// const [selectAnswerType, setSelectAnswerType] = useState<AnswerFilterType>(filter.answerType); // фильтр по ответам
	// const [advancedSearchFlg, setAdvancedSearchFlg] = useState<boolean>(false); // флаг показа расширенного поиска
	// const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления вопроса
	// const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	// const inputQuestionRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода вопроса
	// const inputAnswerRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода ответа
	const listRef = useRef<HTMLDivElement>(null); // ссылка на список вопросов

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	// const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const questionItem = useAppSelector(selectQuestion); // store - вопрос/ответ

	const navigate = useNavigate(); // hook для навигации
	// const [cookies] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// функция фильтрации списка вопросов
	// const filterFunction = (questionItem: IQuestionItem): boolean => {
	// 	return (questionItem.category === selectCategoryId || selectCategoryId === '') &&
	// 		((selectAnswerType === 'all' && (questionItem.checked >= 0 || questionItem.unchecked >= 0)) ||
	// 			(selectAnswerType === 'checked' && questionItem.checked > 0) ||
	// 			(selectAnswerType === 'unchecked' && questionItem.unchecked > 0) ||
	// 			(selectAnswerType === 'empty' && (questionItem.checked === 0 && questionItem.unchecked === 0))
	// 		);
	// };

	// виртуализация списка вопросов
	const virtualizer = useVirtualizer({
		count: questionsList.data.length,
		getScrollElement: () => listRef.current,
		estimateSize: () => 19,
		// overscan: 1,
	});

	const items = virtualizer.getVirtualItems();

	// следим за списком вопросов, фильтрацией по категории/типу ответов
	// useEffect(() => {
	// 	// если есть доступ и список вопросов не пустой
	// 	if (isAccess(QAS.QUESTION_GET) && questionsList.data.filter(filterFunction).length > 0) {
	// 		// если есть запись в cookie и список вопросов содержит эту запись, то передаем ее в обработчик выбора активного вопроса, иначе выбираем первый вопрос из списка
	// 		cookies[QAS_QUESTION_ID] && questionsList.data
	// 			.filter(filterFunction)
	// 			.find(questionItem => questionItem.id === cookies[QAS_QUESTION_ID]) ?
	// 			questionHandler(cookies[QAS_QUESTION_ID])
	// 			:
	// 			questionHandler(questionsList.data.filter(filterFunction)[0].id);
	// 	} else {
	// 		questionItem.questionId && dispatch(clearQuestion()); // иначе очищаем все данные о вопросе, если есть
	// 	}
	// 	selectedListQuestionIds.length > 0 && setSelectedListQuestionIds([]); // сброс выделенных вопросов
	// }, [questionsList.data, selectCategoryId, selectAnswerType]);

	// следим за полями поиска ответов/вопросов и флагом установки поиска по запросу
	// useEffect(() => {
	// 	// через пол-секунды бездействия после окончания ввода значения для фильтрации
	// 	const handler = setTimeout(() => {
	// 		// только если изменилось поле вопроса/ответа, либо хотя бы один символ, кроме пробела, либо пустое поле
	// 		if ((searchQuestion !== filter.question && (/\S/.test(searchQuestion) || searchQuestion === '')) || (searchAnswer !== filter.answer && (/\S/.test(searchAnswer) || searchAnswer === ''))) {
	// 			dispatch(changeAnswerFilter(searchAnswer)); // ставим фильтр по ответу в store
	// 			dispatch(changeQuestionFilter(searchQuestion));  // ставим фильтр по вопросу в store

	// 			dispatch(getQuestionsList({ answer: searchAnswer, question: searchQuestion })); // фильтруем
	// 		}
	// 	}, 500);

	// 	return () => {
	// 		clearTimeout(handler); // сбрасываем timeout, если продолжается ввод значения для фильтрации
	// 	};
	// }, [searchAnswer, searchQuestion]);

	// следим за активным вопросом
	// useEffect(() => {
	// 	const indexQuestion = questionsList.data.filter(filterFunction).findIndex(fileItem => fileItem.id === questionItem.questionId);
	// 	virtualizer.scrollToIndex(indexQuestion, { align: 'center' }); // показ активного вопроса в центре списка с имеющейся полосой прокрутки
	// 	// listRef.current?.children[questionsList.data.filter(filterFunction).findIndex(fileItem => fileItem.id === questionItem.questionId)]?.scrollIntoView({ block: "center" }); // показ активного вопроса в центре списка с имеющейся полосой прокрутки
	// }, [questionItem.questionId]);

	// обработчик изменения checkbox (выделение вопросов)
	// const checkboxChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
	// 	// если выбран checkbox - добавляем в список
	// 	if (e.target.checked) setSelectedListQuestionIds(prevState => [...prevState, e.target.id]);
	// 	// иначе убираем его из этого списка
	// 	else setSelectedListQuestionIds(prevState => prevState.filter(id => id !== e.target.id));
	// };

	// обработчик изменения checkbox (выделение всех вопросов)
	// const checkboxAllChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
	// 	// если выбран checkbox - добавляем весь список
	// 	if (e.target.checked) setSelectedListQuestionIds(questionsList.data.filter(filterFunction).map(question => question.id));
	// 	// иначе очищаем
	// 	else setSelectedListQuestionIds([]);
	// };

	// обработчик выбора активного вопроса
	// const questionSelectionHandler = (id: string) => {
	// 	// если текущий id не равен id выбираемого вопроса
	// 	if (id !== questionItem.questionId) {
	// 		questionHandler(id); // запускаем обработчик получения вопроса
	// 	}
	// };

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 1 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>


						{/* ошибка загрузки списка вопросов */}
						{(questionsList.status === RequestStatus.FAILED || questionsList.message !== '') &&
							<div className={styles.navbarFailedText}>
								<span>{translate(questionsList.message || 'questionNavbar_failedQuestionsList')}</span>
								{isAccess(QAS.QUESTION_LIST) &&
									<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getQuestionsList({}))}>{translate('update')}</span>
								}
							</div>
						}

						{/* пустой список вопросов */}
						{(questionsList.status === RequestStatus.IDLE || questionsList.update) && questionsList.data.length === 0 &&
							<div className={styles.navbarNoQuestions}>{translate('questionNavbar_emptyQuestionsList')}</div>
						}

						{/* загрузка списка вопросов */}
						{questionsList.status === RequestStatus.LOADING && !questionsList.update &&
							<div className={styles.navbarLoading}>
								<ProgressCircle title={translate('progressCircle_questionsListLoadingTitle')} />
							</div>
						}

						{/* список вопросов */}
						{(questionsList.status === RequestStatus.IDLE || questionsList.update) && questionsList.data.length > 0 &&
							<div className={styles.navbarQuestions} ref={listRef}>
								<div
									style={{
										height: `${virtualizer.getTotalSize()}px`,
										width: '100%',
										position: 'relative',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											transform: `translateY(${items[0]?.start ?? 0}px)`,
										}}
									>
										{items.map((virtualItem) => {
											const { question, id } = questionsList.data[virtualItem.index];
											return (
												<div
													key={id}
													data-index={virtualItem.index}
													ref={virtualizer.measureElement}
												>
													<div className={styles.navbarQuestionsItem}>
														<Fade in={questionsList.status !== RequestStatus.LOADING || (questionsList.data.length > 0)} timeout={500}>
															<div
																className={cn({
																	[styles.navbarQuestionsLink]: questionItem.questionId !== id,
																	// [styles.navbarQuestionsLinkActive]: questionItem.questionId === id,
																})}
															// onClick={() => isAccess(QAS.QUESTION_GET) && questionSelectionHandler(id)}
															>
																<div className={styles.navbarQuestionsLinkLeftBlock} title={question}>
																	{/* {highlightMultipleQuestions &&
																		<input
																			type='checkbox'
																			id={id}
																			checked={selectedListQuestionIds.includes(id)}
																			onChange={checkboxChangeHandler}
																			onClick={e => e.stopPropagation()}
																		/>
																	} */}
																	<span>{question}</span>
																</div>
																{/* <div className={cn(styles.navbarQuestionsLinkIcon, {
																	[styles.navbarQuestionsLinkIconActive]: questionItem.questionId === id,
																})}>
																	<FontAwesomeIcon icon={faAngleRight} />
																</div> */}
															</div>
														</Fade>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						}

						<div className={styles.functionButtons}>
							{/* табы */}
							{isAccess(SERVICE.QAS) &&
								<div className={styles.functionButtonsTabs}>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(QUESTIONNAIRE)}>
										{translate('questionNavbar_tabDocuments')}
									</div>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(`${QUESTIONNAIRE}/${QUESTION}`)}
									>
										{translate('questionNavbar_tabQuestions')}
									</div>
									<div className={styles.functionButtonsTab}>{translate('questionNavbar_tabCandidates')}</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default CandidatesNavbar;
