import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getCategoriesList, getChannelList, getQuestionsList, selectCategoriesList, selectChannelList, selectQuestionsList } from '../../store/qasSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS, SERVER, SERVICE } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import CandidatesNavbar from '../../components/Navbars/CandidatesNavbar/CandidatesNavbar';
import CandidatesControls from '../../components/Controls/CandidatesControls/CandidatesControls';
import CandidatesTable from '../../components/Tables/Candidates/Candidates';
import NoticeSingleAction from '../../components/Notification/NoticeSingleAction/NoticeSingleAction';
import { ICandidatesProps } from './Candidates.props';
import styles from './Candidates.module.scss';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';

const Candidates = ({ serviceType }: ICandidatesProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [showNotificationCategory, setShowNotificationCategory] = useState<boolean>(false); // показ уведомлений статуса загрузки списка категорий
	const [showNotificationChannel, setShowNotificationChannel] = useState<boolean>(false); // показ уведомлений статуса загрузки списка каналов

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(QAS.QUESTION_LIST) && dispatch(getQuestionsList({})); // получаем все вопросы
		isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		isAccess(QAS.CHANNEL_LIST) && dispatch(getChannelList()); // получаем список каналов
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очистка всего state
		};
	}, []);

	// следим за статусом получения списка категорий и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (categoriesList.status === RequestStatus.FAILED || categoriesList.error === ResponseStatus.FAILED) setShowNotificationCategory(true);
	}, [categoriesList.status]);

	// следим за статусом получения списка каналов и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (channelList.status === RequestStatus.FAILED || channelList.error === ResponseStatus.FAILED) setShowNotificationChannel(true);
	}, [channelList.status]);


	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.QAS, QAS.QUESTION_LIST]}>
			<>
				<CandidatesControls
					setShowPage={setShowPage}
				/>

				<div className={styles.bottomContainer}>
					<CandidatesNavbar
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
					/>

					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{questionsList.data.length > 0 ?
							<Fade in={true} timeout={500}>
								<div className={styles.wrapperTable}>
									<CandidatesTable />
									{questionsList.status === RequestStatus.LOADING &&
										<div className={styles.wrapperTableScreenLock}>
											<ProgressCircle title='' />
										</div>
									}
								</div>
							</Fade>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</div>

				{showNotificationCategory &&
					<NoticeSingleAction
						showNotification={showNotificationCategory}
						setShowNotification={setShowNotificationCategory}
						title={translate(categoriesList.message || 'noticeCategoriesList_failed')}
					/>
				}
				{showNotificationChannel &&
					<NoticeSingleAction
						showNotification={showNotificationChannel}
						setShowNotification={setShowNotificationChannel}
						title={translate(channelList.message || 'noticeChannelList_failed')}
					/>
				}
			</>
		</PageWrapper>
	);
};

export default Candidates;
