import { useState } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/hooks';
import { addModelConfig } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { ROBOT_MODEL_SERVICE_TYPE_EXTENDED_LIST, ServiceTypeModelRobotExtended } from '../../../constants/robotConfigLists';
import { colorPrimary } from '../../../constants/colors';
import { ServiceTypeModelRobot } from '../../../types/cloudTypes';
import { IFormAddingModelConfigProps } from './FormAddingModelConfig.props';
import styles from './FormAddingModelConfig.module.scss';

const FormAddingModelConfig = ({ setChangeFlg, modelsListSmc, modelsListSee, modelsListTts }: IFormAddingModelConfigProps): JSX.Element => {
	const [selectType, setSelectType] = useState<ServiceTypeModelRobotExtended>('chooseType'); // тип
	const [inputModelName, setInputModelName] = useState<string>(''); // название модели

	const dispatch = useAppDispatch();

	const translate = useTranslate(); // hook для перевода текста

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setSelectType('chooseType');
		setInputModelName('');
	};

	// обработчик добавления настроек модели
	const changeModelHandler = (): void => {
		if (inputModelName !== '') {
			dispatch(addModelConfig({ serviceType: selectType as ServiceTypeModelRobot, modelName: inputModelName }));
			setChangeFlg(true); // ставим флаг о несохраненных данных
			resetInputAndSelect(); // очищаем поля
		}
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: selectType === 'chooseType'
		})}>
			{/* тип */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('configDataElem_selectType')}</InputLabel>
				<Select
					required
					label={translate('configDataElem_selectType')}
					value={selectType}
					onChange={e => setSelectType(e.target.value as ServiceTypeModelRobot)}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{ROBOT_MODEL_SERVICE_TYPE_EXTENDED_LIST.map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }} disabled={type === 'chooseType'}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* модель */}
			{selectType !== 'chooseType' &&
				<FormControl fullWidth margin='dense'>
					<Autocomplete
						freeSolo
						options={selectType === 'smc' ? modelsListSmc : selectType === 'see' ? modelsListSee : modelsListTts}
						value={inputModelName}
						onChange={(_, value) => setInputModelName(value ? value : '')}
						onBlur={changeModelHandler}
						renderInput={(params) =>
							<TextField
								{...params}
								label={translate('configDataElem_inputModel')}
								onChange={(e) => setInputModelName(e.target.value)}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						}
						sx={{
							".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
							".MuiInputBase-input": { marginTop: -1 },
						}}
						getOptionLabel={option => option}
						renderOption={(props, option) => {
							return (
								<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
									{option}
								</span>
							);
						}}
					/>
				</FormControl>
			}
		</div>
	);
};

export default FormAddingModelConfig;
