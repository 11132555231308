import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeleteMarks, clearMarksList, clearPutMarks, deleteMarks, getMarksList, putMarks, selectDeleteMarks, selectMarks, selectMarksList, selectPutMarks } from '../../../store/marksSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { MARKS, SERVER } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ScreenLock from '../../ScreenLock/ScreenLock';
import ServerStatus from '../../ServerStatus/ServerStatus';
import ExportMarks from '../Buttons/ExportMarks/ExportMarks';
import ImportMarks from '../Buttons/ImportMarks/ImportMarks';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import { IMarksControlsProps } from './MarksControls.props';
import styles from './MarksControls.module.scss';

const MarksControls = ({ changeFlg, setChangeFlg, setShowPage }: IMarksControlsProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна при сохранении
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления о сохранении

	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна при удалении
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const marks = useAppSelector(selectMarks); // store - словарь меток
	const putMarksStatus = useAppSelector(selectPutMarks); // store - статус сохранения изменений в словаре меток
	const deleteMarksStatus = useAppSelector(selectDeleteMarks); // store - статус удаления словаря меток
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления роли
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (putMarksStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_saveMarksTitle') });
		else if (deleteMarksStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_deleteMarksTitle') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменения сохранились
		if (putMarksStatus.error === ResponseStatus.SUCCESS && putMarksStatus.status === RequestStatus.IDLE && putMarksStatus.message !== '') {
			setChangeFlg(false); // выключаем флаг измененных данных
		}
		// если удалилось успешно 
		if (deleteMarksStatus.error === ResponseStatus.SUCCESS && deleteMarksStatus.status === RequestStatus.IDLE && deleteMarksStatus.message !== '') {
			dispatch(clearMarksList()); // очищаем список словарей
			dispatch(getMarksList()); // получаем заново список словарей меток
		}
	}, [putMarksStatus, deleteMarksStatus]);

	// обработчик сохранения изменений
	const changeHandler = (): void => {
		// если есть не сохраненные изменения
		if (changeFlg) {
			setShowAlertDialog(false); // закрываем диалоговое окно
			setShowNotification(true); // включаем уведомление
			marksList.activeMarksId && dispatch(putMarks({ marksId: marksList.activeMarksId, data: marks.data })); // сохранение (изменение)
		}
	};

	// обработчик удаления словаря меток
	const deleteMarksHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete && setShowNotificationDelete(true); // включаем уведомление об удалении словаря
		marksList.activeMarksId && dispatch(deleteMarks(marksList.activeMarksId)); // удаление словаря меток
	};

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('smc')}</div>
						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}></div>

							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsLeftBlock}>
									{isAccess(MARKS.EXPORT) &&
										<ExportMarks
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
										/>
									}
									{isAccess(MARKS.IMPORT) &&
										<ImportMarks
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
										/>
									}
								</div>

								<div className={styles.controlsFunctionsCenterBlock}>
									{isAccess(MARKS.PUT) &&
										<Save
											styleNotActive={styles.controlsFunctionBtnNotActive}
											changeFlg={changeFlg}
											showNotification={showNotification}
											setShowNotification={setShowNotification}
											dataResponse={selectPutMarks}
											clearDataResponse={clearPutMarks}
											setShowAlertDialog={setShowAlertDialog}
										/>
									}
									{isAccess(MARKS.DELETE) &&
										<Delete
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
											// title='controlsBtn_deleteTranscript'
											setShowAlertDialog={setShowAlertDialogDelete}
										/>
									}
								</div>

								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>

				{/* бирки */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
					<section className={styles.tags}>
						{isAccess(SERVER.ADDRESSES) &&
							<div className={styles.tag} onClick={() => setShowServers(prev => !prev)}>
								<span className={styles.tagTitle}>
									<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
									{translate('tag_servers')}
								</span>
							</div>
						}
					</section>
				</Slide>

				<ServerStatus showServers={showServers} setShowServers={setShowServers} />
			</div>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{showAlertDialog &&
				<AlertDialog
					showAlertDialog={showAlertDialog}
					setShowAlertDialog={setShowAlertDialog}
					submitHandler={changeHandler}
					title='dialog_saveMarksTitle'
					description='dialog_saveMarksConfirm'
					name={marksList.activeMarksName || ''}
				/>
			}
			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteMarksHandler}
					title='dialog_deleteMarksTitle'
					description='dialog_deleteMarksConfirm'
					name={marksList.activeMarksName || ''}
				/>
			}
			{/* уведомление для удаления словаря меток */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeleteMarks}
					clearDataResponse={clearDeleteMarks}
					titleFailed='noticeDeleteMarks_failed'
					titleSuccess='noticeDeleteMarks_success'
				/>
			}
		</>
	);
};

export default MarksControls;
