import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearExportMarks, exportMarks, selectExportMarks, selectMarksList } from '../../../../store/marksSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import NoticeSingleAction from '../../../Notification/NoticeSingleAction/NoticeSingleAction';
import { IExportMarksProps } from './ExportMarks.props';

const ExportMarks = ({ styleNotActive, isAvailable }: IExportMarksProps): JSX.Element => {
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления
	const [showNotificationLoading, setShowNotificationLoading] = useState<boolean>(false); // показ уведомления загрузки

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const exportMarksStatus = useAppSelector(selectExportMarks); // store - статус экспорта словаря меток

	const translate = useTranslate(); // hook для перевода текста

	// обработчик экспорта словаря меток
	const exportHandler = () => {
		setShowNotificationLoading(true); // включаем уведомление для начала экспорта
		setShowNotification(true); // включаем уведомление для полученных данных
		marksList.activeMarksId && marksList.activeMarksName && dispatch(exportMarks({ marksId: marksList.activeMarksId, marksName: marksList.activeMarksName })); // экспорт словаря меток
	};

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={exportHandler}
				title={translate('controlsBtn_exportMarks')}>
				<FontAwesomeIcon icon={faCloudArrowDown} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectExportMarks}
						clearDataResponse={clearExportMarks}
						titleFailed='noticeExportMarks_failed'
						titleSuccess='noticeExportMarks_success'
					/>
				}
				{showNotificationLoading &&
					<NoticeSingleAction
						showNotification={showNotificationLoading}
						setShowNotification={setShowNotificationLoading}
						title='noticeExportMarks_loading'
						severity='info'
						dataResponse={exportMarksStatus}
					/>}
			</section>
		</>
	);
};

export default ExportMarks;
