import { DataElemType, ServiceTypeModelRobot, ServiceTypeRobot } from '../types/cloudTypes';
import { Action, ActionType, DataElemActionEventType, ServiceType } from '../types/sesTypes';
import { DaysType, ExceptionType, RobotActionEventType, ServiceDataType } from '../types/sesRobotTypes';

export type ActionExtended = Action | 'chooseAction';
export type ActionTypeExtended = ActionType | 'chooseActionType';
export type ServiceTypeExtended = ServiceType | 'chooseService';
export type TypeExtended = DataElemType | 'chooseType';
export type ServiceTypeModelRobotExtended = ServiceTypeModelRobot | 'chooseType';

// список событий робота
export const ROBOT_EVENT_LIST: { event: RobotActionEventType, translation: string }[] = [
	{ event: 'start', translation: 'robotEvent_start' },
	{ event: 'hotstart', translation: 'robotEvent_hotStart' },
	{ event: 'loop', translation: 'robotEvent_loop' },
	{ event: 'not found', translation: 'robotEvent_notFound' },
	{ event: 'repeat', translation: 'robotEvent_repeat' },
];

// список исключений робота
export const ROBOT_EXCEPTION_LIST: { exception: ExceptionType, translation: string }[] = [
	{ exception: 'silence', translation: 'robotException_silence' },
	{ exception: 'not found', translation: 'robotException_notFound' },
];

// список сервисных данных робота
export const ROBOT_SERVICE_DATA_LIST: { serviceData: ServiceDataType, translation: string }[] = [
	{ serviceData: 'yes', translation: 'robotServiceData_yes' },
	{ serviceData: 'no', translation: 'robotServiceData_no' },
	{ serviceData: 'repeat', translation: 'robotServiceData_repeat' },
];

// список действий
export const ACTION_LIST: { action: Action, translation: string }[] = [
	{ action: 'say', translation: 'action_say' },
	{ action: 'end', translation: 'action_end' },
];

// список типов действий
export const ACTION_TYPE_LIST: { actionType: ActionType, translation: string }[] = [
	{ actionType: 'linear', translation: 'actionType_linear' },
	{ actionType: 'random', translation: 'actionType_random' },
	{ actionType: 'external', translation: 'actionType_external' },
	{ actionType: 'internal', translation: 'actionType_internal' },
];

// список сервисов
export const SERVICE_TYPE_LIST: { serviceType: ServiceType, translation: string }[] = [
	{ serviceType: 'qas', translation: 'QAS' },
];

// список типов элемента данных
export const DATA_ELEM_TYPE_LIST: { type: DataElemType, translation: string }[] = [
	{ type: 'smc', translation: 'dataElemType_smc' },
	{ type: 'see', translation: 'dataElemType_see' },
	{ type: 'script', translation: 'dataElemType_script' },
];

// список доступных сервисов робота
export const ROBOT_SERVICE_TYPE_LIST: { type: ServiceTypeRobot, translation: string }[] = [
	{ type: 'smc', translation: 'dataElemType_smc' },
	{ type: 'see', translation: 'dataElemType_see' },
];

// список сервисов моделей робота
export const ROBOT_MODEL_SERVICE_TYPE_LIST: { type: ServiceTypeModelRobot, translation: string }[] = [
	{ type: 'smc', translation: 'dataElemType_smc' },
	{ type: 'see', translation: 'dataElemType_see' },
	{ type: 'tts', translation: 'robotModelType_tts' },
];

// список событий элемента данных
export const DATA_ELEM_EVENT_LIST: { event: DataElemActionEventType, translation: string }[] = [
	{ event: 'alone', translation: 'dataElemEvent_alone' },
	{ event: 'nearest', translation: 'dataElemEvent_nearest' },
];

// список дней с опорой на производственный календарь
export const DAYS_PRODUCTION_CALENDAR_LIST: { day: DaysType, translation: string }[] = [
	{ day: 'all', translation: 'daysProductionCalendarList_all' },
	{ day: 'workdays', translation: 'daysProductionCalendarList_workdays' },
	{ day: 'holidays', translation: 'daysProductionCalendarList_holidays' },
];

// список дней недели
export const WEEK_DAYS_LIST: { day: number, translationFull: string, translationShort: string }[] = [
	{ day: 1, translationFull: 'Понедельник', translationShort: 'weekDaysList_mon' },
	{ day: 2, translationFull: 'Вторник', translationShort: 'weekDaysList_tue' },
	{ day: 3, translationFull: 'Среда', translationShort: 'weekDaysList_wed' },
	{ day: 4, translationFull: 'Четверг', translationShort: 'weekDaysList_thu' },
	{ day: 5, translationFull: 'Пятница', translationShort: 'weekDaysList_fri' },
	{ day: 6, translationFull: 'Суббота', translationShort: 'weekDaysList_sat' },
	{ day: 7, translationFull: 'Воскресенье', translationShort: 'weekDaysList_sun' },
];

// список месяцев
export const MONTHS_LIST: { month: number, translationFull: string, translationShort: string }[] = [
	{ month: 1, translationFull: 'Январь', translationShort: 'monthsList_jan' },
	{ month: 2, translationFull: 'Февраль', translationShort: 'monthsList_feb' },
	{ month: 3, translationFull: 'Март', translationShort: 'monthsList_mar' },
	{ month: 4, translationFull: 'Апрель', translationShort: 'monthsList_apr' },
	{ month: 5, translationFull: 'Май', translationShort: 'monthsList_may' },
	{ month: 6, translationFull: 'Июнь', translationShort: 'monthsList_june' },
	{ month: 7, translationFull: 'Июль', translationShort: 'monthsList_july' },
	{ month: 8, translationFull: 'Август', translationShort: 'monthsList_aug' },
	{ month: 9, translationFull: 'Сентябрь', translationShort: 'monthsList_sept' },
	{ month: 10, translationFull: 'Октябрь', translationShort: 'monthsList_oct' },
	{ month: 11, translationFull: 'Ноябрь', translationShort: 'monthsList_nov' },
	{ month: 12, translationFull: 'Декабрь', translationShort: 'monthsList_dec' },
];

export const ACTION_EXTENDED_LIST: { action: ActionExtended, translation: string }[] = [
	{ action: 'chooseAction', translation: 'action_choose' },
	...ACTION_LIST,
];

export const ACTION_TYPE_EXTENDED_LIST: { actionType: ActionTypeExtended, translation: string }[] = [
	{ actionType: 'chooseActionType', translation: 'actionType_choose' },
	...ACTION_TYPE_LIST,
];

export const SERVICE_TYPE_EXTENDED_LIST: { serviceType: ServiceTypeExtended, translation: string }[] = [
	{ serviceType: 'chooseService', translation: 'service_choose' },
	...SERVICE_TYPE_LIST,
];

export const ROBOT_SERVICE_TYPE_EXTENDED_LIST: { type: TypeExtended, translation: string }[] = [
	{ type: 'chooseType', translation: 'dataElemType_choose' },
	...ROBOT_SERVICE_TYPE_LIST,
];

export const ROBOT_MODEL_SERVICE_TYPE_EXTENDED_LIST: { type: ServiceTypeModelRobotExtended, translation: string }[] = [
	{ type: 'chooseType', translation: 'dataElemType_choose' },
	...ROBOT_MODEL_SERVICE_TYPE_LIST,
];
