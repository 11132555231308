import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCorpusList, putCorpus, selectAutoMark, selectCorpusList, selectCorpusPutStatus } from '../../../store/corpusSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingAutoMarkCorpusProps } from './FormAddingAutoMarkCorpus.props';

const FormAddingAutoMarkCorpus = ({ showModal, setShowModal, setShowNotification }: IFormAddingAutoMarkCorpusProps): JSX.Element => {
	const [corpusNameInput, setCorpusNameInput] = useState<string>(''); // имя нового корпуса
	const [existCorpusFlg, setExistCorpusFlg] = useState<boolean>(false); // флаг для уведомления о существующем корпусе

	const dispatch = useAppDispatch();
	const autoMarkResult = useAppSelector(selectAutoMark); // store - результат авторазметки
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус создания (изменения) корпуса

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом соаздения (изменения) корпуса
	useEffect(() => {
		// если есть изменения
		if (putStatus.error !== ResponseStatus.SUCCESS || putStatus.status === RequestStatus.FAILED || putStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}

		// если добавление прошло успешно 
		if (putStatus.error === ResponseStatus.SUCCESS && putStatus.status === RequestStatus.IDLE && putStatus.message !== '') {
			dispatch(getCorpusList({ serviceType: 'smc' })); // получаем заново список корпусов
		}
	}, [putStatus]);

	// обработчик создания корпуса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя корпуса уже существует - ставим флаг на существование такого корпуса и выходим
		if (corpusList.data.includes(corpusNameInput)) {
			setExistCorpusFlg(true);
			return;
		}
		dispatch(putCorpus({ corpusName: corpusNameInput, text: { classes: [], data: autoMarkResult.data, groups: [] }, serviceType: 'smc' }));
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет создание - запрещаем покидать форму
		if (putStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingAutomarkCorpus_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						label={translate('formErrorsInCorpus_inputCorpusName')}
						variant="outlined"
						value={corpusNameInput}
						disabled={putStatus.status === RequestStatus.LOADING}
						onChange={(e) => setCorpusNameInput(e.target.value)}
						error={existCorpusFlg}
						helperText={existCorpusFlg ? translate("formErrorsInCorpus_existCorpusTitle") : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={putStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{putStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingAutomarkCorpus_createBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingAutomarkCorpus_createBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingAutoMarkCorpus;
