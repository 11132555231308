import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCorpusList, selectCorpusList } from '../../../store/corpusSlice';
import { getMarksList, selectMarksList } from '../../../store/marksSlice';
import { trainModelSmc } from '../../../store/smcSlice';
import { trainModelSee } from '../../../store/seeSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { CORPUS, MARKS } from '../../../constants/accessRights';
import { NoiseType } from '../../../types/seeTypes';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormExportProps } from './FormTrain.props';
import styles from './FormTrain.module.scss';

const FormTrain = ({ showModal, setShowModal, modelName, serviceType, setShowNotification }: IFormExportProps): JSX.Element => {
	const [checked, setChecked] = useState<boolean>(false); // переключатель данных для обучения (false - корпус, true - метки)
	const [corpusName, setCorpusName] = useState<string>(''); // имя корпуса для обучения
	const [marksId, setMarksId] = useState<string>(''); // id словаря меток для обучения
	const [noise, setNoise] = useState<NoiseType>(2); // шум

	const dispatch = useAppDispatch();
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(CORPUS.LIST) && dispatch(getCorpusList({ serviceType })); // получение списка корпусов
		serviceType === 'smc' && isAccess(MARKS.LIST) && dispatch(getMarksList()); // получение списка словарей меток
	}, []);

	// обработчик обучения модели
	const submitHandlerModel = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		serviceType === 'smc' && modelName && dispatch(trainModelSmc({
			modelName,
			corpusName: checked ? marksId : corpusName,
			isMarks: checked ? 1 : 0,
		})); // обучение модели smc
		serviceType === 'see' && modelName && dispatch(trainModelSee({ modelName, corpusName, noise })); // обучение модели see
		setShowModal(false); // закрываем форму
		setShowNotification(true); // включаем уведомления
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle={`${translate('formTrain_title')} "${modelName}"`}>
			{(corpusList.status === RequestStatus.LOADING || marksList.status === RequestStatus.LOADING) ?
				<ProgressCircle title={translate('progressCircle_formLoadingTitle')} />
				:
				<form onSubmit={(e) => submitHandlerModel(e)}>
					{(corpusList.status === RequestStatus.FAILED || corpusList.data.length < 1 || !Array.isArray(corpusList.data)) ?
						<div>{translate('formTrain_noCorpusesTitle')}</div>
						:
						<>
							{serviceType === 'smc' &&
								<div className={styles.switchBlock}>
									{translate('formTrain_switchCorpus')}
									<Switch
										checked={checked}
										onChange={e => setChecked(e.target.checked)}
									/>
									{translate('formTrain_switchMarks')}
								</div>
							}
							{checked ?
								// словарь меток
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="marksId-label" sx={{ fontSize: 13 }}>{translate('formTrain_inputCorpus')}</InputLabel>
									<Select
										required
										labelId="marksId-label"
										id="marksId"
										label={translate('formTrain_inputCorpus')}
										value={marksId}
										onChange={(e) => setMarksId(e.target.value)}
										style={{ fontSize: 13, height: 33 }}
									>
										{marksList.data.map((marksItem) => (
											<MenuItem key={marksItem.id} value={marksItem.id} sx={{ fontSize: 13 }}>{marksItem.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								:
								// корпус
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="corpusName-label" sx={{ fontSize: 13 }}>{translate('formTrain_inputCorpus')}</InputLabel>
									<Select
										required
										labelId="corpusName-label"
										id="corpusName"
										label={translate('formTrain_inputCorpus')}
										value={corpusName}
										onChange={(e) => setCorpusName(e.target.value)}
										style={{ fontSize: 13, height: 33 }}
									>
										{corpusList.data.map((corpus) => (
											<MenuItem key={corpus} value={corpus} sx={{ fontSize: 13 }}>{corpus}</MenuItem>
										))}
									</Select>
								</FormControl>
							}

							{serviceType === 'see' &&
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="noise-label" sx={{ fontSize: 13 }}>{translate('formTrain_inputNoise')}</InputLabel>
									<Select
										labelId="noise-label"
										id="noise"
										label={translate('formTrain_inputNoise')}
										value={noise}
										onChange={(e) => setNoise(e.target.value as NoiseType)}
										style={{ fontSize: 13, height: 33 }}
									>
										{[0, 1, 2, 3, 4, 5].map((value) => (
											<MenuItem key={value} value={value} sx={{ fontSize: 13 }}>{value}</MenuItem>
										))}
									</Select>
								</FormControl>
							}

							<FormControl fullWidth margin='dense'>
								<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>{translate('formTrain_trainBtn')}</Button>
							</FormControl>
						</>
					}
				</form>
			}
		</ModalFormWindow>
	);
};

export default FormTrain;
