import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAutomark, clearAutomarkList, clearPutResponseCorpus, getAutomarkList, getAutomarkResult, selectAutoMark, selectAutoMarkList, selectCorpusPutStatus } from '../../store/corpusSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { AUTO_MARK_TASK_ID } from '../../constants/cookieNames';
import { CORPUS } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import AutoMarkTable from './AutoMarkTable/AutoMarkTable';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import FormAddingAutoMarkCorpus from '../Forms/FormAddingAutoMarkCorpus/FormAddingAutoMarkCorpus';
import Notification from '../Notification/Notification';
import { IAutoMarkProps } from './AutoMark.props';
import styles from './AutoMark.module.scss';

const AutoMark = ({ showAutoMark, setShowAutoMark }: IAutoMarkProps): JSX.Element => {
	const [activeTaskId, setActiveTaskId] = useState<string>(''); // id активной задачи
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы для преобразования ошибок в корпус
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений

	const dispatch = useAppDispatch();
	const autoMarkList = useAppSelector(selectAutoMarkList); // store - список задач авторазметки
	const autoMarkResult = useAppSelector(selectAutoMark); // store - результат авторазметки
	// const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус изменения корпуса

	const [cookies] = useCookies([AUTO_MARK_TASK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за состоянием вкладки
	useEffect(() => {
		dispatch(clearAutomarkList()); // очищаем список задач авторазметки
		dispatch(clearAutomark()); // очищаем результат авторазметки
		setActiveTaskId(''); // сброс id активной задачи
		showAutoMark && dispatch(getAutomarkList()); // если вкладка ошибок открыта - получаем список задач авторазметки
	}, [showAutoMark]);

	// следим за списком задач авторазметки
	useEffect(() => {
		// если есть список задач и право
		if (autoMarkList.data.length > 0 && isAccess(CORPUS.AUTOMARK_RESULT)) {
			// устанавливаем id активной задачи
			if (autoMarkList.data.find(autoMarkTask => autoMarkTask.id === cookies.autoMarkTaskId)) setActiveTaskId(cookies.autoMarkTaskId);
			else setActiveTaskId(autoMarkList.data[0].id);
		}
	}, [autoMarkList.data]);

	// следим за активной задачей
	useEffect(() => {
		activeTaskId && dispatch(getAutomarkResult(activeTaskId)); // получаем результат
	}, [activeTaskId]);

	// обработчик выбора активной задачи
	const activeTaskHandler = (taskId: string) => {
		dispatch(clearAutomark()); // очищаем результат авторазметки
		isAccess(CORPUS.AUTOMARK_RESULT) && setActiveTaskId(taskId); // устанавливаем id активной задачи
	};

	// обработчик закрытия вкладки
	const handleClose = (): void => {
		setShowAutoMark(false);  // закрытие вкладки
		setShowNotification(false); // выключение уведомления
	};

	// функция преобразования данных авторазметки в .csv файл
	const arrAutoMarkInCsvFormat = (): string => {
		let str: string = '';
		autoMarkResult.data.forEach(row => str += row[0].join(',') + '\t' + row[1] + '\n');
		return str;
	};

	return (
		<Slide direction="left" in={showAutoMark} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={handleClose}>
				<div className={styles.autoMark} onMouseDown={(e) => e.stopPropagation()}>
					<div className={styles.autoMarkInner}>
						{/* загрузка списка задач авторазметок */}
						{autoMarkList.status === RequestStatus.LOADING &&
							<div className={styles.loading}>
								<ProgressCircle title={translate('progressCircle_autoMarkListLoadingTitle')} />
							</div>
						}

						{/* ошибка получения списка задач авторазметок */}
						{(autoMarkList.status === RequestStatus.FAILED || autoMarkList.error === ResponseStatus.FAILED) &&
							<div className={styles.failed}>
								{translate(autoMarkList.message || 'autoMark_failedTitle')}
							</div>
						}

						{/* пустой список */}
						{autoMarkList.data.length === 0 && autoMarkList.status === RequestStatus.IDLE && autoMarkList.error === ResponseStatus.SUCCESS &&
							<div className={styles.empty}>
								{translate('autoMark_emptyTitle')}
							</div>
						}

						{/* задачи авторазметок */}
						{autoMarkList.data.length > 0 &&
							<Fade in={true} timeout={500}>
								<div className={styles.autoMarkData}>
									{/* загрузка таблицы результата */}
									{autoMarkResult.status === RequestStatus.LOADING &&
										<div className={cn(styles.loading, styles.loadingAutomarkResult)}>
											<ProgressCircle title={translate('progressCircle_autoMarkResultLoadingTitle')} />
										</div>
									}
									{/* ошибка получения таблицы результата */}
									{(autoMarkResult.status === RequestStatus.FAILED || autoMarkResult.error === ResponseStatus.FAILED) &&
										<div className={cn(styles.failed, styles.failedAutomarkResult)}>
											<span>{translate(autoMarkResult.message || 'autoMark_failedResultTitle')}</span>
											<span className={styles.failedAutomarkResultUpdate} onClick={() => {
												dispatch(clearAutomark());
												dispatch(getAutomarkResult(activeTaskId));
											}}>{translate('update')}</span>
										</div>
									}
									{/* таблица авторазметки */}
									{autoMarkResult.status === RequestStatus.IDLE && autoMarkResult.data.length > 0 &&
										<div className={styles.autoMarkResult}>
											<AutoMarkTable automarkCorpus={autoMarkResult.data} />
											<div className={styles.autoMarkResultBottom}>
												<div>
													{autoMarkResult.started && <div>{translate('autoMark_startTitle')}: {autoMarkResult.started}</div>}
													{autoMarkResult.finished && <div>{translate('autoMark_finishTitle')}: {autoMarkResult.finished}</div>}
												</div>
												<div>
													{isAccess(CORPUS.SAVE) &&
														<Button
															variant="outlined"
															sx={{ fontSize: 11, marginRight: '16px' }}
															onClick={() => setShowModal(true)}
														>
															{translate('autoMark_addCorpusBtn')}
														</Button>
													}
													<a
														href={'data:text/plain;charset=utf-8,' + encodeURIComponent(arrAutoMarkInCsvFormat())}
														target='_blank'
														rel="noreferrer"
														download={`autoMark_id_${activeTaskId}.csv`}
													>
														<Button variant="outlined" sx={{ fontSize: 11 }}>
															{translate('autoMark_exportBtn')}
														</Button>
													</a>
												</div>
											</div>
										</div>
									}

									{/* список задач */}
									<ul className={styles.autoMarkList}>
										{autoMarkList.data.map(({ id, taskname }) => (
											<li className={cn(styles.autoMarkItem, {
												[styles.autoMarkItemActive]: id === activeTaskId,
											})} key={id} onClick={() => id !== activeTaskId && activeTaskHandler(id)}>
												<p className={styles.autoMarkItemName} title={taskname}>
													{taskname}
												</p>
											</li>
										))}
									</ul>
								</div>
							</Fade>
						}

						<div className={styles.tagClose} onClick={handleClose}>
							{translate('tag_close')}
						</div>

						{showModal && <FormAddingAutoMarkCorpus showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}

						{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusPutStatus} clearDataResponse={clearPutResponseCorpus} titleFailed='noticeImport_addCorpusFailed' titleSuccess='noticeImport_addCorpusSuccess' />}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default AutoMark;
