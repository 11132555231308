import { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectModelName } from '../../../store/modelSlice';
import { clearNormalization, clearSynthesisData, normalizeText, selectNormalization, selectSynthesisData, synthesizeSpeech } from '../../../store/ttsSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { TTS } from '../../../constants/accessRights';
import { RequestStatus } from '../../../types/statusTypes';
import NoticeSingleAction from '../../Notification/NoticeSingleAction/NoticeSingleAction';
import styles from './FormSynthesis.module.scss';

const FormSynthesis = (): JSX.Element => {
	const [text, setText] = useState<string>(''); // поле текста
	const [rate, setRate] = useState<number>(100); // поле скорости
	const [pitch, setPitch] = useState<number>(100); // поле тональности
	const [volume, setVolume] = useState<number>(100); // поле громкости
	const [rowsText, setRowsText] = useState<number>(1); // количество строк текстового поля для полного заполнения высоты
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления статуса синтеза
	const formRef = useRef<HTMLFormElement>(null); // ссылка на форму

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const normalizationData = useAppSelector(selectNormalization); // store - данные нормализации
	const synthesisData = useAppSelector(selectSynthesisData); // store - данные синтеза речи

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за полями формы и если что изменилось - очищаем результат синтеза и нормализации
	useEffect(() => {
		if (synthesisData.dataUrl !== null || synthesisData.status === RequestStatus.FAILED) {
			dispatch(clearSynthesisData());
			dispatch(clearNormalization());
		}
	}, [text, rate, pitch, volume]);

	// подписываемся на событие изменения размеров экрана и запускаем функцию подсчета кол-во строк для заполнения высоты формы
	useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		// отписываемся
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);

	// следим за ссылкой на форму и запускаем функцию подсчета кол-во строк для заполнения высоты формы
	useEffect(() => {
		resizeHandler();
	}, [formRef]);

	// следим за данными нормализации
	useEffect(() => {
		// если есть и отличается от текста в форме - меняем
		if (normalizationData.text && normalizationData.text !== text) setText(normalizationData.text);
	}, [normalizationData]);

	// следим за данными синтеза
	useEffect(() => {
		// если есть ошибка - включаем уведомление
		if (synthesisData.status === RequestStatus.FAILED) setShowNotification(true);
	}, [synthesisData]);

	// функция подсчета кол-ва строк для заполнения высоты формы
	const resizeHandler = () => {
		formRef.current && setRowsText(Math.floor((formRef.current.clientHeight - 178) / 19) - 1);
	};

	// обработчик нормализации
	const normalizeHandler = (): void => {
		(normalizationData.text || normalizationData.status === RequestStatus.FAILED) && dispatch(clearNormalization());
		dispatch(normalizeText(text));
	};

	// обработчик синтеза
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		(synthesisData.dataUrl !== null || synthesisData.status === RequestStatus.FAILED) && dispatch(clearSynthesisData()); // если есть данные синтеза - очищаем
		modelName && dispatch(synthesizeSpeech({ modelName, text, rate, pitch, volume })); // синтез речи
	};

	return (
		<>
			<form className={styles.form} onSubmit={e => submitHandler(e)} ref={formRef}>
				<div>
					<FormControl fullWidth margin='dense' sx={{ marginTop: 0 }}>
						<TextField
							required
							id="text"
							label={translate('formSynthesis_inputText')}
							variant="outlined"
							multiline
							rows={rowsText}
							value={text}
							onChange={(e) => setText(e.target.value)}
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										{text.length > 0 &&
											<FontAwesomeIcon icon={faXmark} onClick={() => normalizationData.status !== RequestStatus.LOADING && synthesisData.status !== RequestStatus.LOADING && setText('')} style={{ cursor: 'pointer ' }} />
										}
									</InputAdornment>
								),
								style: {
									padding: 8,
									fontSize: 13
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							id="rate"
							label={translate('formSynthesis_inputRate')}
							variant="outlined"
							type='number'
							value={rate}
							onChange={(e) => setRate(Math.round(Number(e.target.value)))}
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
								inputProps: { min: 0 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							id="pitch"
							label={translate('formSynthesis_inputPitch')}
							variant="outlined"
							type='number'
							value={pitch}
							onChange={(e) => setPitch(Math.round(Number(e.target.value)))}
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
								inputProps: { min: 0 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							id="volume"
							label={translate('formSynthesis_inputVolume')}
							variant="outlined"
							type='number'
							value={volume}
							onChange={(e) => setVolume(Math.round(Number(e.target.value)))}
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
								inputProps: { min: 0 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				</div>

				<div className={styles.formButtons}>
					{/* нормализация */}
					{isAccess(TTS.NORMALIZE) &&
						<FormControl fullWidth margin='dense' sx={{ marginBottom: 0 }}>
							<Button
								variant="outlined"
								type="button"
								disabled={normalizationData.status === RequestStatus.LOADING || !text}
								sx={{ overflow: 'hidden', fontSize: 11 }}
								onClick={normalizeHandler}
							>
								{translate('formSynthesis_normalizeBtn')}
							</Button>
						</FormControl>
					}
					{/* синтез */}
					<FormControl fullWidth margin='dense' sx={{ marginBottom: 0 }}>
						<Button
							variant="outlined"
							type="submit"
							disabled={synthesisData.status === RequestStatus.LOADING || !text}
							sx={{ overflow: 'hidden', fontSize: 11 }}
						>
							{translate('formSynthesis_synthesizeBtn')}
						</Button>
					</FormControl>
				</div>
			</form>

			{showNotification && <NoticeSingleAction showNotification={showNotification} setShowNotification={setShowNotification} title='noticeSynthesis_failed' />}
		</>
	);
};

export default FormSynthesis;
